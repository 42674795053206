/*-----------------------------------------------------------------------------------
    example100
-----------------------------------------------------------------------------------*/

#example100 {
  width: 100%;
  max-width: $width;
  margin: 0px auto 0px;
  // padding: 0 5%;
  @include media(PC) {
    padding: 0;
    margin: 40px auto 0px;
  }

  .example100-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-bottom: 30px;

    img {
      width: 100%;
      height:auto;
    }
    @include media(PC) {
      margin-bottom: 30px;
      height: 400px;
      font-size: 5.1rem;


    }
  }

  .example100-contents {
    .example100-contents__item {
      margin-bottom: 50px;
      &.example100-water {
        background-color: #e6f2fc;
      }
      &.example100-interior {
        background-color: #f9f0f5;
      }
      &.example100-house {
        background-color: #fff5de;
      }
      .image {
        img {
          max-width: 100%;
        }
      }
      .table {
        margin: 20px 5%;
        border: 1px solid #000;
        @include media(PC) {
          margin: 50px;
        }
        .item {
          display: table;
          border-top: 1px solid #231815;
          width: 100%;

          @include media(PC) {
            font-size: 1.4rem;
          }
          &:first-child {
            border-top: 0px solid #000;
          }

          .title {
            width: 60%;
          }

          .price {
            width: 25%;
          }

          .other {
            width: 15%;
          }

          .cell {
            display: table-cell;
            padding: 8px 10px;
            box-sizing: border-box;
            font-size: 1.10em;

            &.red {
              color: red;
            }
          }
        }
      }
      .contact {
        background-color: #f7fbec;
        padding: 20px 0;
        .contact-menu {
          margin-top: 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          @include media(PC) {
            flex-direction: row;
            align-items: flex-start;
          }

          .menu-item {
            margin-bottom: 30px;

            @include media(PC) {
              margin-bottom: 0;
              margin: 20px 40px;
            }
            dl {
              dt {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;

                @include media(PC) {
                  margin-bottom: 10px;
                }
                span {
                  img {
                    max-width: 30px;
                    margin-right: 10px;
                  }
                }
                p {
                  font-size: 1.5rem;
                  color: $primary-color;
                }
              }
            }
            .button {
              a {
                display: block;
                max-width: 300px;
                width: 100%;
                padding: 15px 0px;
                font-size: 1.6rem;
                text-align: center;
                margin: 0 auto 30px;
                color: $primary-color;
                border: 2px solid $primary-color;
                text-decoration: none;
                position: relative;
                background-color: transparent;
                transition: all .2s ease;

                &:hover {
                  background-color: $primary-color;
                  color: #fff;

                  &::after {
                    border-top: solid 2px #fff;
                    border-right: solid 2px #fff;
                  }
                }

                &::after {
                  content: '';
                  width: 10px;
                  height: 10px;
                  border: 0px;
                  border-top: solid 2px $primary-color;
                  border-right: solid 2px $primary-color;
                  -ms-transform: rotate(45deg);
                  -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
                  position: absolute;
                  top: 50%;
                  right: 20px;
                  margin-top: -4px;
                  transition: all .2s ease;
                }
              }
            }
            .tel {
              color: $primary-color;
              text-align: center;
              p {
                font-size: 3.5rem;
              }
              span {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }
  }
}
