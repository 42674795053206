//-----------------------------------------------------------------------------------
//
//  common.scss
//
//  1. font style
//  2. layout style
//
//-----------------------------------------------------------------------------------

//////////////////////////////////////////////////////////
// 1. font style
//////////////////////////////////////////////////////////

html, body {
    font-size: 62.5%;
    font-family: $body-font;
    color: $dark-color;
    line-height: $line-height;
    -webkit-font-smoothing: antialiased;
}

::selection {
    background: $primary-color;
    color: $light-color;
    text-shadow: none;
}


/* 画面外にいる状態 */
.fadein {
	opacity : 0.1;
	transform : translate(0, 50px);
	transition : all 500ms;
	}

/* 画面内に入った状態 */
.fadein.scrollin {
	opacity : 1;
	transform : translate(0, 0);
	}


@keyframes anim-updown {
  0% {
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  50% {
    -moz-transform: scale(1, 1) translate(0, 10px);
    -ms-transform: scale(1, 1) translate(0, 10px);
    -webkit-transform: scale(1, 1) translate(0, 10px);
    transform: scale(1, 1) translate(0, 10px);
  }
  100% {
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
.anim-updown {
  -webkit-animation: anim-updown 3s cubic-bezier(0.81, 0.32, 0.27, 0.72) 0s infinite;
  -moz-animation: anim-updown 3s cubic-bezier(0.81, 0.32, 0.27, 0.72) 0s infinite;
  animation: anim-updown 3s cubic-bezier(0.81, 0.32, 0.27, 0.72) 0s infinite;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}

.sp-show {
  display: inherit;
  @include media(PC){
    display: none;
  }
}

.pc-show {
  display: none;
  @include media(PC){
    display: inherit;
  }
}



//////////////////////////////////////////////////////////
// 1. header
//////////////////////////////////////////////////////////

.header {
  width: 100%;
  padding: 10px;
  z-index: 9999;
  position: relative;
  top: 0;
  left: 0;
  background-color: #fff;
  img {
    width: 100%;
    height: auto;
  }
  @include media(PC) {
    padding: 0;
  }
  .inr {
    position: relative;
    display: flex;
    flex-direction: column;
    @include media(PC){
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      width: 1100px;
    }
    .logo {
      img {
        height: 45px;
        width: auto;

        @include media(PC) {
          height: auto;
          width: 100%;
        }
      }

      @include media(PC) {
        width: 50%;
        max-width: 230px;
      }
    }
    .contactnav {
      display: none;
      @include media(PC){
        margin-top: 0;
        display: inherit;
        nav {
          ul {
            display: flex;
            li {
              text-align: center;
              display: flex;
              border-right: 1px solid #c9caca;
              a {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                color: $dark-color;
                text-decoration: none;
                font-size: 1.6rem;
                opacity: 1;
                font-weight: bold;
                width: 120px;
                height: 100px;
                transition: all .1s ease;

                &:hover {
                  opacity: 1;
                  background-color: rgba($accent-color,0.25);
                }

                &.bg-g {
                  background-color: $primary-color;
                  color: $light-color;

                  &:hover {
                    background-color: rgba($primary-color,0.5);
                  }

                  span {
                    color: $light-color;
                  }
                }
              }
              span {
                display: block;
                font-size: 1.1rem;
                color: $primary-color;
              }
            }
          }
        }
      }
      .snsnav {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        a {
          color: $primary-color;
          display: block;
          padding: 10px 15px;
          opacity: 1;
          transition: all .2s ease;

          &:hover {
            opacity: .5;
          }

          img {
            height: 20px;
          }
        }
      }

    }

  }
  .gnavWrap {
    display: none;
    position: absolute;
    right: -20px;
    left: -20px;
    top: 58px;
    background-color: $primary-color;
    font-size: 1.4rem;
    // padding: 60px 20px 20px 20px;
    .gnav {
      padding-top: 0px;
      padding-bottom: 60px;
      ul {
        display: flex;
        flex-wrap: wrap;
      }
      li {
        padding: 10px 20px;
        border-bottom: 1px solid #fff;
        width: 50%;
        // margin-bottom: 10px;

        &:nth-of-type(odd) {
          border-right: 1px solid #fff;
        }
        a {
          text-decoration: none;
          color: #fff;
          display: block;
        }
      }
      .gnav-contact {
        margin-top: 20px;
        .button {
          a {
            display: block;
            max-width: 300px;
            width: 100%;
            padding: 15px 0px;
            font-size: 1.6rem;
            text-align: center;
            margin: 0 auto 30px;
            color: #fff;
            border: 2px solid #fff;
            text-decoration: none;
            position: relative;
            background-color: transparent;
            transition: all .2s ease;

            &:hover {
              background-color: #fff;
              color: #fff;

              &::after {
                border-top: solid 2px #fff;
                border-right: solid 2px #fff;
              }
            }

            &::after {
              content: '';
              width: 10px;
              height: 10px;
              border: 0px;
              border-top: solid 2px #fff;
              border-right: solid 2px #fff;
              -ms-transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              position: absolute;
              top: 50%;
              right: 20px;
              margin-top: -4px;
              transition: all .2s ease;
            }
          }
        }
      }
      .gnav-sns {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          display: block;
          margin: 0 10px;
          img {
            width: 50px;
          }
        }
      }
    }

  }

}


.home {
  .header {
    .contactnav {

      ul li a {
        color: #fff !important;
      }
    }
  }
  .menu .menu-line {
    background: #fff !important;
    }
}

.menu {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 53px;
  width: 35px;
  z-index: 9999;
  span {
    position: absolute;
    bottom: 7px;
    margin: 0;
    padding: 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  @include media(PC){
    display: none;
  }
}
.menu .menu-line {
position: absolute;
width: 35px;
left: 0px;
height: 3px;
border-radius: 2px;
background: $primary-color;
}
 .menu .menu-line:nth-of-type(1) {
top: 8px;
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu .menu-line:nth-of-type(2) {
top: 16px;
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu .menu-line:nth-of-type(3) {
top: 24px;
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu.open .menu-line {
background: $primary-color;
}
 .menu.open .menu-line:nth-of-type(1) {
top: 16px;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu.open .menu-line:nth-of-type(2) {
opacity: 0;
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu.open .menu-line:nth-of-type(3) {
top: 16px;
-webkit-transform: rotate(-45deg);
-ms-transform: rotate(-45deg);
transform: rotate(-45deg);
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu .menu-text {
}

 .menu .menu-text span {
  width: 30px;
  color: $primary-color;
  position: absolute;
  left: 10px;
  bottom: 4px;
  line-height: 1;
  text-align: center;
}
 .menu .menu-text span img {
  width: 28px !important;
  height: auto;
}



//////////////////////////////////////////////////////////
// 2. footer
//////////////////////////////////////////////////////////

.footer {
  background-color: #fff;
  position: relative;
  .footer-inner {
    text-align: center;
  }
  .footer-info {
    background-color: $accent-color;
    padding: 10px 20px;
    text-align: center;
    color: $light-color;
    .title {
      font-size: 2.0rem;

      span {
        font-size: 1.4rem;
      }
    }
    .adr {
      font-size: 1.2rem;
    }
  }
  .footer-copyright {
    font-size: 1.4rem;
    padding: 20px 0;
  }

  .pagetop {
    position: fixed;
    bottom: 10px;
    right: 10px;
    @include media(PC) {
      bottom: 30px;
      right: 30px
    }
    a {
      display: block;
      width: 50px;
      height: 50px;

      @include media(PC) {
        width: 100px;
        height: 100px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
