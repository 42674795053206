@charset "UTF-8";
/*-----------------------------------------------------------------------------------

    Theme Name: meme

-----------------------------------------------------------------------------------*/
/*! sanitize.css v7.0.3 | CC0 License | github.com/csstools/sanitize.css */
/* Document
 * ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add border box sizing in all browsers (opinionated).
 */
@import url(https://fonts.googleapis.com/css2?family=Questrial&display=swap);
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: border-box;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Use the default user interface font in all browsers (opinionated).
 * 3. Correct the line height in all browsers.
 * 4. Use a 4-space tab width in all browsers (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */
html {
  cursor: default;
  /* 1 */
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* 2 */
  line-height: 1.15;
  /* 3 */
  -moz-tab-size: 4;
  /* 4 */
  tab-size: 4;
  /* 4 */
  -ms-text-size-adjust: 100%;
  /* 5 */
  -webkit-text-size-adjust: 100%;
  /* 5 */
  word-break: break-word;
  /* 6 */ }

/* Sections
 * ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
 * ========================================================================== */
/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Add the correct display in IE.
 */
main {
  display: block; }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics
 * ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Add the correct text decoration in Edge, IE, Opera, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/*
 * Remove the text shadow on text selections in Firefox 61- (opinionated).
 * 1. Restore the coloring undone by defining the text shadow
 *    in all browsers (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000;
  /* 1 */
  text-shadow: none; }

/* Embedded content
 * ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg:not([fill]) {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data
 * ========================================================================== */
/**
 * Collapse border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse; }

/* Forms
 * ========================================================================== */
/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * Remove the margin in Safari.
 */
button,
input,
select {
  margin: 0; }

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge, Firefox, and IE.
 */
button {
  overflow: visible;
  /* 1 */
  text-transform: none;
  /* 2 */ }

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
 * Show the overflow in Edge and IE.
 */
input {
  overflow: visible; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in Edge and IE.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none; }

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  margin: 0;
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
  /* 3 */ }

/**
 * Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  padding: 0; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText; }

/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge and IE.
 */
details {
  display: block; }

/*
 * Add the correct styles in Edge, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content; }

dialog:not([open]) {
  display: none; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* User interaction
 * ========================================================================== */
/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
  /* 2 */ }

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* Accessibility
 * ========================================================================== */
/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
[aria-disabled="true"],
[disabled] {
  cursor: not-allowed; }

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  animation: fa-spin 2s infinite linear; }

.fa-pulse {
  animation: fa-spin 1s infinite steps(8); }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1); }

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e"; }

.fa-accessible-icon:before {
  content: "\f368"; }

.fa-accusoft:before {
  content: "\f369"; }

.fa-acquisitions-incorporated:before {
  content: "\f6af"; }

.fa-ad:before {
  content: "\f641"; }

.fa-address-book:before {
  content: "\f2b9"; }

.fa-address-card:before {
  content: "\f2bb"; }

.fa-adjust:before {
  content: "\f042"; }

.fa-adn:before {
  content: "\f170"; }

.fa-adobe:before {
  content: "\f778"; }

.fa-adversal:before {
  content: "\f36a"; }

.fa-affiliatetheme:before {
  content: "\f36b"; }

.fa-air-freshener:before {
  content: "\f5d0"; }

.fa-algolia:before {
  content: "\f36c"; }

.fa-align-center:before {
  content: "\f037"; }

.fa-align-justify:before {
  content: "\f039"; }

.fa-align-left:before {
  content: "\f036"; }

.fa-align-right:before {
  content: "\f038"; }

.fa-alipay:before {
  content: "\f642"; }

.fa-allergies:before {
  content: "\f461"; }

.fa-amazon:before {
  content: "\f270"; }

.fa-amazon-pay:before {
  content: "\f42c"; }

.fa-ambulance:before {
  content: "\f0f9"; }

.fa-american-sign-language-interpreting:before {
  content: "\f2a3"; }

.fa-amilia:before {
  content: "\f36d"; }

.fa-anchor:before {
  content: "\f13d"; }

.fa-android:before {
  content: "\f17b"; }

.fa-angellist:before {
  content: "\f209"; }

.fa-angle-double-down:before {
  content: "\f103"; }

.fa-angle-double-left:before {
  content: "\f100"; }

.fa-angle-double-right:before {
  content: "\f101"; }

.fa-angle-double-up:before {
  content: "\f102"; }

.fa-angle-down:before {
  content: "\f107"; }

.fa-angle-left:before {
  content: "\f104"; }

.fa-angle-right:before {
  content: "\f105"; }

.fa-angle-up:before {
  content: "\f106"; }

.fa-angry:before {
  content: "\f556"; }

.fa-angrycreative:before {
  content: "\f36e"; }

.fa-angular:before {
  content: "\f420"; }

.fa-ankh:before {
  content: "\f644"; }

.fa-app-store:before {
  content: "\f36f"; }

.fa-app-store-ios:before {
  content: "\f370"; }

.fa-apper:before {
  content: "\f371"; }

.fa-apple:before {
  content: "\f179"; }

.fa-apple-alt:before {
  content: "\f5d1"; }

.fa-apple-pay:before {
  content: "\f415"; }

.fa-archive:before {
  content: "\f187"; }

.fa-archway:before {
  content: "\f557"; }

.fa-arrow-alt-circle-down:before {
  content: "\f358"; }

.fa-arrow-alt-circle-left:before {
  content: "\f359"; }

.fa-arrow-alt-circle-right:before {
  content: "\f35a"; }

.fa-arrow-alt-circle-up:before {
  content: "\f35b"; }

.fa-arrow-circle-down:before {
  content: "\f0ab"; }

.fa-arrow-circle-left:before {
  content: "\f0a8"; }

.fa-arrow-circle-right:before {
  content: "\f0a9"; }

.fa-arrow-circle-up:before {
  content: "\f0aa"; }

.fa-arrow-down:before {
  content: "\f063"; }

.fa-arrow-left:before {
  content: "\f060"; }

.fa-arrow-right:before {
  content: "\f061"; }

.fa-arrow-up:before {
  content: "\f062"; }

.fa-arrows-alt:before {
  content: "\f0b2"; }

.fa-arrows-alt-h:before {
  content: "\f337"; }

.fa-arrows-alt-v:before {
  content: "\f338"; }

.fa-artstation:before {
  content: "\f77a"; }

.fa-assistive-listening-systems:before {
  content: "\f2a2"; }

.fa-asterisk:before {
  content: "\f069"; }

.fa-asymmetrik:before {
  content: "\f372"; }

.fa-at:before {
  content: "\f1fa"; }

.fa-atlas:before {
  content: "\f558"; }

.fa-atlassian:before {
  content: "\f77b"; }

.fa-atom:before {
  content: "\f5d2"; }

.fa-audible:before {
  content: "\f373"; }

.fa-audio-description:before {
  content: "\f29e"; }

.fa-autoprefixer:before {
  content: "\f41c"; }

.fa-avianex:before {
  content: "\f374"; }

.fa-aviato:before {
  content: "\f421"; }

.fa-award:before {
  content: "\f559"; }

.fa-aws:before {
  content: "\f375"; }

.fa-baby:before {
  content: "\f77c"; }

.fa-baby-carriage:before {
  content: "\f77d"; }

.fa-backspace:before {
  content: "\f55a"; }

.fa-backward:before {
  content: "\f04a"; }

.fa-bacon:before {
  content: "\f7e5"; }

.fa-balance-scale:before {
  content: "\f24e"; }

.fa-ban:before {
  content: "\f05e"; }

.fa-band-aid:before {
  content: "\f462"; }

.fa-bandcamp:before {
  content: "\f2d5"; }

.fa-barcode:before {
  content: "\f02a"; }

.fa-bars:before {
  content: "\f0c9"; }

.fa-baseball-ball:before {
  content: "\f433"; }

.fa-basketball-ball:before {
  content: "\f434"; }

.fa-bath:before {
  content: "\f2cd"; }

.fa-battery-empty:before {
  content: "\f244"; }

.fa-battery-full:before {
  content: "\f240"; }

.fa-battery-half:before {
  content: "\f242"; }

.fa-battery-quarter:before {
  content: "\f243"; }

.fa-battery-three-quarters:before {
  content: "\f241"; }

.fa-bed:before {
  content: "\f236"; }

.fa-beer:before {
  content: "\f0fc"; }

.fa-behance:before {
  content: "\f1b4"; }

.fa-behance-square:before {
  content: "\f1b5"; }

.fa-bell:before {
  content: "\f0f3"; }

.fa-bell-slash:before {
  content: "\f1f6"; }

.fa-bezier-curve:before {
  content: "\f55b"; }

.fa-bible:before {
  content: "\f647"; }

.fa-bicycle:before {
  content: "\f206"; }

.fa-bimobject:before {
  content: "\f378"; }

.fa-binoculars:before {
  content: "\f1e5"; }

.fa-biohazard:before {
  content: "\f780"; }

.fa-birthday-cake:before {
  content: "\f1fd"; }

.fa-bitbucket:before {
  content: "\f171"; }

.fa-bitcoin:before {
  content: "\f379"; }

.fa-bity:before {
  content: "\f37a"; }

.fa-black-tie:before {
  content: "\f27e"; }

.fa-blackberry:before {
  content: "\f37b"; }

.fa-blender:before {
  content: "\f517"; }

.fa-blender-phone:before {
  content: "\f6b6"; }

.fa-blind:before {
  content: "\f29d"; }

.fa-blog:before {
  content: "\f781"; }

.fa-blogger:before {
  content: "\f37c"; }

.fa-blogger-b:before {
  content: "\f37d"; }

.fa-bluetooth:before {
  content: "\f293"; }

.fa-bluetooth-b:before {
  content: "\f294"; }

.fa-bold:before {
  content: "\f032"; }

.fa-bolt:before {
  content: "\f0e7"; }

.fa-bomb:before {
  content: "\f1e2"; }

.fa-bone:before {
  content: "\f5d7"; }

.fa-bong:before {
  content: "\f55c"; }

.fa-book:before {
  content: "\f02d"; }

.fa-book-dead:before {
  content: "\f6b7"; }

.fa-book-medical:before {
  content: "\f7e6"; }

.fa-book-open:before {
  content: "\f518"; }

.fa-book-reader:before {
  content: "\f5da"; }

.fa-bookmark:before {
  content: "\f02e"; }

.fa-bowling-ball:before {
  content: "\f436"; }

.fa-box:before {
  content: "\f466"; }

.fa-box-open:before {
  content: "\f49e"; }

.fa-boxes:before {
  content: "\f468"; }

.fa-braille:before {
  content: "\f2a1"; }

.fa-brain:before {
  content: "\f5dc"; }

.fa-bread-slice:before {
  content: "\f7ec"; }

.fa-briefcase:before {
  content: "\f0b1"; }

.fa-briefcase-medical:before {
  content: "\f469"; }

.fa-broadcast-tower:before {
  content: "\f519"; }

.fa-broom:before {
  content: "\f51a"; }

.fa-brush:before {
  content: "\f55d"; }

.fa-btc:before {
  content: "\f15a"; }

.fa-bug:before {
  content: "\f188"; }

.fa-building:before {
  content: "\f1ad"; }

.fa-bullhorn:before {
  content: "\f0a1"; }

.fa-bullseye:before {
  content: "\f140"; }

.fa-burn:before {
  content: "\f46a"; }

.fa-buromobelexperte:before {
  content: "\f37f"; }

.fa-bus:before {
  content: "\f207"; }

.fa-bus-alt:before {
  content: "\f55e"; }

.fa-business-time:before {
  content: "\f64a"; }

.fa-buysellads:before {
  content: "\f20d"; }

.fa-calculator:before {
  content: "\f1ec"; }

.fa-calendar:before {
  content: "\f133"; }

.fa-calendar-alt:before {
  content: "\f073"; }

.fa-calendar-check:before {
  content: "\f274"; }

.fa-calendar-day:before {
  content: "\f783"; }

.fa-calendar-minus:before {
  content: "\f272"; }

.fa-calendar-plus:before {
  content: "\f271"; }

.fa-calendar-times:before {
  content: "\f273"; }

.fa-calendar-week:before {
  content: "\f784"; }

.fa-camera:before {
  content: "\f030"; }

.fa-camera-retro:before {
  content: "\f083"; }

.fa-campground:before {
  content: "\f6bb"; }

.fa-canadian-maple-leaf:before {
  content: "\f785"; }

.fa-candy-cane:before {
  content: "\f786"; }

.fa-cannabis:before {
  content: "\f55f"; }

.fa-capsules:before {
  content: "\f46b"; }

.fa-car:before {
  content: "\f1b9"; }

.fa-car-alt:before {
  content: "\f5de"; }

.fa-car-battery:before {
  content: "\f5df"; }

.fa-car-crash:before {
  content: "\f5e1"; }

.fa-car-side:before {
  content: "\f5e4"; }

.fa-caret-down:before {
  content: "\f0d7"; }

.fa-caret-left:before {
  content: "\f0d9"; }

.fa-caret-right:before {
  content: "\f0da"; }

.fa-caret-square-down:before {
  content: "\f150"; }

.fa-caret-square-left:before {
  content: "\f191"; }

.fa-caret-square-right:before {
  content: "\f152"; }

.fa-caret-square-up:before {
  content: "\f151"; }

.fa-caret-up:before {
  content: "\f0d8"; }

.fa-carrot:before {
  content: "\f787"; }

.fa-cart-arrow-down:before {
  content: "\f218"; }

.fa-cart-plus:before {
  content: "\f217"; }

.fa-cash-register:before {
  content: "\f788"; }

.fa-cat:before {
  content: "\f6be"; }

.fa-cc-amazon-pay:before {
  content: "\f42d"; }

.fa-cc-amex:before {
  content: "\f1f3"; }

.fa-cc-apple-pay:before {
  content: "\f416"; }

.fa-cc-diners-club:before {
  content: "\f24c"; }

.fa-cc-discover:before {
  content: "\f1f2"; }

.fa-cc-jcb:before {
  content: "\f24b"; }

.fa-cc-mastercard:before {
  content: "\f1f1"; }

.fa-cc-paypal:before {
  content: "\f1f4"; }

.fa-cc-stripe:before {
  content: "\f1f5"; }

.fa-cc-visa:before {
  content: "\f1f0"; }

.fa-centercode:before {
  content: "\f380"; }

.fa-centos:before {
  content: "\f789"; }

.fa-certificate:before {
  content: "\f0a3"; }

.fa-chair:before {
  content: "\f6c0"; }

.fa-chalkboard:before {
  content: "\f51b"; }

.fa-chalkboard-teacher:before {
  content: "\f51c"; }

.fa-charging-station:before {
  content: "\f5e7"; }

.fa-chart-area:before {
  content: "\f1fe"; }

.fa-chart-bar:before {
  content: "\f080"; }

.fa-chart-line:before {
  content: "\f201"; }

.fa-chart-pie:before {
  content: "\f200"; }

.fa-check:before {
  content: "\f00c"; }

.fa-check-circle:before {
  content: "\f058"; }

.fa-check-double:before {
  content: "\f560"; }

.fa-check-square:before {
  content: "\f14a"; }

.fa-cheese:before {
  content: "\f7ef"; }

.fa-chess:before {
  content: "\f439"; }

.fa-chess-bishop:before {
  content: "\f43a"; }

.fa-chess-board:before {
  content: "\f43c"; }

.fa-chess-king:before {
  content: "\f43f"; }

.fa-chess-knight:before {
  content: "\f441"; }

.fa-chess-pawn:before {
  content: "\f443"; }

.fa-chess-queen:before {
  content: "\f445"; }

.fa-chess-rook:before {
  content: "\f447"; }

.fa-chevron-circle-down:before {
  content: "\f13a"; }

.fa-chevron-circle-left:before {
  content: "\f137"; }

.fa-chevron-circle-right:before {
  content: "\f138"; }

.fa-chevron-circle-up:before {
  content: "\f139"; }

.fa-chevron-down:before {
  content: "\f078"; }

.fa-chevron-left:before {
  content: "\f053"; }

.fa-chevron-right:before {
  content: "\f054"; }

.fa-chevron-up:before {
  content: "\f077"; }

.fa-child:before {
  content: "\f1ae"; }

.fa-chrome:before {
  content: "\f268"; }

.fa-church:before {
  content: "\f51d"; }

.fa-circle:before {
  content: "\f111"; }

.fa-circle-notch:before {
  content: "\f1ce"; }

.fa-city:before {
  content: "\f64f"; }

.fa-clinic-medical:before {
  content: "\f7f2"; }

.fa-clipboard:before {
  content: "\f328"; }

.fa-clipboard-check:before {
  content: "\f46c"; }

.fa-clipboard-list:before {
  content: "\f46d"; }

.fa-clock:before {
  content: "\f017"; }

.fa-clone:before {
  content: "\f24d"; }

.fa-closed-captioning:before {
  content: "\f20a"; }

.fa-cloud:before {
  content: "\f0c2"; }

.fa-cloud-download-alt:before {
  content: "\f381"; }

.fa-cloud-meatball:before {
  content: "\f73b"; }

.fa-cloud-moon:before {
  content: "\f6c3"; }

.fa-cloud-moon-rain:before {
  content: "\f73c"; }

.fa-cloud-rain:before {
  content: "\f73d"; }

.fa-cloud-showers-heavy:before {
  content: "\f740"; }

.fa-cloud-sun:before {
  content: "\f6c4"; }

.fa-cloud-sun-rain:before {
  content: "\f743"; }

.fa-cloud-upload-alt:before {
  content: "\f382"; }

.fa-cloudscale:before {
  content: "\f383"; }

.fa-cloudsmith:before {
  content: "\f384"; }

.fa-cloudversify:before {
  content: "\f385"; }

.fa-cocktail:before {
  content: "\f561"; }

.fa-code:before {
  content: "\f121"; }

.fa-code-branch:before {
  content: "\f126"; }

.fa-codepen:before {
  content: "\f1cb"; }

.fa-codiepie:before {
  content: "\f284"; }

.fa-coffee:before {
  content: "\f0f4"; }

.fa-cog:before {
  content: "\f013"; }

.fa-cogs:before {
  content: "\f085"; }

.fa-coins:before {
  content: "\f51e"; }

.fa-columns:before {
  content: "\f0db"; }

.fa-comment:before {
  content: "\f075"; }

.fa-comment-alt:before {
  content: "\f27a"; }

.fa-comment-dollar:before {
  content: "\f651"; }

.fa-comment-dots:before {
  content: "\f4ad"; }

.fa-comment-medical:before {
  content: "\f7f5"; }

.fa-comment-slash:before {
  content: "\f4b3"; }

.fa-comments:before {
  content: "\f086"; }

.fa-comments-dollar:before {
  content: "\f653"; }

.fa-compact-disc:before {
  content: "\f51f"; }

.fa-compass:before {
  content: "\f14e"; }

.fa-compress:before {
  content: "\f066"; }

.fa-compress-arrows-alt:before {
  content: "\f78c"; }

.fa-concierge-bell:before {
  content: "\f562"; }

.fa-confluence:before {
  content: "\f78d"; }

.fa-connectdevelop:before {
  content: "\f20e"; }

.fa-contao:before {
  content: "\f26d"; }

.fa-cookie:before {
  content: "\f563"; }

.fa-cookie-bite:before {
  content: "\f564"; }

.fa-copy:before {
  content: "\f0c5"; }

.fa-copyright:before {
  content: "\f1f9"; }

.fa-couch:before {
  content: "\f4b8"; }

.fa-cpanel:before {
  content: "\f388"; }

.fa-creative-commons:before {
  content: "\f25e"; }

.fa-creative-commons-by:before {
  content: "\f4e7"; }

.fa-creative-commons-nc:before {
  content: "\f4e8"; }

.fa-creative-commons-nc-eu:before {
  content: "\f4e9"; }

.fa-creative-commons-nc-jp:before {
  content: "\f4ea"; }

.fa-creative-commons-nd:before {
  content: "\f4eb"; }

.fa-creative-commons-pd:before {
  content: "\f4ec"; }

.fa-creative-commons-pd-alt:before {
  content: "\f4ed"; }

.fa-creative-commons-remix:before {
  content: "\f4ee"; }

.fa-creative-commons-sa:before {
  content: "\f4ef"; }

.fa-creative-commons-sampling:before {
  content: "\f4f0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1"; }

.fa-creative-commons-share:before {
  content: "\f4f2"; }

.fa-creative-commons-zero:before {
  content: "\f4f3"; }

.fa-credit-card:before {
  content: "\f09d"; }

.fa-critical-role:before {
  content: "\f6c9"; }

.fa-crop:before {
  content: "\f125"; }

.fa-crop-alt:before {
  content: "\f565"; }

.fa-cross:before {
  content: "\f654"; }

.fa-crosshairs:before {
  content: "\f05b"; }

.fa-crow:before {
  content: "\f520"; }

.fa-crown:before {
  content: "\f521"; }

.fa-crutch:before {
  content: "\f7f7"; }

.fa-css3:before {
  content: "\f13c"; }

.fa-css3-alt:before {
  content: "\f38b"; }

.fa-cube:before {
  content: "\f1b2"; }

.fa-cubes:before {
  content: "\f1b3"; }

.fa-cut:before {
  content: "\f0c4"; }

.fa-cuttlefish:before {
  content: "\f38c"; }

.fa-d-and-d:before {
  content: "\f38d"; }

.fa-d-and-d-beyond:before {
  content: "\f6ca"; }

.fa-dashcube:before {
  content: "\f210"; }

.fa-database:before {
  content: "\f1c0"; }

.fa-deaf:before {
  content: "\f2a4"; }

.fa-delicious:before {
  content: "\f1a5"; }

.fa-democrat:before {
  content: "\f747"; }

.fa-deploydog:before {
  content: "\f38e"; }

.fa-deskpro:before {
  content: "\f38f"; }

.fa-desktop:before {
  content: "\f108"; }

.fa-dev:before {
  content: "\f6cc"; }

.fa-deviantart:before {
  content: "\f1bd"; }

.fa-dharmachakra:before {
  content: "\f655"; }

.fa-dhl:before {
  content: "\f790"; }

.fa-diagnoses:before {
  content: "\f470"; }

.fa-diaspora:before {
  content: "\f791"; }

.fa-dice:before {
  content: "\f522"; }

.fa-dice-d20:before {
  content: "\f6cf"; }

.fa-dice-d6:before {
  content: "\f6d1"; }

.fa-dice-five:before {
  content: "\f523"; }

.fa-dice-four:before {
  content: "\f524"; }

.fa-dice-one:before {
  content: "\f525"; }

.fa-dice-six:before {
  content: "\f526"; }

.fa-dice-three:before {
  content: "\f527"; }

.fa-dice-two:before {
  content: "\f528"; }

.fa-digg:before {
  content: "\f1a6"; }

.fa-digital-ocean:before {
  content: "\f391"; }

.fa-digital-tachograph:before {
  content: "\f566"; }

.fa-directions:before {
  content: "\f5eb"; }

.fa-discord:before {
  content: "\f392"; }

.fa-discourse:before {
  content: "\f393"; }

.fa-divide:before {
  content: "\f529"; }

.fa-dizzy:before {
  content: "\f567"; }

.fa-dna:before {
  content: "\f471"; }

.fa-dochub:before {
  content: "\f394"; }

.fa-docker:before {
  content: "\f395"; }

.fa-dog:before {
  content: "\f6d3"; }

.fa-dollar-sign:before {
  content: "\f155"; }

.fa-dolly:before {
  content: "\f472"; }

.fa-dolly-flatbed:before {
  content: "\f474"; }

.fa-donate:before {
  content: "\f4b9"; }

.fa-door-closed:before {
  content: "\f52a"; }

.fa-door-open:before {
  content: "\f52b"; }

.fa-dot-circle:before {
  content: "\f192"; }

.fa-dove:before {
  content: "\f4ba"; }

.fa-download:before {
  content: "\f019"; }

.fa-draft2digital:before {
  content: "\f396"; }

.fa-drafting-compass:before {
  content: "\f568"; }

.fa-dragon:before {
  content: "\f6d5"; }

.fa-draw-polygon:before {
  content: "\f5ee"; }

.fa-dribbble:before {
  content: "\f17d"; }

.fa-dribbble-square:before {
  content: "\f397"; }

.fa-dropbox:before {
  content: "\f16b"; }

.fa-drum:before {
  content: "\f569"; }

.fa-drum-steelpan:before {
  content: "\f56a"; }

.fa-drumstick-bite:before {
  content: "\f6d7"; }

.fa-drupal:before {
  content: "\f1a9"; }

.fa-dumbbell:before {
  content: "\f44b"; }

.fa-dumpster:before {
  content: "\f793"; }

.fa-dumpster-fire:before {
  content: "\f794"; }

.fa-dungeon:before {
  content: "\f6d9"; }

.fa-dyalog:before {
  content: "\f399"; }

.fa-earlybirds:before {
  content: "\f39a"; }

.fa-ebay:before {
  content: "\f4f4"; }

.fa-edge:before {
  content: "\f282"; }

.fa-edit:before {
  content: "\f044"; }

.fa-egg:before {
  content: "\f7fb"; }

.fa-eject:before {
  content: "\f052"; }

.fa-elementor:before {
  content: "\f430"; }

.fa-ellipsis-h:before {
  content: "\f141"; }

.fa-ellipsis-v:before {
  content: "\f142"; }

.fa-ello:before {
  content: "\f5f1"; }

.fa-ember:before {
  content: "\f423"; }

.fa-empire:before {
  content: "\f1d1"; }

.fa-envelope:before {
  content: "\f0e0"; }

.fa-envelope-open:before {
  content: "\f2b6"; }

.fa-envelope-open-text:before {
  content: "\f658"; }

.fa-envelope-square:before {
  content: "\f199"; }

.fa-envira:before {
  content: "\f299"; }

.fa-equals:before {
  content: "\f52c"; }

.fa-eraser:before {
  content: "\f12d"; }

.fa-erlang:before {
  content: "\f39d"; }

.fa-ethereum:before {
  content: "\f42e"; }

.fa-ethernet:before {
  content: "\f796"; }

.fa-etsy:before {
  content: "\f2d7"; }

.fa-euro-sign:before {
  content: "\f153"; }

.fa-exchange-alt:before {
  content: "\f362"; }

.fa-exclamation:before {
  content: "\f12a"; }

.fa-exclamation-circle:before {
  content: "\f06a"; }

.fa-exclamation-triangle:before {
  content: "\f071"; }

.fa-expand:before {
  content: "\f065"; }

.fa-expand-arrows-alt:before {
  content: "\f31e"; }

.fa-expeditedssl:before {
  content: "\f23e"; }

.fa-external-link-alt:before {
  content: "\f35d"; }

.fa-external-link-square-alt:before {
  content: "\f360"; }

.fa-eye:before {
  content: "\f06e"; }

.fa-eye-dropper:before {
  content: "\f1fb"; }

.fa-eye-slash:before {
  content: "\f070"; }

.fa-facebook:before {
  content: "\f09a"; }

.fa-facebook-f:before {
  content: "\f39e"; }

.fa-facebook-messenger:before {
  content: "\f39f"; }

.fa-facebook-square:before {
  content: "\f082"; }

.fa-fantasy-flight-games:before {
  content: "\f6dc"; }

.fa-fast-backward:before {
  content: "\f049"; }

.fa-fast-forward:before {
  content: "\f050"; }

.fa-fax:before {
  content: "\f1ac"; }

.fa-feather:before {
  content: "\f52d"; }

.fa-feather-alt:before {
  content: "\f56b"; }

.fa-fedex:before {
  content: "\f797"; }

.fa-fedora:before {
  content: "\f798"; }

.fa-female:before {
  content: "\f182"; }

.fa-fighter-jet:before {
  content: "\f0fb"; }

.fa-figma:before {
  content: "\f799"; }

.fa-file:before {
  content: "\f15b"; }

.fa-file-alt:before {
  content: "\f15c"; }

.fa-file-archive:before {
  content: "\f1c6"; }

.fa-file-audio:before {
  content: "\f1c7"; }

.fa-file-code:before {
  content: "\f1c9"; }

.fa-file-contract:before {
  content: "\f56c"; }

.fa-file-csv:before {
  content: "\f6dd"; }

.fa-file-download:before {
  content: "\f56d"; }

.fa-file-excel:before {
  content: "\f1c3"; }

.fa-file-export:before {
  content: "\f56e"; }

.fa-file-image:before {
  content: "\f1c5"; }

.fa-file-import:before {
  content: "\f56f"; }

.fa-file-invoice:before {
  content: "\f570"; }

.fa-file-invoice-dollar:before {
  content: "\f571"; }

.fa-file-medical:before {
  content: "\f477"; }

.fa-file-medical-alt:before {
  content: "\f478"; }

.fa-file-pdf:before {
  content: "\f1c1"; }

.fa-file-powerpoint:before {
  content: "\f1c4"; }

.fa-file-prescription:before {
  content: "\f572"; }

.fa-file-signature:before {
  content: "\f573"; }

.fa-file-upload:before {
  content: "\f574"; }

.fa-file-video:before {
  content: "\f1c8"; }

.fa-file-word:before {
  content: "\f1c2"; }

.fa-fill:before {
  content: "\f575"; }

.fa-fill-drip:before {
  content: "\f576"; }

.fa-film:before {
  content: "\f008"; }

.fa-filter:before {
  content: "\f0b0"; }

.fa-fingerprint:before {
  content: "\f577"; }

.fa-fire:before {
  content: "\f06d"; }

.fa-fire-alt:before {
  content: "\f7e4"; }

.fa-fire-extinguisher:before {
  content: "\f134"; }

.fa-firefox:before {
  content: "\f269"; }

.fa-first-aid:before {
  content: "\f479"; }

.fa-first-order:before {
  content: "\f2b0"; }

.fa-first-order-alt:before {
  content: "\f50a"; }

.fa-firstdraft:before {
  content: "\f3a1"; }

.fa-fish:before {
  content: "\f578"; }

.fa-fist-raised:before {
  content: "\f6de"; }

.fa-flag:before {
  content: "\f024"; }

.fa-flag-checkered:before {
  content: "\f11e"; }

.fa-flag-usa:before {
  content: "\f74d"; }

.fa-flask:before {
  content: "\f0c3"; }

.fa-flickr:before {
  content: "\f16e"; }

.fa-flipboard:before {
  content: "\f44d"; }

.fa-flushed:before {
  content: "\f579"; }

.fa-fly:before {
  content: "\f417"; }

.fa-folder:before {
  content: "\f07b"; }

.fa-folder-minus:before {
  content: "\f65d"; }

.fa-folder-open:before {
  content: "\f07c"; }

.fa-folder-plus:before {
  content: "\f65e"; }

.fa-font:before {
  content: "\f031"; }

.fa-font-awesome:before {
  content: "\f2b4"; }

.fa-font-awesome-alt:before {
  content: "\f35c"; }

.fa-font-awesome-flag:before {
  content: "\f425"; }

.fa-font-awesome-logo-full:before {
  content: "\f4e6"; }

.fa-fonticons:before {
  content: "\f280"; }

.fa-fonticons-fi:before {
  content: "\f3a2"; }

.fa-football-ball:before {
  content: "\f44e"; }

.fa-fort-awesome:before {
  content: "\f286"; }

.fa-fort-awesome-alt:before {
  content: "\f3a3"; }

.fa-forumbee:before {
  content: "\f211"; }

.fa-forward:before {
  content: "\f04e"; }

.fa-foursquare:before {
  content: "\f180"; }

.fa-free-code-camp:before {
  content: "\f2c5"; }

.fa-freebsd:before {
  content: "\f3a4"; }

.fa-frog:before {
  content: "\f52e"; }

.fa-frown:before {
  content: "\f119"; }

.fa-frown-open:before {
  content: "\f57a"; }

.fa-fulcrum:before {
  content: "\f50b"; }

.fa-funnel-dollar:before {
  content: "\f662"; }

.fa-futbol:before {
  content: "\f1e3"; }

.fa-galactic-republic:before {
  content: "\f50c"; }

.fa-galactic-senate:before {
  content: "\f50d"; }

.fa-gamepad:before {
  content: "\f11b"; }

.fa-gas-pump:before {
  content: "\f52f"; }

.fa-gavel:before {
  content: "\f0e3"; }

.fa-gem:before {
  content: "\f3a5"; }

.fa-genderless:before {
  content: "\f22d"; }

.fa-get-pocket:before {
  content: "\f265"; }

.fa-gg:before {
  content: "\f260"; }

.fa-gg-circle:before {
  content: "\f261"; }

.fa-ghost:before {
  content: "\f6e2"; }

.fa-gift:before {
  content: "\f06b"; }

.fa-gifts:before {
  content: "\f79c"; }

.fa-git:before {
  content: "\f1d3"; }

.fa-git-square:before {
  content: "\f1d2"; }

.fa-github:before {
  content: "\f09b"; }

.fa-github-alt:before {
  content: "\f113"; }

.fa-github-square:before {
  content: "\f092"; }

.fa-gitkraken:before {
  content: "\f3a6"; }

.fa-gitlab:before {
  content: "\f296"; }

.fa-gitter:before {
  content: "\f426"; }

.fa-glass-cheers:before {
  content: "\f79f"; }

.fa-glass-martini:before {
  content: "\f000"; }

.fa-glass-martini-alt:before {
  content: "\f57b"; }

.fa-glass-whiskey:before {
  content: "\f7a0"; }

.fa-glasses:before {
  content: "\f530"; }

.fa-glide:before {
  content: "\f2a5"; }

.fa-glide-g:before {
  content: "\f2a6"; }

.fa-globe:before {
  content: "\f0ac"; }

.fa-globe-africa:before {
  content: "\f57c"; }

.fa-globe-americas:before {
  content: "\f57d"; }

.fa-globe-asia:before {
  content: "\f57e"; }

.fa-globe-europe:before {
  content: "\f7a2"; }

.fa-gofore:before {
  content: "\f3a7"; }

.fa-golf-ball:before {
  content: "\f450"; }

.fa-goodreads:before {
  content: "\f3a8"; }

.fa-goodreads-g:before {
  content: "\f3a9"; }

.fa-google:before {
  content: "\f1a0"; }

.fa-google-drive:before {
  content: "\f3aa"; }

.fa-google-play:before {
  content: "\f3ab"; }

.fa-google-plus:before {
  content: "\f2b3"; }

.fa-google-plus-g:before {
  content: "\f0d5"; }

.fa-google-plus-square:before {
  content: "\f0d4"; }

.fa-google-wallet:before {
  content: "\f1ee"; }

.fa-gopuram:before {
  content: "\f664"; }

.fa-graduation-cap:before {
  content: "\f19d"; }

.fa-gratipay:before {
  content: "\f184"; }

.fa-grav:before {
  content: "\f2d6"; }

.fa-greater-than:before {
  content: "\f531"; }

.fa-greater-than-equal:before {
  content: "\f532"; }

.fa-grimace:before {
  content: "\f57f"; }

.fa-grin:before {
  content: "\f580"; }

.fa-grin-alt:before {
  content: "\f581"; }

.fa-grin-beam:before {
  content: "\f582"; }

.fa-grin-beam-sweat:before {
  content: "\f583"; }

.fa-grin-hearts:before {
  content: "\f584"; }

.fa-grin-squint:before {
  content: "\f585"; }

.fa-grin-squint-tears:before {
  content: "\f586"; }

.fa-grin-stars:before {
  content: "\f587"; }

.fa-grin-tears:before {
  content: "\f588"; }

.fa-grin-tongue:before {
  content: "\f589"; }

.fa-grin-tongue-squint:before {
  content: "\f58a"; }

.fa-grin-tongue-wink:before {
  content: "\f58b"; }

.fa-grin-wink:before {
  content: "\f58c"; }

.fa-grip-horizontal:before {
  content: "\f58d"; }

.fa-grip-lines:before {
  content: "\f7a4"; }

.fa-grip-lines-vertical:before {
  content: "\f7a5"; }

.fa-grip-vertical:before {
  content: "\f58e"; }

.fa-gripfire:before {
  content: "\f3ac"; }

.fa-grunt:before {
  content: "\f3ad"; }

.fa-guitar:before {
  content: "\f7a6"; }

.fa-gulp:before {
  content: "\f3ae"; }

.fa-h-square:before {
  content: "\f0fd"; }

.fa-hacker-news:before {
  content: "\f1d4"; }

.fa-hacker-news-square:before {
  content: "\f3af"; }

.fa-hackerrank:before {
  content: "\f5f7"; }

.fa-hamburger:before {
  content: "\f805"; }

.fa-hammer:before {
  content: "\f6e3"; }

.fa-hamsa:before {
  content: "\f665"; }

.fa-hand-holding:before {
  content: "\f4bd"; }

.fa-hand-holding-heart:before {
  content: "\f4be"; }

.fa-hand-holding-usd:before {
  content: "\f4c0"; }

.fa-hand-lizard:before {
  content: "\f258"; }

.fa-hand-middle-finger:before {
  content: "\f806"; }

.fa-hand-paper:before {
  content: "\f256"; }

.fa-hand-peace:before {
  content: "\f25b"; }

.fa-hand-point-down:before {
  content: "\f0a7"; }

.fa-hand-point-left:before {
  content: "\f0a5"; }

.fa-hand-point-right:before {
  content: "\f0a4"; }

.fa-hand-point-up:before {
  content: "\f0a6"; }

.fa-hand-pointer:before {
  content: "\f25a"; }

.fa-hand-rock:before {
  content: "\f255"; }

.fa-hand-scissors:before {
  content: "\f257"; }

.fa-hand-spock:before {
  content: "\f259"; }

.fa-hands:before {
  content: "\f4c2"; }

.fa-hands-helping:before {
  content: "\f4c4"; }

.fa-handshake:before {
  content: "\f2b5"; }

.fa-hanukiah:before {
  content: "\f6e6"; }

.fa-hard-hat:before {
  content: "\f807"; }

.fa-hashtag:before {
  content: "\f292"; }

.fa-hat-wizard:before {
  content: "\f6e8"; }

.fa-haykal:before {
  content: "\f666"; }

.fa-hdd:before {
  content: "\f0a0"; }

.fa-heading:before {
  content: "\f1dc"; }

.fa-headphones:before {
  content: "\f025"; }

.fa-headphones-alt:before {
  content: "\f58f"; }

.fa-headset:before {
  content: "\f590"; }

.fa-heart:before {
  content: "\f004"; }

.fa-heart-broken:before {
  content: "\f7a9"; }

.fa-heartbeat:before {
  content: "\f21e"; }

.fa-helicopter:before {
  content: "\f533"; }

.fa-highlighter:before {
  content: "\f591"; }

.fa-hiking:before {
  content: "\f6ec"; }

.fa-hippo:before {
  content: "\f6ed"; }

.fa-hips:before {
  content: "\f452"; }

.fa-hire-a-helper:before {
  content: "\f3b0"; }

.fa-history:before {
  content: "\f1da"; }

.fa-hockey-puck:before {
  content: "\f453"; }

.fa-holly-berry:before {
  content: "\f7aa"; }

.fa-home:before {
  content: "\f015"; }

.fa-hooli:before {
  content: "\f427"; }

.fa-hornbill:before {
  content: "\f592"; }

.fa-horse:before {
  content: "\f6f0"; }

.fa-horse-head:before {
  content: "\f7ab"; }

.fa-hospital:before {
  content: "\f0f8"; }

.fa-hospital-alt:before {
  content: "\f47d"; }

.fa-hospital-symbol:before {
  content: "\f47e"; }

.fa-hot-tub:before {
  content: "\f593"; }

.fa-hotdog:before {
  content: "\f80f"; }

.fa-hotel:before {
  content: "\f594"; }

.fa-hotjar:before {
  content: "\f3b1"; }

.fa-hourglass:before {
  content: "\f254"; }

.fa-hourglass-end:before {
  content: "\f253"; }

.fa-hourglass-half:before {
  content: "\f252"; }

.fa-hourglass-start:before {
  content: "\f251"; }

.fa-house-damage:before {
  content: "\f6f1"; }

.fa-houzz:before {
  content: "\f27c"; }

.fa-hryvnia:before {
  content: "\f6f2"; }

.fa-html5:before {
  content: "\f13b"; }

.fa-hubspot:before {
  content: "\f3b2"; }

.fa-i-cursor:before {
  content: "\f246"; }

.fa-ice-cream:before {
  content: "\f810"; }

.fa-icicles:before {
  content: "\f7ad"; }

.fa-id-badge:before {
  content: "\f2c1"; }

.fa-id-card:before {
  content: "\f2c2"; }

.fa-id-card-alt:before {
  content: "\f47f"; }

.fa-igloo:before {
  content: "\f7ae"; }

.fa-image:before {
  content: "\f03e"; }

.fa-images:before {
  content: "\f302"; }

.fa-imdb:before {
  content: "\f2d8"; }

.fa-inbox:before {
  content: "\f01c"; }

.fa-indent:before {
  content: "\f03c"; }

.fa-industry:before {
  content: "\f275"; }

.fa-infinity:before {
  content: "\f534"; }

.fa-info:before {
  content: "\f129"; }

.fa-info-circle:before {
  content: "\f05a"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-intercom:before {
  content: "\f7af"; }

.fa-internet-explorer:before {
  content: "\f26b"; }

.fa-invision:before {
  content: "\f7b0"; }

.fa-ioxhost:before {
  content: "\f208"; }

.fa-italic:before {
  content: "\f033"; }

.fa-itunes:before {
  content: "\f3b4"; }

.fa-itunes-note:before {
  content: "\f3b5"; }

.fa-java:before {
  content: "\f4e4"; }

.fa-jedi:before {
  content: "\f669"; }

.fa-jedi-order:before {
  content: "\f50e"; }

.fa-jenkins:before {
  content: "\f3b6"; }

.fa-jira:before {
  content: "\f7b1"; }

.fa-joget:before {
  content: "\f3b7"; }

.fa-joint:before {
  content: "\f595"; }

.fa-joomla:before {
  content: "\f1aa"; }

.fa-journal-whills:before {
  content: "\f66a"; }

.fa-js:before {
  content: "\f3b8"; }

.fa-js-square:before {
  content: "\f3b9"; }

.fa-jsfiddle:before {
  content: "\f1cc"; }

.fa-kaaba:before {
  content: "\f66b"; }

.fa-kaggle:before {
  content: "\f5fa"; }

.fa-key:before {
  content: "\f084"; }

.fa-keybase:before {
  content: "\f4f5"; }

.fa-keyboard:before {
  content: "\f11c"; }

.fa-keycdn:before {
  content: "\f3ba"; }

.fa-khanda:before {
  content: "\f66d"; }

.fa-kickstarter:before {
  content: "\f3bb"; }

.fa-kickstarter-k:before {
  content: "\f3bc"; }

.fa-kiss:before {
  content: "\f596"; }

.fa-kiss-beam:before {
  content: "\f597"; }

.fa-kiss-wink-heart:before {
  content: "\f598"; }

.fa-kiwi-bird:before {
  content: "\f535"; }

.fa-korvue:before {
  content: "\f42f"; }

.fa-landmark:before {
  content: "\f66f"; }

.fa-language:before {
  content: "\f1ab"; }

.fa-laptop:before {
  content: "\f109"; }

.fa-laptop-code:before {
  content: "\f5fc"; }

.fa-laptop-medical:before {
  content: "\f812"; }

.fa-laravel:before {
  content: "\f3bd"; }

.fa-lastfm:before {
  content: "\f202"; }

.fa-lastfm-square:before {
  content: "\f203"; }

.fa-laugh:before {
  content: "\f599"; }

.fa-laugh-beam:before {
  content: "\f59a"; }

.fa-laugh-squint:before {
  content: "\f59b"; }

.fa-laugh-wink:before {
  content: "\f59c"; }

.fa-layer-group:before {
  content: "\f5fd"; }

.fa-leaf:before {
  content: "\f06c"; }

.fa-leanpub:before {
  content: "\f212"; }

.fa-lemon:before {
  content: "\f094"; }

.fa-less:before {
  content: "\f41d"; }

.fa-less-than:before {
  content: "\f536"; }

.fa-less-than-equal:before {
  content: "\f537"; }

.fa-level-down-alt:before {
  content: "\f3be"; }

.fa-level-up-alt:before {
  content: "\f3bf"; }

.fa-life-ring:before {
  content: "\f1cd"; }

.fa-lightbulb:before {
  content: "\f0eb"; }

.fa-line:before {
  content: "\f3c0"; }

.fa-link:before {
  content: "\f0c1"; }

.fa-linkedin:before {
  content: "\f08c"; }

.fa-linkedin-in:before {
  content: "\f0e1"; }

.fa-linode:before {
  content: "\f2b8"; }

.fa-linux:before {
  content: "\f17c"; }

.fa-lira-sign:before {
  content: "\f195"; }

.fa-list:before {
  content: "\f03a"; }

.fa-list-alt:before {
  content: "\f022"; }

.fa-list-ol:before {
  content: "\f0cb"; }

.fa-list-ul:before {
  content: "\f0ca"; }

.fa-location-arrow:before {
  content: "\f124"; }

.fa-lock:before {
  content: "\f023"; }

.fa-lock-open:before {
  content: "\f3c1"; }

.fa-long-arrow-alt-down:before {
  content: "\f309"; }

.fa-long-arrow-alt-left:before {
  content: "\f30a"; }

.fa-long-arrow-alt-right:before {
  content: "\f30b"; }

.fa-long-arrow-alt-up:before {
  content: "\f30c"; }

.fa-low-vision:before {
  content: "\f2a8"; }

.fa-luggage-cart:before {
  content: "\f59d"; }

.fa-lyft:before {
  content: "\f3c3"; }

.fa-magento:before {
  content: "\f3c4"; }

.fa-magic:before {
  content: "\f0d0"; }

.fa-magnet:before {
  content: "\f076"; }

.fa-mail-bulk:before {
  content: "\f674"; }

.fa-mailchimp:before {
  content: "\f59e"; }

.fa-male:before {
  content: "\f183"; }

.fa-mandalorian:before {
  content: "\f50f"; }

.fa-map:before {
  content: "\f279"; }

.fa-map-marked:before {
  content: "\f59f"; }

.fa-map-marked-alt:before {
  content: "\f5a0"; }

.fa-map-marker:before {
  content: "\f041"; }

.fa-map-marker-alt:before {
  content: "\f3c5"; }

.fa-map-pin:before {
  content: "\f276"; }

.fa-map-signs:before {
  content: "\f277"; }

.fa-markdown:before {
  content: "\f60f"; }

.fa-marker:before {
  content: "\f5a1"; }

.fa-mars:before {
  content: "\f222"; }

.fa-mars-double:before {
  content: "\f227"; }

.fa-mars-stroke:before {
  content: "\f229"; }

.fa-mars-stroke-h:before {
  content: "\f22b"; }

.fa-mars-stroke-v:before {
  content: "\f22a"; }

.fa-mask:before {
  content: "\f6fa"; }

.fa-mastodon:before {
  content: "\f4f6"; }

.fa-maxcdn:before {
  content: "\f136"; }

.fa-medal:before {
  content: "\f5a2"; }

.fa-medapps:before {
  content: "\f3c6"; }

.fa-medium:before {
  content: "\f23a"; }

.fa-medium-m:before {
  content: "\f3c7"; }

.fa-medkit:before {
  content: "\f0fa"; }

.fa-medrt:before {
  content: "\f3c8"; }

.fa-meetup:before {
  content: "\f2e0"; }

.fa-megaport:before {
  content: "\f5a3"; }

.fa-meh:before {
  content: "\f11a"; }

.fa-meh-blank:before {
  content: "\f5a4"; }

.fa-meh-rolling-eyes:before {
  content: "\f5a5"; }

.fa-memory:before {
  content: "\f538"; }

.fa-mendeley:before {
  content: "\f7b3"; }

.fa-menorah:before {
  content: "\f676"; }

.fa-mercury:before {
  content: "\f223"; }

.fa-meteor:before {
  content: "\f753"; }

.fa-microchip:before {
  content: "\f2db"; }

.fa-microphone:before {
  content: "\f130"; }

.fa-microphone-alt:before {
  content: "\f3c9"; }

.fa-microphone-alt-slash:before {
  content: "\f539"; }

.fa-microphone-slash:before {
  content: "\f131"; }

.fa-microscope:before {
  content: "\f610"; }

.fa-microsoft:before {
  content: "\f3ca"; }

.fa-minus:before {
  content: "\f068"; }

.fa-minus-circle:before {
  content: "\f056"; }

.fa-minus-square:before {
  content: "\f146"; }

.fa-mitten:before {
  content: "\f7b5"; }

.fa-mix:before {
  content: "\f3cb"; }

.fa-mixcloud:before {
  content: "\f289"; }

.fa-mizuni:before {
  content: "\f3cc"; }

.fa-mobile:before {
  content: "\f10b"; }

.fa-mobile-alt:before {
  content: "\f3cd"; }

.fa-modx:before {
  content: "\f285"; }

.fa-monero:before {
  content: "\f3d0"; }

.fa-money-bill:before {
  content: "\f0d6"; }

.fa-money-bill-alt:before {
  content: "\f3d1"; }

.fa-money-bill-wave:before {
  content: "\f53a"; }

.fa-money-bill-wave-alt:before {
  content: "\f53b"; }

.fa-money-check:before {
  content: "\f53c"; }

.fa-money-check-alt:before {
  content: "\f53d"; }

.fa-monument:before {
  content: "\f5a6"; }

.fa-moon:before {
  content: "\f186"; }

.fa-mortar-pestle:before {
  content: "\f5a7"; }

.fa-mosque:before {
  content: "\f678"; }

.fa-motorcycle:before {
  content: "\f21c"; }

.fa-mountain:before {
  content: "\f6fc"; }

.fa-mouse-pointer:before {
  content: "\f245"; }

.fa-mug-hot:before {
  content: "\f7b6"; }

.fa-music:before {
  content: "\f001"; }

.fa-napster:before {
  content: "\f3d2"; }

.fa-neos:before {
  content: "\f612"; }

.fa-network-wired:before {
  content: "\f6ff"; }

.fa-neuter:before {
  content: "\f22c"; }

.fa-newspaper:before {
  content: "\f1ea"; }

.fa-nimblr:before {
  content: "\f5a8"; }

.fa-nintendo-switch:before {
  content: "\f418"; }

.fa-node:before {
  content: "\f419"; }

.fa-node-js:before {
  content: "\f3d3"; }

.fa-not-equal:before {
  content: "\f53e"; }

.fa-notes-medical:before {
  content: "\f481"; }

.fa-npm:before {
  content: "\f3d4"; }

.fa-ns8:before {
  content: "\f3d5"; }

.fa-nutritionix:before {
  content: "\f3d6"; }

.fa-object-group:before {
  content: "\f247"; }

.fa-object-ungroup:before {
  content: "\f248"; }

.fa-odnoklassniki:before {
  content: "\f263"; }

.fa-odnoklassniki-square:before {
  content: "\f264"; }

.fa-oil-can:before {
  content: "\f613"; }

.fa-old-republic:before {
  content: "\f510"; }

.fa-om:before {
  content: "\f679"; }

.fa-opencart:before {
  content: "\f23d"; }

.fa-openid:before {
  content: "\f19b"; }

.fa-opera:before {
  content: "\f26a"; }

.fa-optin-monster:before {
  content: "\f23c"; }

.fa-osi:before {
  content: "\f41a"; }

.fa-otter:before {
  content: "\f700"; }

.fa-outdent:before {
  content: "\f03b"; }

.fa-page4:before {
  content: "\f3d7"; }

.fa-pagelines:before {
  content: "\f18c"; }

.fa-pager:before {
  content: "\f815"; }

.fa-paint-brush:before {
  content: "\f1fc"; }

.fa-paint-roller:before {
  content: "\f5aa"; }

.fa-palette:before {
  content: "\f53f"; }

.fa-palfed:before {
  content: "\f3d8"; }

.fa-pallet:before {
  content: "\f482"; }

.fa-paper-plane:before {
  content: "\f1d8"; }

.fa-paperclip:before {
  content: "\f0c6"; }

.fa-parachute-box:before {
  content: "\f4cd"; }

.fa-paragraph:before {
  content: "\f1dd"; }

.fa-parking:before {
  content: "\f540"; }

.fa-passport:before {
  content: "\f5ab"; }

.fa-pastafarianism:before {
  content: "\f67b"; }

.fa-paste:before {
  content: "\f0ea"; }

.fa-patreon:before {
  content: "\f3d9"; }

.fa-pause:before {
  content: "\f04c"; }

.fa-pause-circle:before {
  content: "\f28b"; }

.fa-paw:before {
  content: "\f1b0"; }

.fa-paypal:before {
  content: "\f1ed"; }

.fa-peace:before {
  content: "\f67c"; }

.fa-pen:before {
  content: "\f304"; }

.fa-pen-alt:before {
  content: "\f305"; }

.fa-pen-fancy:before {
  content: "\f5ac"; }

.fa-pen-nib:before {
  content: "\f5ad"; }

.fa-pen-square:before {
  content: "\f14b"; }

.fa-pencil-alt:before {
  content: "\f303"; }

.fa-pencil-ruler:before {
  content: "\f5ae"; }

.fa-penny-arcade:before {
  content: "\f704"; }

.fa-people-carry:before {
  content: "\f4ce"; }

.fa-pepper-hot:before {
  content: "\f816"; }

.fa-percent:before {
  content: "\f295"; }

.fa-percentage:before {
  content: "\f541"; }

.fa-periscope:before {
  content: "\f3da"; }

.fa-person-booth:before {
  content: "\f756"; }

.fa-phabricator:before {
  content: "\f3db"; }

.fa-phoenix-framework:before {
  content: "\f3dc"; }

.fa-phoenix-squadron:before {
  content: "\f511"; }

.fa-phone:before {
  content: "\f095"; }

.fa-phone-slash:before {
  content: "\f3dd"; }

.fa-phone-square:before {
  content: "\f098"; }

.fa-phone-volume:before {
  content: "\f2a0"; }

.fa-php:before {
  content: "\f457"; }

.fa-pied-piper:before {
  content: "\f2ae"; }

.fa-pied-piper-alt:before {
  content: "\f1a8"; }

.fa-pied-piper-hat:before {
  content: "\f4e5"; }

.fa-pied-piper-pp:before {
  content: "\f1a7"; }

.fa-piggy-bank:before {
  content: "\f4d3"; }

.fa-pills:before {
  content: "\f484"; }

.fa-pinterest:before {
  content: "\f0d2"; }

.fa-pinterest-p:before {
  content: "\f231"; }

.fa-pinterest-square:before {
  content: "\f0d3"; }

.fa-pizza-slice:before {
  content: "\f818"; }

.fa-place-of-worship:before {
  content: "\f67f"; }

.fa-plane:before {
  content: "\f072"; }

.fa-plane-arrival:before {
  content: "\f5af"; }

.fa-plane-departure:before {
  content: "\f5b0"; }

.fa-play:before {
  content: "\f04b"; }

.fa-play-circle:before {
  content: "\f144"; }

.fa-playstation:before {
  content: "\f3df"; }

.fa-plug:before {
  content: "\f1e6"; }

.fa-plus:before {
  content: "\f067"; }

.fa-plus-circle:before {
  content: "\f055"; }

.fa-plus-square:before {
  content: "\f0fe"; }

.fa-podcast:before {
  content: "\f2ce"; }

.fa-poll:before {
  content: "\f681"; }

.fa-poll-h:before {
  content: "\f682"; }

.fa-poo:before {
  content: "\f2fe"; }

.fa-poo-storm:before {
  content: "\f75a"; }

.fa-poop:before {
  content: "\f619"; }

.fa-portrait:before {
  content: "\f3e0"; }

.fa-pound-sign:before {
  content: "\f154"; }

.fa-power-off:before {
  content: "\f011"; }

.fa-pray:before {
  content: "\f683"; }

.fa-praying-hands:before {
  content: "\f684"; }

.fa-prescription:before {
  content: "\f5b1"; }

.fa-prescription-bottle:before {
  content: "\f485"; }

.fa-prescription-bottle-alt:before {
  content: "\f486"; }

.fa-print:before {
  content: "\f02f"; }

.fa-procedures:before {
  content: "\f487"; }

.fa-product-hunt:before {
  content: "\f288"; }

.fa-project-diagram:before {
  content: "\f542"; }

.fa-pushed:before {
  content: "\f3e1"; }

.fa-puzzle-piece:before {
  content: "\f12e"; }

.fa-python:before {
  content: "\f3e2"; }

.fa-qq:before {
  content: "\f1d6"; }

.fa-qrcode:before {
  content: "\f029"; }

.fa-question:before {
  content: "\f128"; }

.fa-question-circle:before {
  content: "\f059"; }

.fa-quidditch:before {
  content: "\f458"; }

.fa-quinscape:before {
  content: "\f459"; }

.fa-quora:before {
  content: "\f2c4"; }

.fa-quote-left:before {
  content: "\f10d"; }

.fa-quote-right:before {
  content: "\f10e"; }

.fa-quran:before {
  content: "\f687"; }

.fa-r-project:before {
  content: "\f4f7"; }

.fa-radiation:before {
  content: "\f7b9"; }

.fa-radiation-alt:before {
  content: "\f7ba"; }

.fa-rainbow:before {
  content: "\f75b"; }

.fa-random:before {
  content: "\f074"; }

.fa-raspberry-pi:before {
  content: "\f7bb"; }

.fa-ravelry:before {
  content: "\f2d9"; }

.fa-react:before {
  content: "\f41b"; }

.fa-reacteurope:before {
  content: "\f75d"; }

.fa-readme:before {
  content: "\f4d5"; }

.fa-rebel:before {
  content: "\f1d0"; }

.fa-receipt:before {
  content: "\f543"; }

.fa-recycle:before {
  content: "\f1b8"; }

.fa-red-river:before {
  content: "\f3e3"; }

.fa-reddit:before {
  content: "\f1a1"; }

.fa-reddit-alien:before {
  content: "\f281"; }

.fa-reddit-square:before {
  content: "\f1a2"; }

.fa-redhat:before {
  content: "\f7bc"; }

.fa-redo:before {
  content: "\f01e"; }

.fa-redo-alt:before {
  content: "\f2f9"; }

.fa-registered:before {
  content: "\f25d"; }

.fa-renren:before {
  content: "\f18b"; }

.fa-reply:before {
  content: "\f3e5"; }

.fa-reply-all:before {
  content: "\f122"; }

.fa-replyd:before {
  content: "\f3e6"; }

.fa-republican:before {
  content: "\f75e"; }

.fa-researchgate:before {
  content: "\f4f8"; }

.fa-resolving:before {
  content: "\f3e7"; }

.fa-restroom:before {
  content: "\f7bd"; }

.fa-retweet:before {
  content: "\f079"; }

.fa-rev:before {
  content: "\f5b2"; }

.fa-ribbon:before {
  content: "\f4d6"; }

.fa-ring:before {
  content: "\f70b"; }

.fa-road:before {
  content: "\f018"; }

.fa-robot:before {
  content: "\f544"; }

.fa-rocket:before {
  content: "\f135"; }

.fa-rocketchat:before {
  content: "\f3e8"; }

.fa-rockrms:before {
  content: "\f3e9"; }

.fa-route:before {
  content: "\f4d7"; }

.fa-rss:before {
  content: "\f09e"; }

.fa-rss-square:before {
  content: "\f143"; }

.fa-ruble-sign:before {
  content: "\f158"; }

.fa-ruler:before {
  content: "\f545"; }

.fa-ruler-combined:before {
  content: "\f546"; }

.fa-ruler-horizontal:before {
  content: "\f547"; }

.fa-ruler-vertical:before {
  content: "\f548"; }

.fa-running:before {
  content: "\f70c"; }

.fa-rupee-sign:before {
  content: "\f156"; }

.fa-sad-cry:before {
  content: "\f5b3"; }

.fa-sad-tear:before {
  content: "\f5b4"; }

.fa-safari:before {
  content: "\f267"; }

.fa-sass:before {
  content: "\f41e"; }

.fa-satellite:before {
  content: "\f7bf"; }

.fa-satellite-dish:before {
  content: "\f7c0"; }

.fa-save:before {
  content: "\f0c7"; }

.fa-schlix:before {
  content: "\f3ea"; }

.fa-school:before {
  content: "\f549"; }

.fa-screwdriver:before {
  content: "\f54a"; }

.fa-scribd:before {
  content: "\f28a"; }

.fa-scroll:before {
  content: "\f70e"; }

.fa-sd-card:before {
  content: "\f7c2"; }

.fa-search:before {
  content: "\f002"; }

.fa-search-dollar:before {
  content: "\f688"; }

.fa-search-location:before {
  content: "\f689"; }

.fa-search-minus:before {
  content: "\f010"; }

.fa-search-plus:before {
  content: "\f00e"; }

.fa-searchengin:before {
  content: "\f3eb"; }

.fa-seedling:before {
  content: "\f4d8"; }

.fa-sellcast:before {
  content: "\f2da"; }

.fa-sellsy:before {
  content: "\f213"; }

.fa-server:before {
  content: "\f233"; }

.fa-servicestack:before {
  content: "\f3ec"; }

.fa-shapes:before {
  content: "\f61f"; }

.fa-share:before {
  content: "\f064"; }

.fa-share-alt:before {
  content: "\f1e0"; }

.fa-share-alt-square:before {
  content: "\f1e1"; }

.fa-share-square:before {
  content: "\f14d"; }

.fa-shekel-sign:before {
  content: "\f20b"; }

.fa-shield-alt:before {
  content: "\f3ed"; }

.fa-ship:before {
  content: "\f21a"; }

.fa-shipping-fast:before {
  content: "\f48b"; }

.fa-shirtsinbulk:before {
  content: "\f214"; }

.fa-shoe-prints:before {
  content: "\f54b"; }

.fa-shopping-bag:before {
  content: "\f290"; }

.fa-shopping-basket:before {
  content: "\f291"; }

.fa-shopping-cart:before {
  content: "\f07a"; }

.fa-shopware:before {
  content: "\f5b5"; }

.fa-shower:before {
  content: "\f2cc"; }

.fa-shuttle-van:before {
  content: "\f5b6"; }

.fa-sign:before {
  content: "\f4d9"; }

.fa-sign-in-alt:before {
  content: "\f2f6"; }

.fa-sign-language:before {
  content: "\f2a7"; }

.fa-sign-out-alt:before {
  content: "\f2f5"; }

.fa-signal:before {
  content: "\f012"; }

.fa-signature:before {
  content: "\f5b7"; }

.fa-sim-card:before {
  content: "\f7c4"; }

.fa-simplybuilt:before {
  content: "\f215"; }

.fa-sistrix:before {
  content: "\f3ee"; }

.fa-sitemap:before {
  content: "\f0e8"; }

.fa-sith:before {
  content: "\f512"; }

.fa-skating:before {
  content: "\f7c5"; }

.fa-sketch:before {
  content: "\f7c6"; }

.fa-skiing:before {
  content: "\f7c9"; }

.fa-skiing-nordic:before {
  content: "\f7ca"; }

.fa-skull:before {
  content: "\f54c"; }

.fa-skull-crossbones:before {
  content: "\f714"; }

.fa-skyatlas:before {
  content: "\f216"; }

.fa-skype:before {
  content: "\f17e"; }

.fa-slack:before {
  content: "\f198"; }

.fa-slack-hash:before {
  content: "\f3ef"; }

.fa-slash:before {
  content: "\f715"; }

.fa-sleigh:before {
  content: "\f7cc"; }

.fa-sliders-h:before {
  content: "\f1de"; }

.fa-slideshare:before {
  content: "\f1e7"; }

.fa-smile:before {
  content: "\f118"; }

.fa-smile-beam:before {
  content: "\f5b8"; }

.fa-smile-wink:before {
  content: "\f4da"; }

.fa-smog:before {
  content: "\f75f"; }

.fa-smoking:before {
  content: "\f48d"; }

.fa-smoking-ban:before {
  content: "\f54d"; }

.fa-sms:before {
  content: "\f7cd"; }

.fa-snapchat:before {
  content: "\f2ab"; }

.fa-snapchat-ghost:before {
  content: "\f2ac"; }

.fa-snapchat-square:before {
  content: "\f2ad"; }

.fa-snowboarding:before {
  content: "\f7ce"; }

.fa-snowflake:before {
  content: "\f2dc"; }

.fa-snowman:before {
  content: "\f7d0"; }

.fa-snowplow:before {
  content: "\f7d2"; }

.fa-socks:before {
  content: "\f696"; }

.fa-solar-panel:before {
  content: "\f5ba"; }

.fa-sort:before {
  content: "\f0dc"; }

.fa-sort-alpha-down:before {
  content: "\f15d"; }

.fa-sort-alpha-up:before {
  content: "\f15e"; }

.fa-sort-amount-down:before {
  content: "\f160"; }

.fa-sort-amount-up:before {
  content: "\f161"; }

.fa-sort-down:before {
  content: "\f0dd"; }

.fa-sort-numeric-down:before {
  content: "\f162"; }

.fa-sort-numeric-up:before {
  content: "\f163"; }

.fa-sort-up:before {
  content: "\f0de"; }

.fa-soundcloud:before {
  content: "\f1be"; }

.fa-sourcetree:before {
  content: "\f7d3"; }

.fa-spa:before {
  content: "\f5bb"; }

.fa-space-shuttle:before {
  content: "\f197"; }

.fa-speakap:before {
  content: "\f3f3"; }

.fa-spider:before {
  content: "\f717"; }

.fa-spinner:before {
  content: "\f110"; }

.fa-splotch:before {
  content: "\f5bc"; }

.fa-spotify:before {
  content: "\f1bc"; }

.fa-spray-can:before {
  content: "\f5bd"; }

.fa-square:before {
  content: "\f0c8"; }

.fa-square-full:before {
  content: "\f45c"; }

.fa-square-root-alt:before {
  content: "\f698"; }

.fa-squarespace:before {
  content: "\f5be"; }

.fa-stack-exchange:before {
  content: "\f18d"; }

.fa-stack-overflow:before {
  content: "\f16c"; }

.fa-stamp:before {
  content: "\f5bf"; }

.fa-star:before {
  content: "\f005"; }

.fa-star-and-crescent:before {
  content: "\f699"; }

.fa-star-half:before {
  content: "\f089"; }

.fa-star-half-alt:before {
  content: "\f5c0"; }

.fa-star-of-david:before {
  content: "\f69a"; }

.fa-star-of-life:before {
  content: "\f621"; }

.fa-staylinked:before {
  content: "\f3f5"; }

.fa-steam:before {
  content: "\f1b6"; }

.fa-steam-square:before {
  content: "\f1b7"; }

.fa-steam-symbol:before {
  content: "\f3f6"; }

.fa-step-backward:before {
  content: "\f048"; }

.fa-step-forward:before {
  content: "\f051"; }

.fa-stethoscope:before {
  content: "\f0f1"; }

.fa-sticker-mule:before {
  content: "\f3f7"; }

.fa-sticky-note:before {
  content: "\f249"; }

.fa-stop:before {
  content: "\f04d"; }

.fa-stop-circle:before {
  content: "\f28d"; }

.fa-stopwatch:before {
  content: "\f2f2"; }

.fa-store:before {
  content: "\f54e"; }

.fa-store-alt:before {
  content: "\f54f"; }

.fa-strava:before {
  content: "\f428"; }

.fa-stream:before {
  content: "\f550"; }

.fa-street-view:before {
  content: "\f21d"; }

.fa-strikethrough:before {
  content: "\f0cc"; }

.fa-stripe:before {
  content: "\f429"; }

.fa-stripe-s:before {
  content: "\f42a"; }

.fa-stroopwafel:before {
  content: "\f551"; }

.fa-studiovinari:before {
  content: "\f3f8"; }

.fa-stumbleupon:before {
  content: "\f1a4"; }

.fa-stumbleupon-circle:before {
  content: "\f1a3"; }

.fa-subscript:before {
  content: "\f12c"; }

.fa-subway:before {
  content: "\f239"; }

.fa-suitcase:before {
  content: "\f0f2"; }

.fa-suitcase-rolling:before {
  content: "\f5c1"; }

.fa-sun:before {
  content: "\f185"; }

.fa-superpowers:before {
  content: "\f2dd"; }

.fa-superscript:before {
  content: "\f12b"; }

.fa-supple:before {
  content: "\f3f9"; }

.fa-surprise:before {
  content: "\f5c2"; }

.fa-suse:before {
  content: "\f7d6"; }

.fa-swatchbook:before {
  content: "\f5c3"; }

.fa-swimmer:before {
  content: "\f5c4"; }

.fa-swimming-pool:before {
  content: "\f5c5"; }

.fa-synagogue:before {
  content: "\f69b"; }

.fa-sync:before {
  content: "\f021"; }

.fa-sync-alt:before {
  content: "\f2f1"; }

.fa-syringe:before {
  content: "\f48e"; }

.fa-table:before {
  content: "\f0ce"; }

.fa-table-tennis:before {
  content: "\f45d"; }

.fa-tablet:before {
  content: "\f10a"; }

.fa-tablet-alt:before {
  content: "\f3fa"; }

.fa-tablets:before {
  content: "\f490"; }

.fa-tachometer-alt:before {
  content: "\f3fd"; }

.fa-tag:before {
  content: "\f02b"; }

.fa-tags:before {
  content: "\f02c"; }

.fa-tape:before {
  content: "\f4db"; }

.fa-tasks:before {
  content: "\f0ae"; }

.fa-taxi:before {
  content: "\f1ba"; }

.fa-teamspeak:before {
  content: "\f4f9"; }

.fa-teeth:before {
  content: "\f62e"; }

.fa-teeth-open:before {
  content: "\f62f"; }

.fa-telegram:before {
  content: "\f2c6"; }

.fa-telegram-plane:before {
  content: "\f3fe"; }

.fa-temperature-high:before {
  content: "\f769"; }

.fa-temperature-low:before {
  content: "\f76b"; }

.fa-tencent-weibo:before {
  content: "\f1d5"; }

.fa-tenge:before {
  content: "\f7d7"; }

.fa-terminal:before {
  content: "\f120"; }

.fa-text-height:before {
  content: "\f034"; }

.fa-text-width:before {
  content: "\f035"; }

.fa-th:before {
  content: "\f00a"; }

.fa-th-large:before {
  content: "\f009"; }

.fa-th-list:before {
  content: "\f00b"; }

.fa-the-red-yeti:before {
  content: "\f69d"; }

.fa-theater-masks:before {
  content: "\f630"; }

.fa-themeco:before {
  content: "\f5c6"; }

.fa-themeisle:before {
  content: "\f2b2"; }

.fa-thermometer:before {
  content: "\f491"; }

.fa-thermometer-empty:before {
  content: "\f2cb"; }

.fa-thermometer-full:before {
  content: "\f2c7"; }

.fa-thermometer-half:before {
  content: "\f2c9"; }

.fa-thermometer-quarter:before {
  content: "\f2ca"; }

.fa-thermometer-three-quarters:before {
  content: "\f2c8"; }

.fa-think-peaks:before {
  content: "\f731"; }

.fa-thumbs-down:before {
  content: "\f165"; }

.fa-thumbs-up:before {
  content: "\f164"; }

.fa-thumbtack:before {
  content: "\f08d"; }

.fa-ticket-alt:before {
  content: "\f3ff"; }

.fa-times:before {
  content: "\f00d"; }

.fa-times-circle:before {
  content: "\f057"; }

.fa-tint:before {
  content: "\f043"; }

.fa-tint-slash:before {
  content: "\f5c7"; }

.fa-tired:before {
  content: "\f5c8"; }

.fa-toggle-off:before {
  content: "\f204"; }

.fa-toggle-on:before {
  content: "\f205"; }

.fa-toilet:before {
  content: "\f7d8"; }

.fa-toilet-paper:before {
  content: "\f71e"; }

.fa-toolbox:before {
  content: "\f552"; }

.fa-tools:before {
  content: "\f7d9"; }

.fa-tooth:before {
  content: "\f5c9"; }

.fa-torah:before {
  content: "\f6a0"; }

.fa-torii-gate:before {
  content: "\f6a1"; }

.fa-tractor:before {
  content: "\f722"; }

.fa-trade-federation:before {
  content: "\f513"; }

.fa-trademark:before {
  content: "\f25c"; }

.fa-traffic-light:before {
  content: "\f637"; }

.fa-train:before {
  content: "\f238"; }

.fa-tram:before {
  content: "\f7da"; }

.fa-transgender:before {
  content: "\f224"; }

.fa-transgender-alt:before {
  content: "\f225"; }

.fa-trash:before {
  content: "\f1f8"; }

.fa-trash-alt:before {
  content: "\f2ed"; }

.fa-trash-restore:before {
  content: "\f829"; }

.fa-trash-restore-alt:before {
  content: "\f82a"; }

.fa-tree:before {
  content: "\f1bb"; }

.fa-trello:before {
  content: "\f181"; }

.fa-tripadvisor:before {
  content: "\f262"; }

.fa-trophy:before {
  content: "\f091"; }

.fa-truck:before {
  content: "\f0d1"; }

.fa-truck-loading:before {
  content: "\f4de"; }

.fa-truck-monster:before {
  content: "\f63b"; }

.fa-truck-moving:before {
  content: "\f4df"; }

.fa-truck-pickup:before {
  content: "\f63c"; }

.fa-tshirt:before {
  content: "\f553"; }

.fa-tty:before {
  content: "\f1e4"; }

.fa-tumblr:before {
  content: "\f173"; }

.fa-tumblr-square:before {
  content: "\f174"; }

.fa-tv:before {
  content: "\f26c"; }

.fa-twitch:before {
  content: "\f1e8"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-twitter-square:before {
  content: "\f081"; }

.fa-typo3:before {
  content: "\f42b"; }

.fa-uber:before {
  content: "\f402"; }

.fa-ubuntu:before {
  content: "\f7df"; }

.fa-uikit:before {
  content: "\f403"; }

.fa-umbrella:before {
  content: "\f0e9"; }

.fa-umbrella-beach:before {
  content: "\f5ca"; }

.fa-underline:before {
  content: "\f0cd"; }

.fa-undo:before {
  content: "\f0e2"; }

.fa-undo-alt:before {
  content: "\f2ea"; }

.fa-uniregistry:before {
  content: "\f404"; }

.fa-universal-access:before {
  content: "\f29a"; }

.fa-university:before {
  content: "\f19c"; }

.fa-unlink:before {
  content: "\f127"; }

.fa-unlock:before {
  content: "\f09c"; }

.fa-unlock-alt:before {
  content: "\f13e"; }

.fa-untappd:before {
  content: "\f405"; }

.fa-upload:before {
  content: "\f093"; }

.fa-ups:before {
  content: "\f7e0"; }

.fa-usb:before {
  content: "\f287"; }

.fa-user:before {
  content: "\f007"; }

.fa-user-alt:before {
  content: "\f406"; }

.fa-user-alt-slash:before {
  content: "\f4fa"; }

.fa-user-astronaut:before {
  content: "\f4fb"; }

.fa-user-check:before {
  content: "\f4fc"; }

.fa-user-circle:before {
  content: "\f2bd"; }

.fa-user-clock:before {
  content: "\f4fd"; }

.fa-user-cog:before {
  content: "\f4fe"; }

.fa-user-edit:before {
  content: "\f4ff"; }

.fa-user-friends:before {
  content: "\f500"; }

.fa-user-graduate:before {
  content: "\f501"; }

.fa-user-injured:before {
  content: "\f728"; }

.fa-user-lock:before {
  content: "\f502"; }

.fa-user-md:before {
  content: "\f0f0"; }

.fa-user-minus:before {
  content: "\f503"; }

.fa-user-ninja:before {
  content: "\f504"; }

.fa-user-nurse:before {
  content: "\f82f"; }

.fa-user-plus:before {
  content: "\f234"; }

.fa-user-secret:before {
  content: "\f21b"; }

.fa-user-shield:before {
  content: "\f505"; }

.fa-user-slash:before {
  content: "\f506"; }

.fa-user-tag:before {
  content: "\f507"; }

.fa-user-tie:before {
  content: "\f508"; }

.fa-user-times:before {
  content: "\f235"; }

.fa-users:before {
  content: "\f0c0"; }

.fa-users-cog:before {
  content: "\f509"; }

.fa-usps:before {
  content: "\f7e1"; }

.fa-ussunnah:before {
  content: "\f407"; }

.fa-utensil-spoon:before {
  content: "\f2e5"; }

.fa-utensils:before {
  content: "\f2e7"; }

.fa-vaadin:before {
  content: "\f408"; }

.fa-vector-square:before {
  content: "\f5cb"; }

.fa-venus:before {
  content: "\f221"; }

.fa-venus-double:before {
  content: "\f226"; }

.fa-venus-mars:before {
  content: "\f228"; }

.fa-viacoin:before {
  content: "\f237"; }

.fa-viadeo:before {
  content: "\f2a9"; }

.fa-viadeo-square:before {
  content: "\f2aa"; }

.fa-vial:before {
  content: "\f492"; }

.fa-vials:before {
  content: "\f493"; }

.fa-viber:before {
  content: "\f409"; }

.fa-video:before {
  content: "\f03d"; }

.fa-video-slash:before {
  content: "\f4e2"; }

.fa-vihara:before {
  content: "\f6a7"; }

.fa-vimeo:before {
  content: "\f40a"; }

.fa-vimeo-square:before {
  content: "\f194"; }

.fa-vimeo-v:before {
  content: "\f27d"; }

.fa-vine:before {
  content: "\f1ca"; }

.fa-vk:before {
  content: "\f189"; }

.fa-vnv:before {
  content: "\f40b"; }

.fa-volleyball-ball:before {
  content: "\f45f"; }

.fa-volume-down:before {
  content: "\f027"; }

.fa-volume-mute:before {
  content: "\f6a9"; }

.fa-volume-off:before {
  content: "\f026"; }

.fa-volume-up:before {
  content: "\f028"; }

.fa-vote-yea:before {
  content: "\f772"; }

.fa-vr-cardboard:before {
  content: "\f729"; }

.fa-vuejs:before {
  content: "\f41f"; }

.fa-walking:before {
  content: "\f554"; }

.fa-wallet:before {
  content: "\f555"; }

.fa-warehouse:before {
  content: "\f494"; }

.fa-water:before {
  content: "\f773"; }

.fa-weebly:before {
  content: "\f5cc"; }

.fa-weibo:before {
  content: "\f18a"; }

.fa-weight:before {
  content: "\f496"; }

.fa-weight-hanging:before {
  content: "\f5cd"; }

.fa-weixin:before {
  content: "\f1d7"; }

.fa-whatsapp:before {
  content: "\f232"; }

.fa-whatsapp-square:before {
  content: "\f40c"; }

.fa-wheelchair:before {
  content: "\f193"; }

.fa-whmcs:before {
  content: "\f40d"; }

.fa-wifi:before {
  content: "\f1eb"; }

.fa-wikipedia-w:before {
  content: "\f266"; }

.fa-wind:before {
  content: "\f72e"; }

.fa-window-close:before {
  content: "\f410"; }

.fa-window-maximize:before {
  content: "\f2d0"; }

.fa-window-minimize:before {
  content: "\f2d1"; }

.fa-window-restore:before {
  content: "\f2d2"; }

.fa-windows:before {
  content: "\f17a"; }

.fa-wine-bottle:before {
  content: "\f72f"; }

.fa-wine-glass:before {
  content: "\f4e3"; }

.fa-wine-glass-alt:before {
  content: "\f5ce"; }

.fa-wix:before {
  content: "\f5cf"; }

.fa-wizards-of-the-coast:before {
  content: "\f730"; }

.fa-wolf-pack-battalion:before {
  content: "\f514"; }

.fa-won-sign:before {
  content: "\f159"; }

.fa-wordpress:before {
  content: "\f19a"; }

.fa-wordpress-simple:before {
  content: "\f411"; }

.fa-wpbeginner:before {
  content: "\f297"; }

.fa-wpexplorer:before {
  content: "\f2de"; }

.fa-wpforms:before {
  content: "\f298"; }

.fa-wpressr:before {
  content: "\f3e4"; }

.fa-wrench:before {
  content: "\f0ad"; }

.fa-x-ray:before {
  content: "\f497"; }

.fa-xbox:before {
  content: "\f412"; }

.fa-xing:before {
  content: "\f168"; }

.fa-xing-square:before {
  content: "\f169"; }

.fa-y-combinator:before {
  content: "\f23b"; }

.fa-yahoo:before {
  content: "\f19e"; }

.fa-yandex:before {
  content: "\f413"; }

.fa-yandex-international:before {
  content: "\f414"; }

.fa-yarn:before {
  content: "\f7e3"; }

.fa-yelp:before {
  content: "\f1e9"; }

.fa-yen-sign:before {
  content: "\f157"; }

.fa-yin-yang:before {
  content: "\f6ad"; }

.fa-yoast:before {
  content: "\f2b1"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-youtube-square:before {
  content: "\f431"; }

.fa-zhihu:before {
  content: "\f63f"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("./assets/webfonts/fa-solid-900.eot");
  src: url("./assets/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("./assets/webfonts/fa-solid-900.woff2") format("woff2"), url("./assets/webfonts/fa-solid-900.woff") format("woff"), url("./assets/webfonts/fa-solid-900.ttf") format("truetype"), url("./assets/webfonts/fa-solid-900.svg#fontawesome") format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900; }

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("./assets/webfonts/fa-brands-400.eot");
  src: url("./assets/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("./assets/webfonts/fa-brands-400.woff2") format("woff2"), url("./assets/webfonts/fa-brands-400.woff") format("woff"), url("./assets/webfonts/fa-brands-400.ttf") format("truetype"), url("./assets/webfonts/fa-brands-400.svg#fontawesome") format("svg"); }

.fab {
  font-family: 'Font Awesome 5 Brands'; }

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("./assets/webfonts/fa-regular-400.eot");
  src: url("./assets/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("./assets/webfonts/fa-regular-400.woff2") format("woff2"), url("./assets/webfonts/fa-regular-400.woff") format("woff"), url("./assets/webfonts/fa-regular-400.ttf") format("truetype"), url("./assets/webfonts/fa-regular-400.svg#fontawesome") format("svg"); }

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

iframe {
  border: none !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

html, body {
  font-size: 62.5%;
  font-family: "游ゴシック", YuGothic, "Helvetica Neue", Helvetica, Arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  color: #131313;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased; }

::selection {
  background: #00912e;
  color: #fff;
  text-shadow: none; }

/* 画面外にいる状態 */
.fadein {
  opacity: 0.1;
  transform: translate(0, 50px);
  transition: all 500ms; }

/* 画面内に入った状態 */
.fadein.scrollin {
  opacity: 1;
  transform: translate(0, 0); }

@keyframes anim-updown {
  0% {
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  50% {
    -moz-transform: scale(1, 1) translate(0, 10px);
    -ms-transform: scale(1, 1) translate(0, 10px);
    -webkit-transform: scale(1, 1) translate(0, 10px);
    transform: scale(1, 1) translate(0, 10px); }
  100% {
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }

.anim-updown {
  -webkit-animation: anim-updown 3s cubic-bezier(0.81, 0.32, 0.27, 0.72) 0s infinite;
  -moz-animation: anim-updown 3s cubic-bezier(0.81, 0.32, 0.27, 0.72) 0s infinite;
  animation: anim-updown 3s cubic-bezier(0.81, 0.32, 0.27, 0.72) 0s infinite;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both; }

.sp-show {
  display: inherit; }
  @media screen and (min-width: 999px) {
    .sp-show {
      display: none; } }

.pc-show {
  display: none; }
  @media screen and (min-width: 999px) {
    .pc-show {
      display: inherit; } }

.header {
  width: 100%;
  padding: 10px;
  z-index: 9999;
  position: relative;
  top: 0;
  left: 0;
  background-color: #fff; }
  .header img {
    width: 100%;
    height: auto; }
  @media screen and (min-width: 999px) {
    .header {
      padding: 0; } }
  .header .inr {
    position: relative;
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 999px) {
      .header .inr {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        width: 1100px; } }
    .header .inr .logo img {
      height: 45px;
      width: auto; }
      @media screen and (min-width: 999px) {
        .header .inr .logo img {
          height: auto;
          width: 100%; } }
    @media screen and (min-width: 999px) {
      .header .inr .logo {
        width: 50%;
        max-width: 230px; } }
    .header .inr .contactnav {
      display: none; }
      @media screen and (min-width: 999px) {
        .header .inr .contactnav {
          margin-top: 0;
          display: inherit; }
          .header .inr .contactnav nav ul {
            display: flex; }
            .header .inr .contactnav nav ul li {
              text-align: center;
              display: flex;
              border-right: 1px solid #c9caca; }
              .header .inr .contactnav nav ul li a {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                color: #131313;
                text-decoration: none;
                font-size: 1.6rem;
                opacity: 1;
                font-weight: bold;
                width: 120px;
                height: 100px;
                transition: all .1s ease; }
                .header .inr .contactnav nav ul li a:hover {
                  opacity: 1;
                  background-color: rgba(168, 204, 6, 0.25); }
                .header .inr .contactnav nav ul li a.bg-g {
                  background-color: #00912e;
                  color: #fff; }
                  .header .inr .contactnav nav ul li a.bg-g:hover {
                    background-color: rgba(0, 145, 46, 0.5); }
                  .header .inr .contactnav nav ul li a.bg-g span {
                    color: #fff; }
              .header .inr .contactnav nav ul li span {
                display: block;
                font-size: 1.1rem;
                color: #00912e; } }
      .header .inr .contactnav .snsnav {
        display: flex;
        flex-direction: column;
        justify-content: flex-end; }
        .header .inr .contactnav .snsnav a {
          color: #00912e;
          display: block;
          padding: 10px 15px;
          opacity: 1;
          transition: all .2s ease; }
          .header .inr .contactnav .snsnav a:hover {
            opacity: .5; }
          .header .inr .contactnav .snsnav a img {
            height: 20px; }
  .header .gnavWrap {
    display: none;
    position: absolute;
    right: -20px;
    left: -20px;
    top: 58px;
    background-color: #00912e;
    font-size: 1.4rem; }
    .header .gnavWrap .gnav {
      padding-top: 0px;
      padding-bottom: 60px; }
      .header .gnavWrap .gnav ul {
        display: flex;
        flex-wrap: wrap; }
      .header .gnavWrap .gnav li {
        padding: 10px 20px;
        border-bottom: 1px solid #fff;
        width: 50%; }
        .header .gnavWrap .gnav li:nth-of-type(odd) {
          border-right: 1px solid #fff; }
        .header .gnavWrap .gnav li a {
          text-decoration: none;
          color: #fff;
          display: block; }
      .header .gnavWrap .gnav .gnav-contact {
        margin-top: 20px; }
        .header .gnavWrap .gnav .gnav-contact .button a {
          display: block;
          max-width: 300px;
          width: 100%;
          padding: 15px 0px;
          font-size: 1.6rem;
          text-align: center;
          margin: 0 auto 30px;
          color: #fff;
          border: 2px solid #fff;
          text-decoration: none;
          position: relative;
          background-color: transparent;
          transition: all .2s ease; }
          .header .gnavWrap .gnav .gnav-contact .button a:hover {
            background-color: #fff;
            color: #fff; }
            .header .gnavWrap .gnav .gnav-contact .button a:hover::after {
              border-top: solid 2px #fff;
              border-right: solid 2px #fff; }
          .header .gnavWrap .gnav .gnav-contact .button a::after {
            content: '';
            width: 10px;
            height: 10px;
            border: 0px;
            border-top: solid 2px #fff;
            border-right: solid 2px #fff;
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 50%;
            right: 20px;
            margin-top: -4px;
            transition: all .2s ease; }
      .header .gnavWrap .gnav .gnav-sns {
        display: flex;
        justify-content: center;
        align-items: center; }
        .header .gnavWrap .gnav .gnav-sns a {
          display: block;
          margin: 0 10px; }
          .header .gnavWrap .gnav .gnav-sns a img {
            width: 50px; }

.home .header .contactnav ul li a {
  color: #fff !important; }

.home .menu .menu-line {
  background: #fff !important; }

.menu {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 53px;
  width: 35px;
  z-index: 9999; }
  .menu span {
    position: absolute;
    bottom: 7px;
    margin: 0;
    padding: 0; }
    .menu span img {
      width: 100%;
      height: auto; }
  @media screen and (min-width: 999px) {
    .menu {
      display: none; } }

.menu .menu-line {
  position: absolute;
  width: 35px;
  left: 0px;
  height: 3px;
  border-radius: 2px;
  background: #00912e; }

.menu .menu-line:nth-of-type(1) {
  top: 8px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu .menu-line:nth-of-type(2) {
  top: 16px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu .menu-line:nth-of-type(3) {
  top: 24px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu.open .menu-line {
  background: #00912e; }

.menu.open .menu-line:nth-of-type(1) {
  top: 16px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu.open .menu-line:nth-of-type(2) {
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu.open .menu-line:nth-of-type(3) {
  top: 16px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu .menu-text span {
  width: 30px;
  color: #00912e;
  position: absolute;
  left: 10px;
  bottom: 4px;
  line-height: 1;
  text-align: center; }

.menu .menu-text span img {
  width: 28px !important;
  height: auto; }

.footer {
  background-color: #fff;
  position: relative; }
  .footer .footer-inner {
    text-align: center; }
  .footer .footer-info {
    background-color: #a8cc06;
    padding: 10px 20px;
    text-align: center;
    color: #fff; }
    .footer .footer-info .title {
      font-size: 2.0rem; }
      .footer .footer-info .title span {
        font-size: 1.4rem; }
    .footer .footer-info .adr {
      font-size: 1.2rem; }
  .footer .footer-copyright {
    font-size: 1.4rem;
    padding: 20px 0; }
  .footer .pagetop {
    position: fixed;
    bottom: 10px;
    right: 10px; }
    @media screen and (min-width: 999px) {
      .footer .pagetop {
        bottom: 30px;
        right: 30px; } }
    .footer .pagetop a {
      display: block;
      width: 50px;
      height: 50px; }
      @media screen and (min-width: 999px) {
        .footer .pagetop a {
          width: 100px;
          height: 100px; } }
      .footer .pagetop a img {
        width: 100%;
        height: auto; }

#index .index-hero .slick-slide {
  opacity: 1;
  transition: all .2s ease; }
  @media screen and (min-width: 999px) {
    #index .index-hero .slick-slide {
      opacity: 0.6; } }

#index .index-hero .slick-center {
  opacity: 1; }

#index .index-hero .item img {
  width: 100%;
  height: auto; }

#index .index-news {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto 40px;
  padding: 0 5%; }
  @media screen and (min-width: 999px) {
    #index .index-news {
      margin: 40px auto 80px;
      padding: 0; } }
  #index .index-news .title {
    margin-bottom: 15px;
    font-family: "Questrial", "Helvetica Neue", Helvetica, Arial, serif;
    font-size: 2.25rem;
    font-weight: bold;
    color: #00912e;
    letter-spacing: 0.1em; }
    @media screen and (min-width: 999px) {
      #index .index-news .title {
        font-size: 3.4rem; } }
  #index .index-news .index-news__list .list .list-item {
    margin-bottom: 20px; }
    @media screen and (min-width: 999px) {
      #index .index-news .index-news__list .list .list-item {
        display: flex;
        align-items: center; } }
    #index .index-news .index-news__list .list .list-item .cat {
      margin-bottom: 10px;
      font-family: "Questrial", "Helvetica Neue", Helvetica, Arial, serif;
      font-size: 1.5rem;
      font-weight: bold;
      color: #00912e;
      letter-spacing: 0.1em; }
      @media screen and (min-width: 999px) {
        #index .index-news .index-news__list .list .list-item .cat {
          width: 90px;
          margin-right: 20px;
          margin-bottom: 0;
          font-size: 2.0rem;
          text-align: right; } }
    #index .index-news .index-news__list .list .list-item dl {
      display: flex;
      font-size: 1.2rem;
      font-weight: 300; }
      @media screen and (min-width: 999px) {
        #index .index-news .index-news__list .list .list-item dl {
          width: calc(100% - 110px);
          font-size: 2.0rem;
          align-items: center; } }
      #index .index-news .index-news__list .list .list-item dl dt {
        width: 80px;
        margin-right: 5px;
        color: #00912e; }
        @media screen and (min-width: 999px) {
          #index .index-news .index-news__list .list .list-item dl dt {
            width: 120px; } }
      #index .index-news .index-news__list .list .list-item dl dd {
        width: calc(100% - 85px); }
        @media screen and (min-width: 999px) {
          #index .index-news .index-news__list .list .list-item dl dd {
            width: calc(100% - 125px); } }
        #index .index-news .index-news__list .list .list-item dl dd a {
          color: #131313; }
          #index .index-news .index-news__list .list .list-item dl dd a:hover {
            text-decoration: none; }
  #index .index-news .index-news__list .list .is--new {
    padding-left: 4px;
    font-size: 12px;
    font-weight: bold;
    color: red; }
    @media screen and (min-width: 999px) {
      #index .index-news .index-news__list .list .is--new {
        font-size: 16px; } }

#index .index-concept {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto 40px;
  padding: 0 5%; }
  @media screen and (min-width: 999px) {
    #index .index-concept {
      margin: 40px auto 80px;
      padding: 0; } }
  #index .index-concept .copy {
    margin-bottom: 30px;
    font-size: 3.0rem;
    text-align: center; }
    @media screen and (min-width: 999px) {
      #index .index-concept .copy {
        font-size: 40px; }
        #index .index-concept .copy br {
          display: none; } }
  #index .index-concept .image {
    margin-bottom: 40px; }
    #index .index-concept .image img {
      display: block;
      width: 100%;
      max-width: 60%;
      margin: 0 auto; }
      @media screen and (min-width: 999px) {
        #index .index-concept .image img {
          max-width: 220px; } }
  #index .index-concept .text {
    font-size: 1.2rem;
    line-height: 2;
    text-align: center; }
    @media screen and (min-width: 999px) {
      #index .index-concept .text {
        font-size: 1.6rem; } }

#index .index-story {
  padding: 0px 0px 30px;
  background-color: #f9f3f0; }
  @media screen and (min-width: 999px) {
    #index .index-story {
      margin: 40px auto 0px;
      padding: 40px 0;
      background-image: url(./assets/img/storyback@2x.png);
      background-position: center top;
      background-size: cover; } }
  @media screen and (min-width: 999px) {
    #index .index-story .index-story__inner {
      width: 100%;
      max-width: 1000px;
      margin: 20px auto 0px; } }
  #index .index-story .index-story__inner .title {
    padding-top: 40px;
    background-image: url(./assets/img/storyback@2x.png);
    background-position: center top;
    background-size: cover;
    text-align: center; }
    @media screen and (min-width: 999px) {
      #index .index-story .index-story__inner .title {
        margin-bottom: 30px;
        padding-top: 0;
        background: none; } }
    #index .index-story .index-story__inner .title span {
      display: block;
      margin-bottom: 10px;
      font-size: 1.4rem;
      color: #604c3f; }
      @media screen and (min-width: 999px) {
        #index .index-story .index-story__inner .title span {
          margin-bottom: 20px;
          font-size: 2.0rem; } }
    #index .index-story .index-story__inner .title img {
      width: 100%;
      max-width: 90%;
      margin: 0 auto 40px; }
      @media screen and (min-width: 999px) {
        #index .index-story .index-story__inner .title img {
          max-width: 730px; } }
  #index .index-story .index-story__inner .list {
    display: flex;
    flex-direction: column;
    padding: 30px 5%; }
    @media screen and (min-width: 999px) {
      #index .index-story .index-story__inner .list {
        flex-direction: row;
        margin-bottom: 50px;
        padding: 0;
        justify-content: center; } }
    #index .index-story .index-story__inner .list .list-item {
      margin-top: -40px; }
      @media screen and (min-width: 999px) {
        #index .index-story .index-story__inner .list .list-item {
          width: 230px;
          margin: 0 20px;
          margin-top: 0; } }
      #index .index-story .index-story__inner .list .list-item:nth-of-type(even) {
        position: relative; }
        #index .index-story .index-story__inner .list .list-item:nth-of-type(even) span {
          margin-left: 40%; }
          @media screen and (min-width: 999px) {
            #index .index-story .index-story__inner .list .list-item:nth-of-type(even) span {
              margin-left: 0; } }
      #index .index-story .index-story__inner .list .list-item span {
        display: block;
        max-width: 60%; }
        @media screen and (min-width: 999px) {
          #index .index-story .index-story__inner .list .list-item span {
            max-width: 100%;
            opacity: 1; } }
        #index .index-story .index-story__inner .list .list-item span img {
          width: 100%; }
  #index .index-story .index-story__inner .button a {
    position: relative;
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 0 auto 30px;
    padding: 15px 0px;
    border: 2px solid #604c3f;
    background-color: transparent;
    font-size: 1.6rem;
    color: #604c3f;
    text-align: center;
    text-decoration: none;
    transition: all .2s ease; }
    #index .index-story .index-story__inner .button a:hover {
      background-color: #604c3f;
      color: #fff; }
      #index .index-story .index-story__inner .button a:hover::after {
        border-top: solid 2px #fff;
        border-right: solid 2px #fff; }
    #index .index-story .index-story__inner .button a::after {
      position: absolute;
      top: 50%;
      right: 20px;
      width: 10px;
      height: 10px;
      margin-top: -4px;
      border: 0px;
      border-top: solid 2px #604c3f;
      border-right: solid 2px #604c3f;
      content: '';
      transition: all .2s ease;
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }

#index .index-works {
  padding: 40px 5%;
  background-color: rgba(168, 204, 6, 0.1); }
  @media screen and (min-width: 999px) {
    #index .index-works {
      margin: 0px auto 0px;
      padding: 80px 0; } }
  #index .index-works .index-works__inner {
    width: 100%;
    max-width: 1000px;
    margin: 0px auto 40px; }
  #index .index-works .title {
    max-width: 100px;
    margin: 0 auto 30px; }
    @media screen and (min-width: 999px) {
      #index .index-works .title {
        max-width: 150px;
        margin: 0 auto 50px; } }
    #index .index-works .title img {
      width: 100%; }
  #index .index-works .list {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px; }
    @media screen and (min-width: 999px) {
      #index .index-works .list {
        flex-direction: row;
        margin-bottom: 50px;
        padding: 0 30px;
        justify-content: space-between; } }
    #index .index-works .list .list-item {
      margin-bottom: 20px; }
      @media screen and (min-width: 999px) {
        #index .index-works .list .list-item {
          width: 30%;
          margin: 0 3%; } }
      #index .index-works .list .list-item a {
        position: relative;
        display: block;
        opacity: 1;
        transition: all .2s ease; }
        #index .index-works .list .list-item a:hover {
          opacity: .7; }
        #index .index-works .list .list-item a .cat {
          position: absolute;
          top: 0;
          right: 0;
          width: 100px;
          padding: 5px 0;
          background-color: #00912e;
          font-size: 1.2rem;
          color: #fff;
          text-align: center; }
        #index .index-works .list .list-item a .image img {
          width: 100%;
          height: auto;
          vertical-align: middle; }
  #index .index-works .button a {
    position: relative;
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 0 auto 30px;
    padding: 15px 0px;
    border: 2px solid #00912e;
    background-color: transparent;
    font-size: 1.6rem;
    color: #00912e;
    text-align: center;
    text-decoration: none;
    transition: all .2s ease; }
    #index .index-works .button a:hover {
      background-color: #00912e;
      color: #fff; }
      #index .index-works .button a:hover::after {
        border-top: solid 2px #fff;
        border-right: solid 2px #fff; }
    #index .index-works .button a::after {
      position: absolute;
      top: 50%;
      right: 20px;
      width: 10px;
      height: 10px;
      margin-top: -4px;
      border: 0px;
      border-top: solid 2px #00912e;
      border-right: solid 2px #00912e;
      content: '';
      transition: all .2s ease;
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }

#index .index-voice {
  padding: 40px 0px 0px;
  background-color: #F1ECCC; }
  @media screen and (min-width: 999px) {
    #index .index-voice {
      margin: 0px auto 40px;
      padding: 80px 0; } }
  #index .index-voice .index-voice__inner {
    width: 100%;
    max-width: 1000px;
    margin: 0px auto 40px;
    padding-bottom: 10px; }
  #index .index-voice .title {
    max-width: 100px;
    margin: 0 auto 0px; }
    @media screen and (min-width: 999px) {
      #index .index-voice .title {
        max-width: 150px;
        margin: 0 auto 30px; } }
    #index .index-voice .title img {
      width: 100%; }
  #index .index-voice .subtitle {
    margin-top: 20px;
    font-size: 17px;
    color: #00912e;
    padding: 0 20px;
    text-align: center;
    margin-bottom: 40px; }
    @media screen and (min-width: 999px) {
      #index .index-voice .subtitle {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 80px; }
        #index .index-voice .subtitle br {
          display: none; } }
  #index .index-voice .list {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px; }
    @media screen and (min-width: 999px) {
      #index .index-voice .list {
        flex-direction: row;
        margin-bottom: 50px;
        padding: 0 30px;
        justify-content: space-between;
        margin-left: -20px; } }
    #index .index-voice .list .list-item {
      margin-bottom: 20px;
      padding: 0 10px; }
      @media screen and (min-width: 999px) {
        #index .index-voice .list .list-item {
          width: calc(33.3333% - 20px);
          margin-right: 20px; } }
      #index .index-voice .list .list-item a {
        position: relative;
        display: block;
        opacity: 1;
        transition: all .2s ease; }
        #index .index-voice .list .list-item a:hover {
          opacity: .7; }
        #index .index-voice .list .list-item a img {
          width: 100%;
          height: auto;
          vertical-align: middle; }
      #index .index-voice .list .list-item dl {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        font-size: 18px;
        color: #00912e;
        font-weight: bold; }
        #index .index-voice .list .list-item dl dt {
          text-align: center; }
  #index .index-voice .slide-arrow {
    position: absolute;
    top: 35%;
    z-index: 20; }
  #index .index-voice .prev-arrow {
    left: 10px;
    display: block;
    width: 35px;
    height: 100px;
    cursor: pointer;
    background-image: url(./assets/img/cursor-left-b.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    transition: all .1s ease; }
    @media screen and (min-width: 999px) {
      #index .index-voice .prev-arrow {
        width: 50px;
        height: 105px;
        left: -20px; } }
    #index .index-voice .prev-arrow:hover {
      opacity: 0.5; }
  #index .index-voice .next-arrow {
    right: 10px;
    display: block;
    width: 35px;
    height: 100px;
    cursor: pointer;
    background-image: url(./assets/img/cursor-right-b.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    transition: all .1s ease; }
    @media screen and (min-width: 999px) {
      #index .index-voice .next-arrow {
        width: 50px;
        height: 105px;
        right: -20px; } }
    #index .index-voice .next-arrow:hover {
      opacity: 0.5; }

#index .index-banner {
  width: 100%;
  max-width: 1000px;
  margin: 0px auto 40px;
  padding: 0px 5%; }
  @media screen and (min-width: 999px) {
    #index .index-banner {
      margin: 0px auto 40px;
      padding: 0px; } }
  #index .index-banner a {
    opacity: 1;
    transition: all .2s ease; }
    #index .index-banner a:hover {
      opacity: .7; }
    #index .index-banner a img {
      max-width: 100%; }

#index .index-blog {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto 40px;
  padding: 0px 5%; }
  @media screen and (min-width: 999px) {
    #index .index-blog {
      margin: 80px auto;
      padding: 0px; } }
  #index .index-blog .list {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 999px) {
      #index .index-blog .list {
        flex-direction: row;
        align-items: flex-end; } }
    #index .index-blog .list .list-item {
      width: 100%;
      margin-bottom: 20px; }
      @media screen and (min-width: 999px) {
        #index .index-blog .list .list-item {
          margin: 20px; } }
      #index .index-blog .list .list-item a {
        display: block;
        opacity: 1;
        transition: all .2s ease; }
        #index .index-blog .list .list-item a:hover {
          opacity: .7; }
        #index .index-blog .list .list-item a img {
          max-width: 100%; }

#index .index-sns {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto 40px;
  padding: 0px 5%; }
  @media screen and (min-width: 999px) {
    #index .index-sns {
      margin: 80px auto;
      padding: 0px; } }
  #index .index-sns .text {
    font-family: "Questrial", "Helvetica Neue", Helvetica, Arial, serif;
    font-size: 1.9rem;
    font-weight: bold;
    color: #00912e;
    text-align: center;
    letter-spacing: 0.1em; }
    @media screen and (min-width: 999px) {
      #index .index-sns .text {
        font-size: 2.6rem; } }
  #index .index-sns .list {
    display: flex;
    justify-content: center;
    align-items: center; }
    #index .index-sns .list .list-item {
      max-width: 80px;
      margin: 20px 10px; }
      @media screen and (min-width: 999px) {
        #index .index-sns .list .list-item {
          margin: 20px; } }
      #index .index-sns .list .list-item img {
        width: 100%; }
      #index .index-sns .list .list-item a {
        opacity: 1;
        transition: all .2s ease; }
        #index .index-sns .list .list-item a:hover {
          opacity: .7; }

#index .index-newsletter {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto 40px;
  padding: 0px 5%; }
  @media screen and (min-width: 999px) {
    #index .index-newsletter {
      margin: 80px auto;
      padding: 0px; } }
  #index .index-newsletter .list {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 999px) {
      #index .index-newsletter .list {
        flex-direction: row;
        align-items: flex-end; } }
    #index .index-newsletter .list .list-item {
      width: 100%;
      margin-bottom: 20px; }
      @media screen and (min-width: 999px) {
        #index .index-newsletter .list .list-item {
          margin: 20px; } }
      #index .index-newsletter .list .list-item a {
        display: block;
        opacity: 1;
        transition: all .2s ease; }
        #index .index-newsletter .list .list-item a:hover {
          opacity: .7; }
        #index .index-newsletter .list .list-item a img {
          max-width: 100%; }

#index .index-joinus {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto 40px;
  padding: 0px 5%; }
  @media screen and (min-width: 999px) {
    #index .index-joinus {
      margin: 80px auto;
      padding: 0px; } }
  #index .index-joinus .figure {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 999px) {
      #index .index-joinus .figure {
        flex-direction: row;
        align-items: center;
        justify-content: center; } }
    #index .index-joinus .figure dt {
      margin-bottom: 20px; }
      @media screen and (min-width: 999px) {
        #index .index-joinus .figure dt {
          width: 400px;
          margin-bottom: 0; } }
      #index .index-joinus .figure dt img {
        max-width: 100%; }
    @media screen and (min-width: 999px) {
      #index .index-joinus .figure dd {
        width: calc(100% - 450px);
        padding: 0 40px; } }
    #index .index-joinus .figure dd .text {
      margin-bottom: 30px;
      font-size: 1.2rem; }
      @media screen and (min-width: 999px) {
        #index .index-joinus .figure dd .text {
          font-size: 1.6rem;
          line-height: 1.8; } }
    #index .index-joinus .figure dd .button a {
      position: relative;
      display: block;
      width: 100%;
      max-width: 300px;
      margin: 0 auto 30px;
      padding: 15px 0px;
      border: 2px solid #00912e;
      background-color: transparent;
      font-size: 1.6rem;
      color: #00912e;
      text-align: center;
      text-decoration: none;
      transition: all .2s ease; }
      #index .index-joinus .figure dd .button a:hover {
        background-color: #00912e;
        color: #fff; }
        #index .index-joinus .figure dd .button a:hover::after {
          border-top: solid 2px #fff;
          border-right: solid 2px #fff; }
      #index .index-joinus .figure dd .button a::after {
        position: absolute;
        top: 50%;
        right: 20px;
        width: 10px;
        height: 10px;
        margin-top: -4px;
        border: 0px;
        border-top: solid 2px #00912e;
        border-right: solid 2px #00912e;
        content: '';
        transition: all .2s ease;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }

#index .index-links {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto 40px;
  padding: 0px 5%; }
  @media screen and (min-width: 999px) {
    #index .index-links {
      margin: 80px auto;
      padding: 0px; } }
  #index .index-links ul {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 999px) {
      #index .index-links ul {
        flex-direction: row;
        padding: 0 20px;
        flex-wrap: wrap;
        justify-content: space-between; } }
    #index .index-links ul li {
      margin-bottom: 20px; }
      @media screen and (min-width: 999px) {
        #index .index-links ul li {
          width: 48%;
          margin-bottom: 30px; } }
      #index .index-links ul li p {
        margin-bottom: 5px;
        font-size: 1.6rem; }
      #index .index-links ul li a {
        display: block;
        opacity: 1;
        transition: all .2s ease; }
        #index .index-links ul li a:hover {
          opacity: .7; }
        #index .index-links ul li a img {
          max-width: 100%; }

#index .index-about {
  padding: 40px 5%;
  background-color: #00912e; }
  @media screen and (min-width: 999px) {
    #index .index-about {
      margin: 80px auto 0;
      padding: 40px 100px; } }
  #index .index-about .index-about__inner {
    width: 100%;
    max-width: 1000px;
    margin: 20px auto 0px; }
  #index .index-about .title {
    width: 90px;
    margin: 0 auto 30px;
    text-align: center; }
    @media screen and (min-width: 999px) {
      #index .index-about .title {
        width: 120px; } }
    #index .index-about .title img {
      width: 100%; }
  #index .index-about .figure dl {
    display: flex;
    margin-bottom: 5px;
    justify-content: space-between; }
    #index .index-about .figure dl dt {
      width: 70px;
      padding: 10px;
      border: 1px solid #fff;
      font-size: 1.2rem;
      color: #fff;
      text-align: right; }
      @media screen and (min-width: 999px) {
        #index .index-about .figure dl dt {
          width: 200px;
          font-size: 1.6rem; } }
    #index .index-about .figure dl dd {
      width: calc(100% - 75px);
      padding: 10px;
      border: 1px solid #fff;
      background-color: #fff;
      font-size: 1.2rem; }
      @media screen and (min-width: 999px) {
        #index .index-about .figure dl dd {
          width: calc(100% - 205px);
          font-size: 1.6rem; } }
      @media screen and (min-width: 999px) {
        #index .index-about .figure dl dd .pc-show {
          display: inline !important; } }
  #index .index-about .zeh {
    margin-top: 60px; }
    @media screen and (min-width: 999px) {
      #index .index-about .zeh {
        margin-top: 100px; } }
    #index .index-about .zeh h3 {
      text-align: center;
      color: #fff;
      font-size: 18px; }
      @media screen and (min-width: 999px) {
        #index .index-about .zeh h3 {
          font-size: 24px; } }
    #index .index-about .zeh .zeh-table {
      margin-top: 10px;
      color: #fff;
      font-size: 1.2rem;
      padding-bottom: 30px; }
      @media screen and (min-width: 999px) {
        #index .index-about .zeh .zeh-table {
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
          font-size: 1.6rem;
          padding-bottom: 100px; } }
      #index .index-about .zeh .zeh-table table {
        width: 100%;
        max-width: 600px; }
        #index .index-about .zeh .zeh-table table th {
          width: 25%; }
        #index .index-about .zeh .zeh-table table td, #index .index-about .zeh .zeh-table table th {
          background-color: #fff;
          border: 2px solid #00912e;
          color: #131313;
          text-align: center;
          padding: 5px; }
          @media screen and (min-width: 999px) {
            #index .index-about .zeh .zeh-table table td, #index .index-about .zeh .zeh-table table th {
              padding: 10px; } }

#index .index-map {
  width: 100%;
  margin: 0px auto 0px; }
  @media screen and (min-width: 999px) {
    #index .index-map {
      margin: 0px auto 0; } }
  #index .index-map iframe {
    vertical-align: middle; }

#index .index-contact {
  padding: 40px 5%;
  background-color: rgba(168, 204, 6, 0.08); }
  @media screen and (min-width: 999px) {
    #index .index-contact {
      margin: 0px auto 0;
      padding: 80px 0; } }
  #index .index-contact .index-contact__inner {
    width: 100%;
    max-width: 1000px;
    margin: 0px auto 0px; }
  #index .index-contact .title {
    width: 90px;
    margin: 0 auto 30px;
    text-align: center; }
    @media screen and (min-width: 999px) {
      #index .index-contact .title {
        width: 120px; } }
    #index .index-contact .title img {
      width: 100%; }
  #index .index-contact .copy {
    margin-bottom: 10px;
    font-size: 2.5rem;
    line-height: 1;
    color: #00912e;
    text-align: center; }
    @media screen and (min-width: 999px) {
      #index .index-contact .copy {
        margin-bottom: 20px;
        font-size: 5rem; } }
  #index .index-contact .text {
    font-size: 1.2rem;
    color: #00912e;
    text-align: center; }
    @media screen and (min-width: 999px) {
      #index .index-contact .text {
        font-size: 2.0rem; } }
  #index .index-contact .contact-menu {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 999px) {
      #index .index-contact .contact-menu {
        flex-direction: row;
        align-items: flex-start; } }
    #index .index-contact .contact-menu .menu-item {
      margin-bottom: 30px; }
      @media screen and (min-width: 999px) {
        #index .index-contact .contact-menu .menu-item {
          margin: 20px 40px;
          margin-bottom: 0; } }
      #index .index-contact .contact-menu .menu-item dl dt {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        justify-content: center; }
        @media screen and (min-width: 999px) {
          #index .index-contact .contact-menu .menu-item dl dt {
            margin-bottom: 10px; } }
        #index .index-contact .contact-menu .menu-item dl dt span img {
          max-width: 30px;
          margin-right: 10px; }
        #index .index-contact .contact-menu .menu-item dl dt p {
          font-size: 1.5rem;
          color: #00912e; }
      #index .index-contact .contact-menu .menu-item .button a {
        position: relative;
        display: block;
        width: 100%;
        max-width: 300px;
        margin: 0 auto 30px;
        padding: 15px 0px;
        border: 2px solid #00912e;
        background-color: transparent;
        font-size: 1.6rem;
        color: #00912e;
        text-align: center;
        text-decoration: none;
        transition: all .2s ease; }
        #index .index-contact .contact-menu .menu-item .button a:hover {
          background-color: #00912e;
          color: #fff; }
          #index .index-contact .contact-menu .menu-item .button a:hover::after {
            border-top: solid 2px #fff;
            border-right: solid 2px #fff; }
        #index .index-contact .contact-menu .menu-item .button a::after {
          position: absolute;
          top: 50%;
          right: 20px;
          width: 10px;
          height: 10px;
          margin-top: -4px;
          border: 0px;
          border-top: solid 2px #00912e;
          border-right: solid 2px #00912e;
          content: '';
          transition: all .2s ease;
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }
      #index .index-contact .contact-menu .menu-item .tel {
        color: #00912e;
        text-align: center; }
        #index .index-contact .contact-menu .menu-item .tel p {
          font-size: 3.5rem; }
        #index .index-contact .contact-menu .menu-item .tel span {
          font-size: 1.4rem; }

#index .common-title {
  margin-bottom: 30px;
  border-bottom: 6px solid #f1eccc;
  font-size: 1.8rem;
  font-weight: bold;
  color: #00912e;
  text-align: center; }
  @media screen and (min-width: 999px) {
    #index .common-title {
      margin-bottom: 30px;
      border-bottom: 12px solid #f1eccc;
      font-size: 3.0rem; } }

/*-----------------------------------------------------------------------------------
    blog
-----------------------------------------------------------------------------------*/
#blog .blog-header {
  background-color: #e3dbc0;
  width: 100%;
  position: relative; }
  #blog .blog-header .image {
    text-align: center; }
    #blog .blog-header .image img {
      max-width: 100%;
      height: auto;
      max-height: 200px;
      vertical-align: middle; }
      @media screen and (min-width: 999px) {
        #blog .blog-header .image img {
          max-height: 300px; } }

#blog .side-nav {
  width: 100%;
  max-width: 1000px;
  margin: 0px auto 0px; }
  @media screen and (min-width: 999px) {
    #blog .side-nav {
      margin: 0px auto 0; } }
  #blog .side-nav .side-nav-banner {
    display: none; }
    @media screen and (min-width: 999px) {
      #blog .side-nav .side-nav-banner {
        display: inherit; }
        #blog .side-nav .side-nav-banner a {
          display: block; }
          #blog .side-nav .side-nav-banner a img {
            width: 100%; } }

#blog .blog-nav {
  padding: 20px 5%; }
  #blog .blog-nav p {
    font-size: 1.4rem;
    text-align: center; }

#blog .blog-wrap {
  width: 100%;
  max-width: 1000px;
  margin: 0px auto 0px;
  padding: 0px 5%; }
  @media screen and (min-width: 999px) {
    #blog .blog-wrap {
      margin: 0px auto 0;
      padding: 0; } }
  #blog .blog-wrap .entry .title {
    background-color: #e3dbc0;
    font-size: 1.8rem;
    border-left: 6px solid #604c3f;
    padding: 5px 10px;
    margin-bottom: 10px; }
    @media screen and (min-width: 999px) {
      #blog .blog-wrap .entry .title {
        font-size: 2.4rem;
        padding: 10px; } }
  #blog .blog-wrap .entry .entry__desc {
    font-size: 1.3rem;
    color: #595757;
    display: flex;
    margin-bottom: 20px; }
    @media screen and (min-width: 999px) {
      #blog .blog-wrap .entry .entry__desc {
        font-size: 1.6rem; } }
    #blog .blog-wrap .entry .entry__desc:before {
      content: "\f017";
      font-family: FontAwesome;
      margin-right: -20px; }
  #blog .blog-wrap .entry .entry__body {
    font-size: 1.4rem;
    line-height: 1.8; }
    @media screen and (min-width: 999px) {
      #blog .blog-wrap .entry .entry__body {
        font-size: 1.6rem;
        line-height: 2; } }
    #blog .blog-wrap .entry .entry__body p {
      margin-bottom: 20px; }
    #blog .blog-wrap .entry .entry__body img {
      margin-bottom: 20px;
      max-width: 100%; }
  #blog .blog-wrap .entry .entry-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 40px;
    font-family: "Questrial", "Helvetica Neue", Helvetica, Arial, serif;
    font-weight: bold; }
    @media screen and (min-width: 999px) {
      #blog .blog-wrap .entry .entry-nav {
        width: 600px;
        margin: 50px auto 100px;
        text-align: center;
        display: flex;
        justify-content: center; } }
    #blog .blog-wrap .entry .entry-nav__btn {
      margin: 10px 0px 0px;
      min-width: auto;
      display: block;
      width: 30%; }
      @media screen and (min-width: 999px) {
        #blog .blog-wrap .entry .entry-nav__btn {
          margin: 20px 20px;
          transition: all 0.2s ease; } }
      #blog .blog-wrap .entry .entry-nav__btn:hover {
        opacity: 0.5; }
      #blog .blog-wrap .entry .entry-nav__btn a {
        border: 1px solid #00912e;
        color: #00912e;
        font-size: 1.4rem;
        display: block;
        letter-spacing: 0.1em;
        text-decoration: none;
        padding: 10px;
        text-align: center; }
        @media screen and (min-width: 999px) {
          #blog .blog-wrap .entry .entry-nav__btn a {
            padding: 10px 20px;
            color: #00912e;
            font-size: 1.8rem;
            display: block; } }

#blog.blog2 .blog-header {
  background-color: #fbe5e3; }

#blog.blog2 .entry .title {
  background-color: #fbe5e3; }

#archive-blog1 .archive-blog-header {
  background-color: #e3dbc0;
  width: 100%;
  position: relative; }
  #archive-blog1 .archive-blog-header .image {
    text-align: center; }
    #archive-blog1 .archive-blog-header .image img {
      max-width: 100%;
      height: auto;
      max-height: 200px;
      vertical-align: middle; }
      @media screen and (min-width: 999px) {
        #archive-blog1 .archive-blog-header .image img {
          max-height: 300px; } }

#archive-blog1 .side-nav {
  width: 100%;
  max-width: 1000px;
  margin: 0px auto 0px; }
  @media screen and (min-width: 999px) {
    #archive-blog1 .side-nav {
      margin: 0px auto 0; } }
  #archive-blog1 .side-nav .side-nav-banner {
    display: none; }
    @media screen and (min-width: 999px) {
      #archive-blog1 .side-nav .side-nav-banner {
        display: inherit; }
        #archive-blog1 .side-nav .side-nav-banner a {
          display: block; }
          #archive-blog1 .side-nav .side-nav-banner a img {
            width: 100%; } }

#archive-blog1 .archive-blog-nav {
  padding: 20px 5%; }
  #archive-blog1 .archive-blog-nav p {
    font-size: 1.4rem;
    text-align: center; }

#archive-blog1 .archive-blog-list {
  width: 100%;
  max-width: 1000px;
  margin: 0px auto 0px;
  padding: 0px 5%; }
  @media screen and (min-width: 999px) {
    #archive-blog1 .archive-blog-list {
      margin: 0px auto 0;
      padding: 0; } }
  #archive-blog1 .archive-blog-list .entry-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5%; }
    @media screen and (min-width: 999px) {
      #archive-blog1 .archive-blog-list .entry-wrap {
        margin-left: -20px; } }
  #archive-blog1 .archive-blog-list .entry {
    margin-bottom: 30px;
    transition: all 0.2s ease;
    width: 45%;
    margin-left: 5%; }
    @media screen and (min-width: 999px) {
      #archive-blog1 .archive-blog-list .entry {
        width: calc(25% - 20px);
        margin-left: 20px; } }
    #archive-blog1 .archive-blog-list .entry a {
      display: block;
      text-decoration: none;
      color: #595757; }
    #archive-blog1 .archive-blog-list .entry img {
      width: 100%;
      height: auto; }
    #archive-blog1 .archive-blog-list .entry .title {
      font-size: 1.5rem;
      text-decoration: underline; }
    #archive-blog1 .archive-blog-list .entry .date {
      margin-bottom: 0px; }

#archive-blog1 .tablenav {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0; }
  #archive-blog1 .tablenav a {
    color: #000;
    margin: 0 20px;
    font-size: 1.6rem;
    text-decoration: none; }
  #archive-blog1 .tablenav span {
    color: #000;
    margin: 0 20px;
    font-size: 1.6rem;
    text-decoration: none; }

#archive-blog2 .archive-blog-header {
  background-color: #fbe5e3;
  width: 100%;
  position: relative; }
  #archive-blog2 .archive-blog-header .image {
    text-align: center; }
    #archive-blog2 .archive-blog-header .image img {
      max-width: 100%;
      height: auto;
      max-height: 200px;
      vertical-align: middle; }
      @media screen and (min-width: 999px) {
        #archive-blog2 .archive-blog-header .image img {
          max-height: 300px; } }

#archive-blog2 .side-nav {
  width: 100%;
  max-width: 1000px;
  margin: 0px auto 0px; }
  @media screen and (min-width: 999px) {
    #archive-blog2 .side-nav {
      margin: 0px auto 0; } }
  #archive-blog2 .side-nav .side-nav-banner {
    display: none; }
    @media screen and (min-width: 999px) {
      #archive-blog2 .side-nav .side-nav-banner {
        display: inherit; }
        #archive-blog2 .side-nav .side-nav-banner a {
          display: block; }
          #archive-blog2 .side-nav .side-nav-banner a img {
            width: 100%; } }

#archive-blog2 .archive-blog-nav {
  padding: 20px 5%; }
  #archive-blog2 .archive-blog-nav p {
    font-size: 1.4rem;
    text-align: center; }

#archive-blog2 .archive-blog-list {
  width: 100%;
  max-width: 1000px;
  margin: 0px auto 0px;
  padding: 0px 5%; }
  @media screen and (min-width: 999px) {
    #archive-blog2 .archive-blog-list {
      margin: 0px auto 0;
      padding: 0; } }
  #archive-blog2 .archive-blog-list .entry-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5%; }
    @media screen and (min-width: 999px) {
      #archive-blog2 .archive-blog-list .entry-wrap {
        margin-left: -20px; } }
  #archive-blog2 .archive-blog-list .entry {
    margin-bottom: 30px;
    transition: all 0.2s ease;
    width: 45%;
    margin-left: 5%; }
    @media screen and (min-width: 999px) {
      #archive-blog2 .archive-blog-list .entry {
        width: calc(25% - 20px);
        margin-left: 20px; } }
    #archive-blog2 .archive-blog-list .entry a {
      display: block;
      text-decoration: none;
      color: #595757; }
    #archive-blog2 .archive-blog-list .entry img {
      width: 100%;
      height: auto; }
    #archive-blog2 .archive-blog-list .entry .title {
      font-size: 1.5rem;
      text-decoration: underline; }
    #archive-blog2 .archive-blog-list .entry .date {
      margin-bottom: 0px; }

#archive-blog2 .tablenav {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0; }
  #archive-blog2 .tablenav a {
    color: #000;
    margin: 0 20px;
    font-size: 1.6rem;
    text-decoration: none; }
  #archive-blog2 .tablenav span {
    color: #000;
    margin: 0 20px;
    font-size: 1.6rem;
    text-decoration: none; }

/*-----------------------------------------------------------------------------------
    sidebar
-----------------------------------------------------------------------------------*/
@media screen and (min-width: 999px) {
  .side-nav {
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center; } }

.side-nav .side-nav-col {
  margin-bottom: 10px; }
  @media screen and (min-width: 999px) {
    .side-nav .side-nav-col {
      width: 30%; } }
  .side-nav .side-nav-col form {
    display: block;
    width: 100%; }
  .side-nav .side-nav-col select {
    background-color: #eee;
    display: block;
    width: 100%;
    padding: 10px 20px;
    position: relative;
    border: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-size: 1.3rem;
    border-radius: 4px; }
  .side-nav .side-nav-col label {
    position: relative;
    display: block; }
    .side-nav .side-nav-col label:after {
      content: "\f0dc";
      font-family: FontAwesome;
      position: absolute;
      top: 10px;
      right: 20px;
      display: block;
      pointer-events: none; }

/*-----------------------------------------------------------------------------------
    work
-----------------------------------------------------------------------------------*/
#work {
  width: 100%;
  padding: 0 5%; }
  @media screen and (min-width: 999px) {
    #work {
      padding: 0;
      margin: 40px auto 0px; } }
  @media screen and (min-width: 999px) {
    #work .work__inner {
      max-width: 1000px;
      margin: 20px auto 0px; } }
  #work .work-header {
    margin-bottom: 40px; }
    #work .work-header h2 {
      font-size: 1.4rem; }
      @media screen and (min-width: 999px) {
        #work .work-header h2 {
          font-size: 2.8rem; } }
      #work .work-header h2 span {
        color: #a8cc06;
        padding-right: 10px; }
  #work .work-gallery {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 999px) {
      #work .work-gallery {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start; } }
    #work .work-gallery .work-gallery__main {
      width: 100%;
      margin-bottom: 20px;
      touch-action: auto !important; }
      @media screen and (min-width: 999px) {
        #work .work-gallery .work-gallery__main {
          width: 780px;
          background-color: #f4f4f4;
          padding: 20px; }
          #work .work-gallery .work-gallery__main .slick-slide {
            display: flex;
            align-items: center;
            justify-content: center; } }
      #work .work-gallery .work-gallery__main .slick-track {
        display: flex;
        align-items: center;
        justify-content: center; }
      #work .work-gallery .work-gallery__main .item {
        width: 100%; }
        #work .work-gallery .work-gallery__main .item img {
          width: auto;
          max-width: 100%;
          height: auto; }
    #work .work-gallery .slide-arrow {
      position: absolute;
      top: 45%;
      z-index: 20; }
    #work .work-gallery .prev-arrow {
      left: -10px;
      display: block;
      width: 35px;
      height: 35px;
      cursor: pointer;
      background-image: url(./assets/img/cursor-left.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% auto;
      transition: all .1s ease; }
      @media screen and (min-width: 999px) {
        #work .work-gallery .prev-arrow {
          width: 50px;
          height: 50px;
          left: 20px; } }
      #work .work-gallery .prev-arrow:hover {
        opacity: 0.5; }
    #work .work-gallery .next-arrow {
      right: -10px;
      display: block;
      width: 35px;
      height: 35px;
      cursor: pointer;
      background-image: url(./assets/img/cursor-right.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% auto;
      transition: all .1s ease; }
      @media screen and (min-width: 999px) {
        #work .work-gallery .next-arrow {
          width: 50px;
          height: 50px;
          right: 20px; } }
      #work .work-gallery .next-arrow:hover {
        opacity: 0.5; }
    #work .work-gallery .work-gallery__nav {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px; }
      @media screen and (min-width: 999px) {
        #work .work-gallery .work-gallery__nav {
          width: 200px; } }
      #work .work-gallery .work-gallery__nav .item {
        width: 20%;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 4px solid #fff; }
        @media screen and (min-width: 999px) {
          #work .work-gallery .work-gallery__nav .item {
            width: 50%; } }
        #work .work-gallery .work-gallery__nav .item img {
          width: 100%;
          height: auto; }
        #work .work-gallery .work-gallery__nav .item.thumbnail-current {
          border: 4px solid #00912e; }
  #work .work-caption .title {
    font-size: 2.1rem;
    margin-bottom: 10px; }
  #work .work-caption .text {
    font-size: 1.6rem; }
  #work .work-shere {
    margin: 40px 0; }
    #work .work-shere ul {
      display: flex; }
      #work .work-shere ul li {
        margin-right: 30px; }
        #work .work-shere ul li a {
          display: block; }
          #work .work-shere ul li a img {
            height: 30px; }
  #work .work-ba {
    margin-bottom: 40px; }
    #work .work-ba .title {
      font-size: 2.1rem;
      color: #a8cc06;
      border-bottom: 1px solid #a8cc06;
      padding-bottom: 2px;
      margin-bottom: 20px; }
      @media screen and (min-width: 999px) {
        #work .work-ba .title {
          font-size: 2.8rem;
          margin-bottom: 30px; } }
    #work .work-ba .figure {
      display: flex;
      flex-direction: column; }
      @media screen and (min-width: 999px) {
        #work .work-ba .figure {
          flex-direction: row;
          justify-content: space-between; } }
      #work .work-ba .figure dl {
        margin-bottom: 20px; }
        @media screen and (min-width: 999px) {
          #work .work-ba .figure dl {
            width: 48%; } }
        #work .work-ba .figure dl dt p {
          font-size: 1.4rem;
          border: 1px solid #000;
          display: inline-block;
          padding: 5px 10px;
          margin-bottom: 5px; }
        #work .work-ba .figure dl dt img {
          max-width: 100%; }
        #work .work-ba .figure dl dd {
          font-size: 1.4rem; }
  #work .work-planning {
    margin-bottom: 40px; }
    #work .work-planning .title {
      font-size: 2.1rem;
      color: #a8cc06;
      border-bottom: 1px solid #a8cc06;
      padding-bottom: 2px;
      margin-bottom: 20px; }
      @media screen and (min-width: 999px) {
        #work .work-planning .title {
          font-size: 2.8rem;
          margin-bottom: 30px; } }
    #work .work-planning .image img {
      max-width: 100%;
      width: auto; }
  #work .work-pickup {
    margin-bottom: 40px; }
    #work .work-pickup .title {
      font-size: 2.1rem;
      color: #a8cc06;
      border-bottom: 1px solid #a8cc06;
      padding-bottom: 2px;
      margin-bottom: 20px; }
      @media screen and (min-width: 999px) {
        #work .work-pickup .title {
          font-size: 2.8rem;
          margin-bottom: 30px; } }
    #work .work-pickup dl {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column; }
      @media screen and (min-width: 999px) {
        #work .work-pickup dl {
          flex-direction: row;
          justify-content: space-between; } }
      @media screen and (min-width: 999px) {
        #work .work-pickup dl dt {
          width: 450px; } }
      #work .work-pickup dl dt p {
        font-size: 1.4rem;
        border: 1px solid #000;
        display: inline-block;
        padding: 5px 10px;
        margin-bottom: 5px; }
      #work .work-pickup dl dt img {
        max-width: 100%; }
      #work .work-pickup dl dd {
        font-size: 1.4rem; }
        @media screen and (min-width: 999px) {
          #work .work-pickup dl dd {
            width: calc(100% - 470px);
            font-size: 1.6rem;
            line-height: 1.8; } }
  #work .work-point {
    margin-bottom: 40px; }
    #work .work-point .title {
      background-color: #ecf2d9;
      border-left: 8px solid #a8cc06;
      font-size: 1.8rem;
      color: #595757;
      padding: 10px;
      margin-bottom: 20px;
      margin-top: 40px; }
    #work .work-point .text {
      margin-bottom: 20px; }
      #work .work-point .text p {
        font-size: 1.6rem;
        line-height: 1.8;
        margin-bottom: 15px; }
      #work .work-point .text h1, #work .work-point .text h2, #work .work-point .text h3, #work .work-point .text h4, #work .work-point .text h5 {
        font-size: 2.0rem;
        color: #a8cc06;
        margin-bottom: 15px; }
  #work .work-contact {
    background-color: rgba(168, 204, 6, 0.08);
    padding: 40px 5%; }
    @media screen and (min-width: 999px) {
      #work .work-contact {
        margin: 80px auto 0;
        padding: 80px 0; } }
    #work .work-contact .work-contact__inner {
      width: 100%;
      max-width: 1000px;
      margin: 0px auto 0px; }
    #work .work-contact .title {
      text-align: center;
      width: 90px;
      margin: 0 auto 30px; }
      @media screen and (min-width: 999px) {
        #work .work-contact .title {
          width: 120px; } }
      #work .work-contact .title img {
        width: 100%; }
    #work .work-contact .copy {
      text-align: center;
      font-size: 2.5rem;
      color: #00912e;
      margin-bottom: 10px;
      line-height: 1; }
      @media screen and (min-width: 999px) {
        #work .work-contact .copy {
          font-size: 5rem;
          margin-bottom: 20px; } }
    #work .work-contact .text {
      text-align: center;
      font-size: 1.2rem;
      color: #00912e; }
      @media screen and (min-width: 999px) {
        #work .work-contact .text {
          font-size: 2.0rem; } }
    #work .work-contact .contact-menu {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      @media screen and (min-width: 999px) {
        #work .work-contact .contact-menu {
          flex-direction: row;
          align-items: flex-start; } }
      #work .work-contact .contact-menu .menu-item {
        margin-bottom: 30px; }
        @media screen and (min-width: 999px) {
          #work .work-contact .contact-menu .menu-item {
            margin-bottom: 0;
            margin: 20px 40px; } }
        #work .work-contact .contact-menu .menu-item dl dt {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px; }
          @media screen and (min-width: 999px) {
            #work .work-contact .contact-menu .menu-item dl dt {
              margin-bottom: 10px; } }
          #work .work-contact .contact-menu .menu-item dl dt span img {
            max-width: 30px;
            margin-right: 10px; }
          #work .work-contact .contact-menu .menu-item dl dt p {
            font-size: 1.5rem;
            color: #00912e; }
        #work .work-contact .contact-menu .menu-item .button a {
          display: block;
          max-width: 300px;
          width: 100%;
          padding: 15px 0px;
          font-size: 1.6rem;
          text-align: center;
          margin: 0 auto 30px;
          color: #00912e;
          border: 2px solid #00912e;
          text-decoration: none;
          position: relative;
          background-color: transparent;
          transition: all .2s ease; }
          #work .work-contact .contact-menu .menu-item .button a:hover {
            background-color: #00912e;
            color: #fff; }
            #work .work-contact .contact-menu .menu-item .button a:hover::after {
              border-top: solid 2px #fff;
              border-right: solid 2px #fff; }
          #work .work-contact .contact-menu .menu-item .button a::after {
            content: '';
            width: 10px;
            height: 10px;
            border: 0px;
            border-top: solid 2px #00912e;
            border-right: solid 2px #00912e;
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 50%;
            right: 20px;
            margin-top: -4px;
            transition: all .2s ease; }
        #work .work-contact .contact-menu .menu-item .tel {
          color: #00912e;
          text-align: center; }
          #work .work-contact .contact-menu .menu-item .tel p {
            font-size: 3.5rem; }
          #work .work-contact .contact-menu .menu-item .tel span {
            font-size: 1.4rem; }

#archive-work {
  padding: 0 5%; }
  @media screen and (min-width: 999px) {
    #archive-work {
      padding: 0;
      margin: 0px auto 0px; } }
  #archive-work .archive-work-header {
    background-image: url(./assets/img/wokstop@2x.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    font-size: 2.5rem;
    color: #00912e;
    font-family: "Questrial", "Helvetica Neue", Helvetica, Arial, serif;
    font-weight: bold;
    letter-spacing: 0.01em;
    margin-bottom: 30px; }
    @media screen and (min-width: 999px) {
      #archive-work .archive-work-header {
        min-height: 300px;
        font-size: 5.1rem; } }
  #archive-work .archive-work-nav {
    margin-bottom: 20px; }
    @media screen and (min-width: 999px) {
      #archive-work .archive-work-nav {
        padding: 0 100px;
        width: 100%;
        max-width: 1000px;
        margin: 0px auto 0px; } }
    #archive-work .archive-work-nav ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: -3%; }
      @media screen and (min-width: 999px) {
        #archive-work .archive-work-nav ul {
          padding: 40px 0;
          margin-left: -2%; } }
      #archive-work .archive-work-nav ul li {
        width: 47%;
        margin-left: 3%;
        margin-bottom: 20px; }
        @media screen and (min-width: 999px) {
          #archive-work .archive-work-nav ul li {
            width: 31%;
            margin-left: 2%; } }
        #archive-work .archive-work-nav ul li a {
          display: block;
          color: #00912e;
          font-size: 1.6rem;
          text-decoration: none;
          padding: 5px;
          text-align: center;
          width: 100%;
          border: 1px solid #00912e; }
          @media screen and (min-width: 999px) {
            #archive-work .archive-work-nav ul li a {
              transition: all .2s ease; }
              #archive-work .archive-work-nav ul li a:hover {
                background-color: #00912e;
                color: #fff; } }
  #archive-work .archive-work-banner {
    margin-bottom: 40px; }
    @media screen and (min-width: 999px) {
      #archive-work .archive-work-banner {
        padding: 0 80px;
        width: 100%;
        max-width: 1000px;
        margin: 0px auto 0px; } }
    #archive-work .archive-work-banner a {
      display: block;
      opacity: 1;
      transition: all .2s ease; }
      #archive-work .archive-work-banner a:hover {
        opacity: .75; }
      #archive-work .archive-work-banner a img {
        max-width: 100%; }
  @media screen and (min-width: 999px) {
    #archive-work .archive-work-old {
      padding: 50px 0; } }
  #archive-work .archive-work-old p {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 10px; }
    @media screen and (min-width: 999px) {
      #archive-work .archive-work-old p {
        font-size: 1.6rem;
        margin-bottom: 20px; } }
  #archive-work .archive-work-old ul {
    display: flex;
    justify-content: center; }
    #archive-work .archive-work-old ul li {
      margin: 0 5px;
      border-right: 1px solid #00912e;
      padding: 0 20px; }
      @media screen and (min-width: 999px) {
        #archive-work .archive-work-old ul li {
          padding: 0 30px; } }
      #archive-work .archive-work-old ul li:first-child {
        border-left: 1px solid #00912e; }
      #archive-work .archive-work-old ul li a {
        color: #00912e;
        font-size: 1.2rem;
        text-decoration: none; }
        @media screen and (min-width: 999px) {
          #archive-work .archive-work-old ul li a {
            font-size: 1.6rem; } }
        #archive-work .archive-work-old ul li a:hover {
          text-decoration: underline; }
  #archive-work .archive-work-list {
    margin: 50px 0; }
    @media screen and (min-width: 999px) {
      #archive-work .archive-work-list {
        width: 100%;
        max-width: 1000px;
        margin: 40px auto; } }
    #archive-work .archive-work-list .entry-wrap {
      display: flex;
      flex-wrap: wrap;
      margin-left: -3%; }
      #archive-work .archive-work-list .entry-wrap .entry {
        margin-bottom: 20px;
        width: 47%;
        margin-left: 3%; }
        @media screen and (min-width: 999px) {
          #archive-work .archive-work-list .entry-wrap .entry {
            width: 22%;
            margin-bottom: 30px; } }
        #archive-work .archive-work-list .entry-wrap .entry a {
          display: block;
          position: relative;
          text-decoration: none;
          opacity: 1;
          transition: all .2s ease; }
          #archive-work .archive-work-list .entry-wrap .entry a:hover {
            opacity: .7; }
          #archive-work .archive-work-list .entry-wrap .entry a .cat {
            position: absolute;
            right: 0;
            top: 0;
            color: #fff;
            background-color: #00912e;
            width: 100px;
            font-size: 1.2rem;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center; }
          #archive-work .archive-work-list .entry-wrap .entry a img {
            width: 100%;
            height: auto;
            vertical-align: middle; }
      #archive-work .archive-work-list .entry-wrap .no-data {
        color: #00912e;
        text-align: center;
        font-size: 1.3rem;
        padding: 40px 0;
        width: 100%; }
        #archive-work .archive-work-list .entry-wrap .no-data .strong {
          font-size: 4.0rem;
          font-weight: bold; }
  #archive-work .work-contact {
    background-color: rgba(168, 204, 6, 0.08);
    padding: 40px 5%; }
    @media screen and (min-width: 999px) {
      #archive-work .work-contact {
        margin: 80px auto 0;
        padding: 80px 0; } }
    #archive-work .work-contact .work-contact__inner {
      width: 100%;
      max-width: 1000px;
      margin: 0px auto 0px; }
    #archive-work .work-contact .title {
      text-align: center;
      width: 90px;
      margin: 0 auto 30px; }
      @media screen and (min-width: 999px) {
        #archive-work .work-contact .title {
          width: 120px; } }
      #archive-work .work-contact .title img {
        width: 100%; }
    #archive-work .work-contact .copy {
      text-align: center;
      font-size: 2.5rem;
      color: #00912e;
      margin-bottom: 10px;
      line-height: 1; }
      @media screen and (min-width: 999px) {
        #archive-work .work-contact .copy {
          font-size: 5rem;
          margin-bottom: 20px; } }
    #archive-work .work-contact .text {
      text-align: center;
      font-size: 1.2rem;
      color: #00912e; }
      @media screen and (min-width: 999px) {
        #archive-work .work-contact .text {
          font-size: 2.0rem; } }
    #archive-work .work-contact .contact-menu {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      @media screen and (min-width: 999px) {
        #archive-work .work-contact .contact-menu {
          flex-direction: row;
          align-items: flex-start; } }
      #archive-work .work-contact .contact-menu .menu-item {
        margin-bottom: 30px; }
        @media screen and (min-width: 999px) {
          #archive-work .work-contact .contact-menu .menu-item {
            margin-bottom: 0;
            margin: 20px 40px; } }
        #archive-work .work-contact .contact-menu .menu-item dl dt {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px; }
          @media screen and (min-width: 999px) {
            #archive-work .work-contact .contact-menu .menu-item dl dt {
              margin-bottom: 10px; } }
          #archive-work .work-contact .contact-menu .menu-item dl dt span img {
            max-width: 30px;
            margin-right: 10px; }
          #archive-work .work-contact .contact-menu .menu-item dl dt p {
            font-size: 1.5rem;
            color: #00912e; }
        #archive-work .work-contact .contact-menu .menu-item .button a {
          display: block;
          max-width: 300px;
          width: 100%;
          padding: 15px 0px;
          font-size: 1.6rem;
          text-align: center;
          margin: 0 auto 30px;
          color: #00912e;
          border: 2px solid #00912e;
          text-decoration: none;
          position: relative;
          background-color: transparent;
          transition: all .2s ease; }
          #archive-work .work-contact .contact-menu .menu-item .button a:hover {
            background-color: #00912e;
            color: #fff; }
            #archive-work .work-contact .contact-menu .menu-item .button a:hover::after {
              border-top: solid 2px #fff;
              border-right: solid 2px #fff; }
          #archive-work .work-contact .contact-menu .menu-item .button a::after {
            content: '';
            width: 10px;
            height: 10px;
            border: 0px;
            border-top: solid 2px #00912e;
            border-right: solid 2px #00912e;
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 50%;
            right: 20px;
            margin-top: -4px;
            transition: all .2s ease; }
        #archive-work .work-contact .contact-menu .menu-item .tel {
          color: #00912e;
          text-align: center; }
          #archive-work .work-contact .contact-menu .menu-item .tel p {
            font-size: 3.5rem; }
          #archive-work .work-contact .contact-menu .menu-item .tel span {
            font-size: 1.4rem; }
  #archive-work .tablenav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0; }
    #archive-work .tablenav a {
      color: #000;
      margin: 0 20px;
      font-size: 1.6rem;
      text-decoration: none; }
    #archive-work .tablenav span {
      color: #000;
      margin: 0 20px;
      font-size: 1.6rem;
      text-decoration: none; }

#restoration {
  width: 100%; }
  @media screen and (min-width: 999px) {
    #restoration {
      padding: 0;
      margin: 40px auto 0px; } }
  #restoration .restoration-header {
    background-image: url(./assets/img/wokstop@2x.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    font-size: 2.5rem;
    color: #00912e;
    font-family: "Questrial", "Helvetica Neue", Helvetica, Arial, serif;
    font-weight: bold;
    letter-spacing: 0.01em;
    margin-bottom: 30px; }
    @media screen and (min-width: 999px) {
      #restoration .restoration-header {
        min-height: 300px;
        font-size: 5.1rem; } }
  #restoration .restoration-nav {
    margin-bottom: 20px; }
    @media screen and (min-width: 999px) {
      #restoration .restoration-nav {
        padding: 0 100px;
        max-width: 1000px;
        margin: 20px auto 0px;
        padding: 0 5%; } }
    #restoration .restoration-nav ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: -3%; }
      @media screen and (min-width: 999px) {
        #restoration .restoration-nav ul {
          padding: 40px 0;
          margin-left: -2%; } }
      #restoration .restoration-nav ul li {
        width: 47%;
        margin-left: 3%;
        margin-bottom: 20px; }
        @media screen and (min-width: 999px) {
          #restoration .restoration-nav ul li {
            width: 31%;
            margin-left: 2%; } }
        #restoration .restoration-nav ul li a {
          display: block;
          color: #00912e;
          font-size: 1.6rem;
          text-decoration: none;
          padding: 5px;
          text-align: center;
          width: 100%;
          border: 1px solid #00912e; }
          @media screen and (min-width: 999px) {
            #restoration .restoration-nav ul li a {
              transition: all .2s ease; }
              #restoration .restoration-nav ul li a:hover {
                background-color: #00912e;
                color: #fff; } }
  @media screen and (min-width: 999px) {
    #restoration .restoration-contents {
      max-width: 1000px;
      margin: 20px auto 0px;
      padding: 0 5%; } }
  #restoration .restoration-contents .restoration-contents__title {
    margin-bottom: 40px; }
    #restoration .restoration-contents .restoration-contents__title .title {
      background-color: #ecf2d9;
      border-left: 8px solid #a8cc06;
      font-size: 1.8rem;
      color: #595757;
      padding: 10px;
      margin-bottom: 20px; }
    #restoration .restoration-contents .restoration-contents__title .text {
      font-size: 1.4rem;
      margin-bottom: 15px; }
  #restoration .restoration-contents .restoration-contents__item img {
    max-width: 100%;
    height: auto;
    vertical-align: middle; }
  #restoration .work-contact {
    background-color: rgba(168, 204, 6, 0.08);
    padding: 40px 5%; }
    @media screen and (min-width: 999px) {
      #restoration .work-contact {
        margin: 80px auto 0;
        padding: 80px 0; } }
    #restoration .work-contact .work-contact__inner {
      width: 100%;
      max-width: 1000px;
      margin: 40px auto 0px; }
    #restoration .work-contact .title {
      text-align: center;
      width: 90px;
      margin: 0 auto 30px; }
      @media screen and (min-width: 999px) {
        #restoration .work-contact .title {
          width: 120px; } }
      #restoration .work-contact .title img {
        width: 100%; }
    #restoration .work-contact .copy {
      text-align: center;
      font-size: 2.5rem;
      color: #00912e;
      margin-bottom: 10px;
      line-height: 1; }
      @media screen and (min-width: 999px) {
        #restoration .work-contact .copy {
          font-size: 5rem;
          margin-bottom: 20px; } }
    #restoration .work-contact .text {
      text-align: center;
      font-size: 1.2rem;
      color: #00912e; }
      @media screen and (min-width: 999px) {
        #restoration .work-contact .text {
          font-size: 2.0rem; } }
    #restoration .work-contact .contact-menu {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      @media screen and (min-width: 999px) {
        #restoration .work-contact .contact-menu {
          flex-direction: row;
          align-items: flex-start; } }
      #restoration .work-contact .contact-menu .menu-item {
        margin-bottom: 30px; }
        @media screen and (min-width: 999px) {
          #restoration .work-contact .contact-menu .menu-item {
            margin-bottom: 0;
            margin: 20px 40px; } }
        #restoration .work-contact .contact-menu .menu-item dl dt {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px; }
          @media screen and (min-width: 999px) {
            #restoration .work-contact .contact-menu .menu-item dl dt {
              margin-bottom: 10px; } }
          #restoration .work-contact .contact-menu .menu-item dl dt span img {
            max-width: 30px;
            margin-right: 10px; }
          #restoration .work-contact .contact-menu .menu-item dl dt p {
            font-size: 1.5rem;
            color: #00912e; }
        #restoration .work-contact .contact-menu .menu-item .button a {
          display: block;
          max-width: 300px;
          width: 100%;
          padding: 15px 0px;
          font-size: 1.6rem;
          text-align: center;
          margin: 0 auto 30px;
          color: #00912e;
          border: 2px solid #00912e;
          text-decoration: none;
          position: relative;
          background-color: transparent;
          transition: all .2s ease; }
          #restoration .work-contact .contact-menu .menu-item .button a:hover {
            background-color: #00912e;
            color: #fff; }
            #restoration .work-contact .contact-menu .menu-item .button a:hover::after {
              border-top: solid 2px #fff;
              border-right: solid 2px #fff; }
          #restoration .work-contact .contact-menu .menu-item .button a::after {
            content: '';
            width: 10px;
            height: 10px;
            border: 0px;
            border-top: solid 2px #00912e;
            border-right: solid 2px #00912e;
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 50%;
            right: 20px;
            margin-top: -4px;
            transition: all .2s ease; }
        #restoration .work-contact .contact-menu .menu-item .tel {
          color: #00912e;
          text-align: center; }
          #restoration .work-contact .contact-menu .menu-item .tel p {
            font-size: 3.5rem; }
          #restoration .work-contact .contact-menu .menu-item .tel span {
            font-size: 1.4rem; }

/*-----------------------------------------------------------------------------------
    example100
-----------------------------------------------------------------------------------*/
#example100 {
  width: 100%;
  max-width: 1000px;
  margin: 0px auto 0px; }
  @media screen and (min-width: 999px) {
    #example100 {
      padding: 0;
      margin: 40px auto 0px; } }
  #example100 .example100-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-bottom: 30px; }
    #example100 .example100-header img {
      width: 100%;
      height: auto; }
    @media screen and (min-width: 999px) {
      #example100 .example100-header {
        margin-bottom: 30px;
        height: 400px;
        font-size: 5.1rem; } }
  #example100 .example100-contents .example100-contents__item {
    margin-bottom: 50px; }
    #example100 .example100-contents .example100-contents__item.example100-water {
      background-color: #e6f2fc; }
    #example100 .example100-contents .example100-contents__item.example100-interior {
      background-color: #f9f0f5; }
    #example100 .example100-contents .example100-contents__item.example100-house {
      background-color: #fff5de; }
    #example100 .example100-contents .example100-contents__item .image img {
      max-width: 100%; }
    #example100 .example100-contents .example100-contents__item .table {
      margin: 20px 5%;
      border: 1px solid #000; }
      @media screen and (min-width: 999px) {
        #example100 .example100-contents .example100-contents__item .table {
          margin: 50px; } }
      #example100 .example100-contents .example100-contents__item .table .item {
        display: table;
        border-top: 1px solid #231815;
        width: 100%; }
        @media screen and (min-width: 999px) {
          #example100 .example100-contents .example100-contents__item .table .item {
            font-size: 1.4rem; } }
        #example100 .example100-contents .example100-contents__item .table .item:first-child {
          border-top: 0px solid #000; }
        #example100 .example100-contents .example100-contents__item .table .item .title {
          width: 60%; }
        #example100 .example100-contents .example100-contents__item .table .item .price {
          width: 25%; }
        #example100 .example100-contents .example100-contents__item .table .item .other {
          width: 15%; }
        #example100 .example100-contents .example100-contents__item .table .item .cell {
          display: table-cell;
          padding: 8px 10px;
          box-sizing: border-box;
          font-size: 1.10em; }
          #example100 .example100-contents .example100-contents__item .table .item .cell.red {
            color: red; }
    #example100 .example100-contents .example100-contents__item .contact {
      background-color: #f7fbec;
      padding: 20px 0; }
      #example100 .example100-contents .example100-contents__item .contact .contact-menu {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        @media screen and (min-width: 999px) {
          #example100 .example100-contents .example100-contents__item .contact .contact-menu {
            flex-direction: row;
            align-items: flex-start; } }
        #example100 .example100-contents .example100-contents__item .contact .contact-menu .menu-item {
          margin-bottom: 30px; }
          @media screen and (min-width: 999px) {
            #example100 .example100-contents .example100-contents__item .contact .contact-menu .menu-item {
              margin-bottom: 0;
              margin: 20px 40px; } }
          #example100 .example100-contents .example100-contents__item .contact .contact-menu .menu-item dl dt {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px; }
            @media screen and (min-width: 999px) {
              #example100 .example100-contents .example100-contents__item .contact .contact-menu .menu-item dl dt {
                margin-bottom: 10px; } }
            #example100 .example100-contents .example100-contents__item .contact .contact-menu .menu-item dl dt span img {
              max-width: 30px;
              margin-right: 10px; }
            #example100 .example100-contents .example100-contents__item .contact .contact-menu .menu-item dl dt p {
              font-size: 1.5rem;
              color: #00912e; }
          #example100 .example100-contents .example100-contents__item .contact .contact-menu .menu-item .button a {
            display: block;
            max-width: 300px;
            width: 100%;
            padding: 15px 0px;
            font-size: 1.6rem;
            text-align: center;
            margin: 0 auto 30px;
            color: #00912e;
            border: 2px solid #00912e;
            text-decoration: none;
            position: relative;
            background-color: transparent;
            transition: all .2s ease; }
            #example100 .example100-contents .example100-contents__item .contact .contact-menu .menu-item .button a:hover {
              background-color: #00912e;
              color: #fff; }
              #example100 .example100-contents .example100-contents__item .contact .contact-menu .menu-item .button a:hover::after {
                border-top: solid 2px #fff;
                border-right: solid 2px #fff; }
            #example100 .example100-contents .example100-contents__item .contact .contact-menu .menu-item .button a::after {
              content: '';
              width: 10px;
              height: 10px;
              border: 0px;
              border-top: solid 2px #00912e;
              border-right: solid 2px #00912e;
              -ms-transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              position: absolute;
              top: 50%;
              right: 20px;
              margin-top: -4px;
              transition: all .2s ease; }
          #example100 .example100-contents .example100-contents__item .contact .contact-menu .menu-item .tel {
            color: #00912e;
            text-align: center; }
            #example100 .example100-contents .example100-contents__item .contact .contact-menu .menu-item .tel p {
              font-size: 3.5rem; }
            #example100 .example100-contents .example100-contents__item .contact .contact-menu .menu-item .tel span {
              font-size: 1.4rem; }

/*-----------------------------------------------------------------------------------
    letter
-----------------------------------------------------------------------------------*/
#letter {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto 0px;
  padding: 0 5%; }
  @media screen and (min-width: 999px) {
    #letter {
      padding: 0;
      margin: 40px auto 0px; } }
  #letter .letter-header {
    background-image: url(./assets/img/kazokutoop@2x.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    margin-bottom: 30px; }
    #letter .letter-header img {
      max-width: 100%;
      width: 40%;
      height: auto; }
    @media screen and (min-width: 999px) {
      #letter .letter-header {
        height: 400px;
        font-size: 5.1rem; } }
  #letter .letter-caption {
    margin: 20px 0; }
    @media screen and (min-width: 999px) {
      #letter .letter-caption {
        margin: 40px 0;
        font-size: 1.6rem; } }
    #letter .letter-caption .copy {
      text-align: center;
      font-size: 1.2rem;
      color: #595757;
      margin-bottom: 30px; }
      @media screen and (min-width: 999px) {
        #letter .letter-caption .copy {
          font-size: 1.6rem; } }
  #letter .letter-contents .letter-contents__pickup {
    margin-bottom: 30px; }
    #letter .letter-contents .letter-contents__pickup .entry {
      position: relative;
      margin-bottom: 30px; }
      #letter .letter-contents .letter-contents__pickup .entry h3 {
        background-color: #ecf2d9;
        border-left: 8px solid #a8cc06;
        font-size: 1.8rem;
        color: #595757;
        padding: 10px;
        margin-bottom: 20px; }
      #letter .letter-contents .letter-contents__pickup .entry .download {
        position: absolute;
        right: 5px;
        top: 60px; }
        #letter .letter-contents .letter-contents__pickup .entry .download a {
          display: block; }
          #letter .letter-contents .letter-contents__pickup .entry .download a img {
            width: 50px;
            height: auto; }
      #letter .letter-contents .letter-contents__pickup .entry .image ul {
        display: flex;
        flex-direction: column; }
        #letter .letter-contents .letter-contents__pickup .entry .image ul li img {
          width: 100%; }
  #letter .letter-contents .letter-contents__archives .title {
    background-color: #ecf2d9;
    border-left: 8px solid #a8cc06;
    font-size: 1.8rem;
    color: #595757;
    padding: 10px;
    margin-bottom: 20px; }
  #letter .letter-contents .letter-contents__archives .list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -2%; }
    #letter .letter-contents .letter-contents__archives .list .entry {
      width: 48%;
      margin-left: 2%;
      margin-bottom: 20px;
      position: relative; }
      @media screen and (min-width: 999px) {
        #letter .letter-contents .letter-contents__archives .list .entry {
          width: 14.4%; } }
      #letter .letter-contents .letter-contents__archives .list .entry .download {
        position: absolute;
        right: -5px;
        top: -5px; }
        #letter .letter-contents .letter-contents__archives .list .entry .download a {
          display: block; }
          #letter .letter-contents .letter-contents__archives .list .entry .download a img {
            width: 50px; }
      #letter .letter-contents .letter-contents__archives .list .entry .image img {
        width: 100%;
        height: auto; }
      #letter .letter-contents .letter-contents__archives .list .entry h3 {
        text-align: center;
        font-size: 1.2rem; }
  #letter .letter-backnumber {
    text-align: center;
    padding: 40px 0; }
    #letter .letter-backnumber p {
      font-size: 1.4rem; }
      #letter .letter-backnumber p a {
        color: #00912e;
        text-decoration: underline; }

/*-----------------------------------------------------------------------------------
    monthly
-----------------------------------------------------------------------------------*/
#monthly .monthly-header {
  background-image: url(./assets/img/genbatop@2x.png);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  margin-bottom: 30px; }
  #monthly .monthly-header img {
    max-width: 100%;
    width: 30%;
    max-width: 400px;
    height: auto; }
  @media screen and (min-width: 999px) {
    #monthly .monthly-header {
      height: 400px;
      font-size: 5.1rem; } }

#monthly .monthly-caption {
  margin: 20px 0; }
  @media screen and (min-width: 999px) {
    #monthly .monthly-caption {
      margin: 40px 0;
      font-size: 1.6rem; } }
  #monthly .monthly-caption .copy {
    text-align: center;
    font-size: 1.2rem;
    color: #595757;
    margin-bottom: 30px; }
    @media screen and (min-width: 999px) {
      #monthly .monthly-caption .copy {
        font-size: 1.6rem; } }

#monthly .monthly-contents {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto 0px;
  padding: 0 5%; }
  @media screen and (min-width: 999px) {
    #monthly .monthly-contents {
      padding: 0;
      margin: 40px auto 0px; } }
  #monthly .monthly-contents .monthly-contents__pickup {
    margin-bottom: 30px; }
    #monthly .monthly-contents .monthly-contents__pickup .entry {
      position: relative;
      margin-bottom: 30px; }
      #monthly .monthly-contents .monthly-contents__pickup .entry h3 {
        background-color: #ecf2d9;
        border-left: 8px solid #a8cc06;
        font-size: 1.8rem;
        color: #595757;
        padding: 10px;
        margin-bottom: 20px; }
      #monthly .monthly-contents .monthly-contents__pickup .entry .download {
        position: absolute;
        right: 5px;
        top: 60px; }
        #monthly .monthly-contents .monthly-contents__pickup .entry .download a {
          display: block; }
          #monthly .monthly-contents .monthly-contents__pickup .entry .download a img {
            width: 50px;
            height: auto; }
      #monthly .monthly-contents .monthly-contents__pickup .entry .image ul {
        display: flex;
        flex-direction: column; }
        #monthly .monthly-contents .monthly-contents__pickup .entry .image ul li img {
          width: 100%; }
  #monthly .monthly-contents .monthly-contents__archives .title {
    background-color: #ecf2d9;
    border-left: 8px solid #a8cc06;
    font-size: 1.8rem;
    color: #595757;
    padding: 10px;
    margin-bottom: 20px; }
  #monthly .monthly-contents .monthly-contents__archives .list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -2%; }
    #monthly .monthly-contents .monthly-contents__archives .list .entry {
      width: 48%;
      margin-left: 2%;
      margin-bottom: 20px;
      position: relative; }
      @media screen and (min-width: 999px) {
        #monthly .monthly-contents .monthly-contents__archives .list .entry {
          width: 14.4%; } }
      #monthly .monthly-contents .monthly-contents__archives .list .entry .download {
        position: absolute;
        right: -5px;
        top: -5px; }
        #monthly .monthly-contents .monthly-contents__archives .list .entry .download a {
          display: block; }
          #monthly .monthly-contents .monthly-contents__archives .list .entry .download a img {
            width: 50px; }
      #monthly .monthly-contents .monthly-contents__archives .list .entry .image img {
        width: 100%;
        height: auto; }
      #monthly .monthly-contents .monthly-contents__archives .list .entry h3 {
        text-align: center;
        font-size: 1.2rem; }

#monthly .monthly-backnumber {
  text-align: center;
  padding: 40px 0; }
  #monthly .monthly-backnumber p {
    font-size: 1.4rem; }
    #monthly .monthly-backnumber p a {
      color: #00912e;
      text-decoration: underline; }

/*-----------------------------------------------------------------------------------
    mpoint
-----------------------------------------------------------------------------------*/
#mpoint {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto 0px;
  padding: 0 5%; }
  @media screen and (min-width: 999px) {
    #mpoint {
      padding: 0;
      margin: 40px auto 0px; } }
  #mpoint .mpoint-header {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    margin-bottom: 30px; }
    #mpoint .mpoint-header img {
      width: 100%;
      height: auto; }
    @media screen and (min-width: 999px) {
      #mpoint .mpoint-header {
        min-height: 400px;
        font-size: 5.1rem; } }
  #mpoint .mpoint-contents {
    margin: 40px 0; }
    #mpoint .mpoint-contents img {
      max-width: 100%;
      height: auto; }
    #mpoint .mpoint-contents .mpoint-contents__menu {
      margin: 40px 0; }
      #mpoint .mpoint-contents .mpoint-contents__menu .list {
        display: flex;
        flex-direction: column; }
        @media screen and (min-width: 999px) {
          #mpoint .mpoint-contents .mpoint-contents__menu .list {
            flex-direction: row;
            justify-content: space-between;
            max-width: 940px;
            margin: 0 auto; } }
        #mpoint .mpoint-contents .mpoint-contents__menu .list li {
          margin-bottom: 30px; }
          @media screen and (min-width: 999px) {
            #mpoint .mpoint-contents .mpoint-contents__menu .list li {
              width: 450px; } }
        #mpoint .mpoint-contents .mpoint-contents__menu .list dl dt {
          font-size: 1.2rem;
          margin-bottom: 10px; }
          @media screen and (min-width: 999px) {
            #mpoint .mpoint-contents .mpoint-contents__menu .list dl dt {
              font-size: 1.4rem; } }
        #mpoint .mpoint-contents .mpoint-contents__menu .list dl dd a {
          display: block; }
          @media screen and (min-width: 999px) {
            #mpoint .mpoint-contents .mpoint-contents__menu .list dl dd a {
              opacity: 1;
              transition: all .2s ease; }
              #mpoint .mpoint-contents .mpoint-contents__menu .list dl dd a:hover {
                opacity: .7; } }
    #mpoint .mpoint-contents .mpoint-contents__more {
      margin-bottom: 100px; }
      #mpoint .mpoint-contents .mpoint-contents__more .text {
        font-size: 1.4rem;
        text-align: center;
        margin-bottom: 20px; }
      #mpoint .mpoint-contents .mpoint-contents__more .button a {
        display: block;
        max-width: 340px;
        width: 100%;
        padding: 15px 0px;
        font-size: 1.6rem;
        text-align: center;
        margin: 0 auto 30px;
        color: #00912e;
        border: 2px solid #00912e;
        text-decoration: none;
        position: relative;
        background-color: transparent;
        transition: all .2s ease; }
        #mpoint .mpoint-contents .mpoint-contents__more .button a:hover {
          background-color: #00912e;
          color: #fff; }
          #mpoint .mpoint-contents .mpoint-contents__more .button a:hover::after {
            border-top: solid 2px #fff;
            border-right: solid 2px #fff; }
        #mpoint .mpoint-contents .mpoint-contents__more .button a::after {
          content: '';
          width: 10px;
          height: 10px;
          border: 0px;
          border-top: solid 2px #00912e;
          border-right: solid 2px #00912e;
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 50%;
          right: 20px;
          margin-top: -4px;
          transition: all .2s ease; }

/*-----------------------------------------------------------------------------------
    mpint-form
-----------------------------------------------------------------------------------*/
#mpint-form {
  width: 100%; }
  @media screen and (min-width: 999px) {
    #mpint-form {
      padding: 0;
      margin: 40px auto 0px; } }
  #mpint-form .mpint-form-header {
    background-color: rgba(168, 204, 6, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    font-size: 3.0rem;
    color: #00912e;
    font-weight: bold;
    letter-spacing: 0.01em;
    text-align: center; }
    @media screen and (min-width: 999px) {
      #mpint-form .mpint-form-header {
        min-height: 300px;
        font-size: 5.1rem; }
        #mpint-form .mpint-form-header br {
          display: none; } }
    #mpint-form .mpint-form-header .caption {
      text-align: center;
      font-size: 1.2rem;
      padding: 20px 0 0;
      color: #000; }
      @media screen and (min-width: 999px) {
        #mpint-form .mpint-form-header .caption {
          padding: 40px 0 0;
          font-size: 1.4rem; }
          #mpint-form .mpint-form-header .caption br {
            display: none; } }
  #mpint-form .mpint-form-contents {
    background-color: rgba(168, 204, 6, 0.2);
    padding: 40px 5%; }
    @media screen and (min-width: 999px) {
      #mpint-form .mpint-form-contents .inner {
        max-width: 1000px;
        margin: 20px auto 0px; } }
    #mpint-form .mpint-form-contents .prv {
      height: 200px;
      overflow: auto;
      background-color: #fff;
      padding: 20px; }
  #mpint-form .form-group {
    color: #000; }
    #mpint-form .form-group dl {
      padding-right: 20px;
      display: block;
      padding-right: 0;
      font-size: 1.4rem;
      margin-bottom: 20px; }
      @media screen and (min-width: 999px) {
        #mpint-form .form-group dl {
          display: flex;
          padding-bottom: 20px;
          border-bottom: 1px dotted #eee;
          margin-bottom: 20px;
          justify-content: center; } }
      #mpint-form .form-group dl dt {
        text-align: left;
        width: auto;
        margin-right: 0;
        margin-bottom: 10px; }
        @media screen and (min-width: 999px) {
          #mpint-form .form-group dl dt {
            text-align: right;
            width: 200px;
            margin-right: 40px; } }
        #mpint-form .form-group dl dt .clr_00 {
          color: #e32877; }
        #mpint-form .form-group dl dt span {
          padding: 2px 5px;
          color: #fff;
          background: #00912e;
          margin-left: 10px;
          font-weight: bold; }
      #mpint-form .form-group dl dd {
        width: auto;
        border: 0; }
        @media screen and (min-width: 999px) {
          #mpint-form .form-group dl dd {
            width: calc(100% - 500px); } }
        #mpint-form .form-group dl dd input {
          width: 100%;
          background-color: #fff;
          border: 0;
          padding: 10px; }
          #mpint-form .form-group dl dd input[type="radio"] {
            width: auto !important; }
          @media screen and (min-width: 999px) {
            #mpint-form .form-group dl dd input {
              max-width: 500px;
              padding: 5px;
              border-radius: 5px; } }
        #mpint-form .form-group dl dd span.cap {
          font-size: 12px;
          display: block;
          margin-top: 10px; }
        #mpint-form .form-group dl dd textarea {
          padding: 5px;
          border-radius: 5px;
          background-color: #fff;
          border: 0;
          width: 100%;
          padding: 10px; }
          @media screen and (min-width: 999px) {
            #mpint-form .form-group dl dd textarea {
              padding: 5px;
              max-width: 500px; } }
      #mpint-form .form-group dl .form-teltrueform {
        display: flex;
        align-items: center; }
        #mpint-form .form-group dl .form-teltrueform dl {
          padding-bottom: 0;
          border-bottom: 0px;
          margin-bottom: 0px;
          padding-right: 0;
          margin-right: 20px;
          display: flex;
          align-items: center; }
          #mpint-form .form-group dl .form-teltrueform dl dt {
            width: auto;
            margin-right: 10px; }
          #mpint-form .form-group dl .form-teltrueform dl dd {
            width: auto; }
          #mpint-form .form-group dl .form-teltrueform dl select {
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #eee;
            min-width: 50px;
            text-align: center; }
            #mpint-form .form-group dl .form-teltrueform dl select option {
              text-align: center; }
      #mpint-form .form-group dl .form-dateform dl {
        padding-bottom: 0;
        border-bottom: 0px;
        margin-bottom: 15px;
        padding-right: 0;
        margin-right: 20px;
        display: block; }
        @media screen and (min-width: 999px) {
          #mpint-form .form-group dl .form-dateform dl {
            display: flex;
            align-items: center; } }
        #mpint-form .form-group dl .form-dateform dl:last-child {
          margin-bottom: 0; }
        #mpint-form .form-group dl .form-dateform dl dt {
          width: auto;
          margin-right: 10px;
          width: 70px; }
        #mpint-form .form-group dl .form-dateform dl dd {
          width: 100%; }
          @media screen and (min-width: 999px) {
            #mpint-form .form-group dl .form-dateform dl dd {
              width: calc(100% - 80px);
              font-size: 14px; } }
        #mpint-form .form-group dl .form-dateform dl .hasDatepicker {
          width: 120px; }
          @media screen and (min-width: 999px) {
            #mpint-form .form-group dl .form-dateform dl .hasDatepicker {
              width: 150px; } }
        #mpint-form .form-group dl .form-dateform dl select {
          padding: 5px;
          border-radius: 5px;
          border: 1px solid #eee;
          min-width: 50px;
          text-align: center;
          margin-right: 10px; }
          #mpint-form .form-group dl .form-dateform dl select option {
            text-align: center; }
      #mpint-form .form-group dl .mwform-radio-field {
        display: block;
        margin: 0px 0px 10px !important; }
    #mpint-form .form-group.form-cap p {
      text-align: center;
      line-height: 1.8; }
    #mpint-form .form-group.form-cap .form-check {
      text-align: center;
      margin: 20px 0; }
      #mpint-form .form-group.form-cap .form-check span {
        font-weight: bold !important;
        margin-left: 10px; }
  #mpint-form .contact-form__submit {
    margin: 20px auto 40px;
    text-align: center; }
    #mpint-form .contact-form__submit input {
      max-width: 200px;
      background-color: transparent;
      text-align: center;
      cursor: pointer;
      padding: 20px;
      margin: 10px 20px;
      color: #00912e;
      transition: all 0.2s ease;
      letter-spacing: .2em;
      font-weight: bold;
      border-radius: 5px;
      width: 80%;
      border: 1px solid #00912e;
      font-size: 1.5rem; }
      @media screen and (min-width: 999px) {
        #mpint-form .contact-form__submit input {
          width: 100%;
          margin: 0px 20px;
          display: inline-block;
          padding: 20px 30px;
          font-size: 1.8rem; } }
      #mpint-form .contact-form__submit input:hover {
        background-color: #00912e;
        color: #fff; }
  #mpint-form .mw_wp_form_preview .form-cap {
    display: none; }
  #mpint-form .mw_wp_form_complete {
    margin: 0px 20px;
    margin-top: -40px;
    padding: 40px 20px; }
    @media screen and (min-width: 999px) {
      #mpint-form .mw_wp_form_complete {
        padding: 20px;
        margin-top: -20px;
        margin-bottom: 20px; } }
    #mpint-form .mw_wp_form_complete .thanks-heading {
      text-align: center;
      margin-bottom: 20px;
      font-size: 14px; }
      @media screen and (min-width: 999px) {
        #mpint-form .mw_wp_form_complete .thanks-heading {
          font-size: 20px; } }
    #mpint-form .mw_wp_form_complete .thanks-txt {
      margin-bottom: 40px; }
    #mpint-form .mw_wp_form_complete .thanks-back {
      width: 200px;
      background-color: #00912e;
      margin: 0 auto;
      transition: all 0.2s ease; }
      #mpint-form .mw_wp_form_complete .thanks-back:hover {
        background-color: scheme(2); }
      #mpint-form .mw_wp_form_complete .thanks-back a {
        display: block;
        text-align: center;
        padding: 20px;
        color: #fff;
        letter-spacing: 0.1em; }

/*-----------------------------------------------------------------------------------
    contact
-----------------------------------------------------------------------------------*/
#contact {
  width: 100%; }
  @media screen and (min-width: 999px) {
    #contact {
      padding: 0;
      margin: 40px auto 0px; } }
  #contact .contact-header {
    background-image: url(./assets/img/contacttop@2x.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    font-size: 2.5rem;
    color: #00912e;
    font-family: "Questrial", "Helvetica Neue", Helvetica, Arial, serif;
    font-weight: bold;
    letter-spacing: 0.01em; }
    @media screen and (min-width: 999px) {
      #contact .contact-header {
        height: 300px;
        font-size: 5.1rem; } }
  #contact .contact-contents {
    background-color: rgba(168, 204, 6, 0.2);
    padding: 40px 5%; }
    @media screen and (min-width: 999px) {
      #contact .contact-contents .inner {
        max-width: 1000px;
        margin: 20px auto 0px; } }
    #contact .contact-contents .prv {
      height: 200px;
      overflow: auto;
      background-color: #fff;
      padding: 20px; }
  #contact .form-group {
    color: #000; }
    #contact .form-group dl {
      padding-right: 20px;
      display: block;
      padding-right: 0;
      font-size: 1.4rem;
      margin-bottom: 20px; }
      @media screen and (min-width: 999px) {
        #contact .form-group dl {
          display: flex;
          padding-bottom: 20px;
          border-bottom: 1px dotted #eee;
          margin-bottom: 20px;
          justify-content: center; } }
      #contact .form-group dl dt {
        text-align: left;
        width: auto;
        margin-right: 0;
        margin-bottom: 10px; }
        @media screen and (min-width: 999px) {
          #contact .form-group dl dt {
            text-align: right;
            width: 200px;
            margin-right: 40px; } }
        #contact .form-group dl dt .clr_00 {
          color: #e32877; }
        #contact .form-group dl dt span {
          padding: 2px 5px;
          color: #fff;
          background: #00912e;
          margin-left: 10px;
          font-weight: bold; }
      #contact .form-group dl dd {
        width: auto;
        border: 0; }
        @media screen and (min-width: 999px) {
          #contact .form-group dl dd {
            width: calc(100% - 500px); } }
        #contact .form-group dl dd input {
          width: 100%;
          background-color: #fff;
          border: 0;
          padding: 10px; }
          #contact .form-group dl dd input[type="radio"] {
            width: auto !important; }
          @media screen and (min-width: 999px) {
            #contact .form-group dl dd input {
              max-width: 500px;
              padding: 5px;
              border-radius: 5px; } }
        #contact .form-group dl dd span.cap {
          font-size: 12px;
          display: block;
          margin-top: 10px; }
        #contact .form-group dl dd textarea {
          padding: 5px;
          border-radius: 5px;
          background-color: #fff;
          border: 0;
          width: 100%;
          padding: 10px; }
          @media screen and (min-width: 999px) {
            #contact .form-group dl dd textarea {
              padding: 5px;
              max-width: 500px; } }
      #contact .form-group dl .form-teltrueform {
        display: flex;
        align-items: center; }
        #contact .form-group dl .form-teltrueform dl {
          padding-bottom: 0;
          border-bottom: 0px;
          margin-bottom: 0px;
          padding-right: 0;
          margin-right: 20px;
          display: flex;
          align-items: center; }
          #contact .form-group dl .form-teltrueform dl dt {
            width: auto;
            margin-right: 10px; }
          #contact .form-group dl .form-teltrueform dl dd {
            width: auto; }
          #contact .form-group dl .form-teltrueform dl select {
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #eee;
            min-width: 50px;
            text-align: center; }
            #contact .form-group dl .form-teltrueform dl select option {
              text-align: center; }
      #contact .form-group dl .form-dateform dl {
        padding-bottom: 0;
        border-bottom: 0px;
        margin-bottom: 15px;
        padding-right: 0;
        margin-right: 20px;
        display: block; }
        @media screen and (min-width: 999px) {
          #contact .form-group dl .form-dateform dl {
            display: flex;
            align-items: center; } }
        #contact .form-group dl .form-dateform dl:last-child {
          margin-bottom: 0; }
        #contact .form-group dl .form-dateform dl dt {
          width: auto;
          margin-right: 10px;
          width: 70px; }
        #contact .form-group dl .form-dateform dl dd {
          width: 100%; }
          @media screen and (min-width: 999px) {
            #contact .form-group dl .form-dateform dl dd {
              width: calc(100% - 80px);
              font-size: 14px; } }
        #contact .form-group dl .form-dateform dl .hasDatepicker {
          width: 120px; }
          @media screen and (min-width: 999px) {
            #contact .form-group dl .form-dateform dl .hasDatepicker {
              width: 150px; } }
        #contact .form-group dl .form-dateform dl select {
          padding: 5px;
          border-radius: 5px;
          border: 1px solid #eee;
          min-width: 50px;
          text-align: center;
          margin-right: 10px; }
          #contact .form-group dl .form-dateform dl select option {
            text-align: center; }
      #contact .form-group dl .mwform-radio-field {
        display: block;
        margin: 0px 0px 10px !important; }
    #contact .form-group.form-cap p {
      text-align: center;
      line-height: 1.8; }
    #contact .form-group.form-cap .form-check {
      text-align: center;
      margin: 20px 0; }
      #contact .form-group.form-cap .form-check span {
        font-weight: bold !important;
        margin-left: 10px; }
  #contact .contact-form__submit {
    margin: 20px auto 40px;
    text-align: center; }
    #contact .contact-form__submit input {
      max-width: 200px;
      background-color: transparent;
      text-align: center;
      cursor: pointer;
      padding: 20px;
      margin: 10px 20px;
      color: #00912e;
      transition: all 0.2s ease;
      letter-spacing: .2em;
      font-weight: bold;
      border-radius: 5px;
      width: 80%;
      border: 1px solid #00912e;
      font-size: 1.5rem; }
      @media screen and (min-width: 999px) {
        #contact .contact-form__submit input {
          width: 100%;
          margin: 0px 20px;
          display: inline-block;
          padding: 20px 30px;
          font-size: 1.8rem; } }
      #contact .contact-form__submit input:hover {
        background-color: #00912e;
        color: #fff; }
  #contact .mw_wp_form_preview .form-cap {
    display: none; }
  #contact .mw_wp_form_complete {
    margin: 0px 20px;
    margin-top: -40px;
    padding: 40px 20px; }
    @media screen and (min-width: 999px) {
      #contact .mw_wp_form_complete {
        padding: 20px;
        margin-top: -20px;
        margin-bottom: 20px; } }
    #contact .mw_wp_form_complete .thanks-heading {
      text-align: center;
      margin-bottom: 20px;
      font-size: 14px; }
      @media screen and (min-width: 999px) {
        #contact .mw_wp_form_complete .thanks-heading {
          font-size: 20px; } }
    #contact .mw_wp_form_complete .thanks-txt {
      margin-bottom: 40px; }
    #contact .mw_wp_form_complete .thanks-back {
      width: 200px;
      background-color: #00912e;
      margin: 0 auto;
      transition: all 0.2s ease; }
      #contact .mw_wp_form_complete .thanks-back:hover {
        background-color: scheme(2); }
      #contact .mw_wp_form_complete .thanks-back a {
        display: block;
        text-align: center;
        padding: 20px;
        color: #fff;
        letter-spacing: 0.1em; }

/*-----------------------------------------------------------------------------------
    staff
-----------------------------------------------------------------------------------*/
#staff {
  width: 100%;
  max-width: 1000px;
  margin: 0px auto 0px; }
  @media screen and (min-width: 999px) {
    #staff {
      padding: 0;
      margin: 40px auto 0px; } }
  #staff .staff-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; }
    #staff .staff-header img {
      width: 100%;
      height: auto; }
    @media screen and (min-width: 999px) {
      #staff .staff-header {
        font-size: 5.1rem; } }
  #staff .staff-contents {
    background-color: #ded7c5;
    padding: 30px 5%; }
    #staff .staff-contents .staff-contents__eyecatch img {
      max-width: 100%; }
    #staff .staff-contents .staff-contents__section {
      display: flex;
      flex-direction: column;
      padding: 40px 0; }
      @media screen and (min-width: 999px) {
        #staff .staff-contents .staff-contents__section {
          flex-direction: row;
          flex-wrap: wrap;
          margin-left: -2%;
          align-items: stretch; } }
      #staff .staff-contents .staff-contents__section .section-item {
        background-color: #fff;
        border-radius: 20px;
        padding: 0 20px 20px;
        margin-bottom: 30px; }
        @media screen and (min-width: 999px) {
          #staff .staff-contents .staff-contents__section .section-item {
            width: 48%;
            margin-left: 2%;
            margin-bottom: 0;
            margin-top: 40px; } }
        #staff .staff-contents .staff-contents__section .section-item:last-child {
          margin-bottom: -10px; }
          @media screen and (min-width: 999px) {
            #staff .staff-contents .staff-contents__section .section-item:last-child {
              margin-bottom: 0; } }
        #staff .staff-contents .staff-contents__section .section-item .section-item__header {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          border-bottom: 1px solid #00912e;
          margin-top: -5vw; }
          @media screen and (min-width: 999px) {
            #staff .staff-contents .staff-contents__section .section-item .section-item__header {
              margin-top: -70px; } }
          #staff .staff-contents .staff-contents__section .section-item .section-item__header .profile {
            width: 55%;
            padding: 3vw 0px 0px 10px; }
            @media screen and (min-width: 999px) {
              #staff .staff-contents .staff-contents__section .section-item .section-item__header .profile {
                padding: 10px 0 0 10px; } }
            #staff .staff-contents .staff-contents__section .section-item .section-item__header .profile .role {
              font-size: 1.2rem; }
            #staff .staff-contents .staff-contents__section .section-item .section-item__header .profile .name {
              font-size: 2.6rem; }
              @media screen and (min-width: 999px) {
                #staff .staff-contents .staff-contents__section .section-item .section-item__header .profile .name {
                  font-size: 3.0rem; } }
            #staff .staff-contents .staff-contents__section .section-item .section-item__header .profile .kana {
              color: #00912e;
              font-family: "Questrial", "Helvetica Neue", Helvetica, Arial, serif;
              font-size: 1.3rem; }
          #staff .staff-contents .staff-contents__section .section-item .section-item__header .image {
            width: 45%; }
            #staff .staff-contents .staff-contents__section .section-item .section-item__header .image img {
              max-width: 100%;
              height: auto;
              vertical-align: middle; }
        #staff .staff-contents .staff-contents__section .section-item .section-item__qualifications {
          padding: 10px 0;
          font-size: 1.2rem;
          border-bottom: 1px solid #00912e; }
          @media screen and (min-width: 999px) {
            #staff .staff-contents .staff-contents__section .section-item .section-item__qualifications {
              font-size: 1.4rem; } }
        #staff .staff-contents .staff-contents__section .section-item .section-item__profile {
          padding: 10px 0;
          font-size: 1.2rem; }
          @media screen and (min-width: 999px) {
            #staff .staff-contents .staff-contents__section .section-item .section-item__profile {
              font-size: 1.4rem; } }

/*-----------------------------------------------------------------------------------
    recruit
-----------------------------------------------------------------------------------*/
#recruit .recruit-header {
  background-color: #e6dcd2; }
  @media screen and (min-width: 999px) {
    #recruit .recruit-header .show-sp {
      display: none; } }
  #recruit .recruit-header .show-sp .recruit-header__inner {
    background-image: url(./assets/img/recruit_sp@2x.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    min-height: 140vw;
    padding: 40px 5%;
    position: relative; }
  #recruit .recruit-header .show-sp .title {
    text-align: center;
    font-size: 2.5rem;
    font-family: "Questrial", "Helvetica Neue", Helvetica, Arial, serif;
    color: #00912e;
    font-weight: bold; }
  #recruit .recruit-header .show-sp .copy {
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    color: #00912e;
    font-size: 2.2rem;
    position: absolute;
    font-weight: bold; }
  #recruit .recruit-header .show-sp .recruit-header__caption {
    background-color: #fff;
    padding: 20px 5%; }
    #recruit .recruit-header .show-sp .recruit-header__caption .text {
      font-size: 1.2rem;
      margin-bottom: 20px; }
    #recruit .recruit-header .show-sp .recruit-header__caption .image img {
      max-width: 100%;
      height: auto; }
  #recruit .recruit-header .show-pc {
    display: none;
    width: 100%;
    max-width: 1100px;
    margin: 0px auto 0px;
    background-image: url(./assets/img/recruit_back_pc@2x.png);
    background-size: cover;
    min-height: 1500px;
    position: relative; }
    @media screen and (min-width: 999px) {
      #recruit .recruit-header .show-pc {
        display: inherit; } }
    #recruit .recruit-header .show-pc .title {
      text-align: center;
      color: #00912e;
      font-size: 5.0rem;
      font-family: "Questrial", "Helvetica Neue", Helvetica, Arial, serif;
      font-weight: bold;
      padding-top: 50px; }
    #recruit .recruit-header .show-pc .caption {
      position: absolute;
      top: 700px;
      left: 0;
      right: 0;
      margin: auto;
      width: 650px; }
      #recruit .recruit-header .show-pc .caption .copy {
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
        color: #00912e;
        margin-bottom: 30px; }
      #recruit .recruit-header .show-pc .caption .text {
        font-size: 1.6rem; }
    #recruit .recruit-header .show-pc .image {
      position: absolute;
      bottom: 30px;
      left: -10%;
      right: 0;
      margin: auto; }
      #recruit .recruit-header .show-pc .image img {
        max-width: 130%; }
    #recruit .recruit-header .show-pc .image2 {
      position: absolute;
      bottom: 800px;
      left: -5%;
      margin: auto;
      width: 330px; }
      #recruit .recruit-header .show-pc .image2 img {
        max-width: 130%; }
    #recruit .recruit-header .show-pc .image3 {
      position: absolute;
      top: 50px;
      right: 100px;
      margin: auto;
      width: 220px; }
      #recruit .recruit-header .show-pc .image3 img {
        max-width: 130%; }

#recruit .recruit-contents {
  background-color: rgba(168, 204, 6, 0.2);
  padding: 20px 5%; }
  #recruit .recruit-contents .title {
    text-align: center;
    color: #00912e;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px; }
    @media screen and (min-width: 999px) {
      #recruit .recruit-contents .title {
        font-size: 4.0rem;
        padding-top: 40px; }
        #recruit .recruit-contents .title br {
          display: none; } }
  #recruit .recruit-contents .figure {
    width: 100%;
    max-width: 1000px;
    margin: 0px auto 0px; }
    @media screen and (min-width: 999px) {
      #recruit .recruit-contents .figure {
        padding: 20px 40px 40px; } }
    #recruit .recruit-contents .figure dl {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between; }
      #recruit .recruit-contents .figure dl dt {
        padding: 10px;
        border: 1px solid #fff;
        background-color: #00912e;
        width: 100px;
        text-align: right;
        font-size: 1.2rem;
        color: #fff;
        font-weight: bold; }
        @media screen and (min-width: 999px) {
          #recruit .recruit-contents .figure dl dt {
            font-size: 1.6rem;
            width: 200px; } }
      #recruit .recruit-contents .figure dl dd {
        width: calc(100% - 105px);
        border: 1px solid #fff;
        background-color: #fff;
        padding: 10px;
        font-size: 1.2rem; }
        @media screen and (min-width: 999px) {
          #recruit .recruit-contents .figure dl dd {
            width: calc(100% - 205px);
            font-size: 1.6rem; } }

#recruit .index-contact {
  background-color: rgba(168, 204, 6, 0.08);
  padding: 40px 5%; }
  @media screen and (min-width: 999px) {
    #recruit .index-contact {
      margin: 0px auto 0;
      padding: 80px 0; } }
  #recruit .index-contact .index-contact__inner {
    width: 100%;
    max-width: 1000px;
    margin: 0px auto 0px; }
  #recruit .index-contact .title {
    text-align: center;
    width: 90px;
    margin: 0 auto 30px; }
    @media screen and (min-width: 999px) {
      #recruit .index-contact .title {
        width: 120px; } }
    #recruit .index-contact .title img {
      width: 100%; }
  #recruit .index-contact .copy {
    text-align: center;
    font-size: 2.5rem;
    color: #00912e;
    margin-bottom: 10px;
    line-height: 1; }
    @media screen and (min-width: 999px) {
      #recruit .index-contact .copy {
        font-size: 5rem;
        margin-bottom: 20px; } }
  #recruit .index-contact .text {
    text-align: center;
    font-size: 1.2rem;
    color: #00912e; }
    @media screen and (min-width: 999px) {
      #recruit .index-contact .text {
        font-size: 2.0rem; } }
  #recruit .index-contact .contact-menu {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 999px) {
      #recruit .index-contact .contact-menu {
        flex-direction: row;
        align-items: flex-start; } }
    #recruit .index-contact .contact-menu .menu-item {
      margin-bottom: 30px; }
      @media screen and (min-width: 999px) {
        #recruit .index-contact .contact-menu .menu-item {
          margin-bottom: 0;
          margin: 20px 40px; } }
      #recruit .index-contact .contact-menu .menu-item dl dt {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px; }
        @media screen and (min-width: 999px) {
          #recruit .index-contact .contact-menu .menu-item dl dt {
            margin-bottom: 10px; } }
        #recruit .index-contact .contact-menu .menu-item dl dt span img {
          max-width: 30px;
          margin-right: 10px; }
        #recruit .index-contact .contact-menu .menu-item dl dt p {
          font-size: 1.5rem;
          color: #00912e; }
      #recruit .index-contact .contact-menu .menu-item .button a {
        display: block;
        max-width: 300px;
        width: 100%;
        padding: 15px 0px;
        font-size: 1.6rem;
        text-align: center;
        margin: 0 auto 30px;
        color: #00912e;
        border: 2px solid #00912e;
        text-decoration: none;
        position: relative;
        background-color: transparent;
        transition: all .2s ease; }
        #recruit .index-contact .contact-menu .menu-item .button a:hover {
          background-color: #00912e;
          color: #fff; }
          #recruit .index-contact .contact-menu .menu-item .button a:hover::after {
            border-top: solid 2px #fff;
            border-right: solid 2px #fff; }
        #recruit .index-contact .contact-menu .menu-item .button a::after {
          content: '';
          width: 10px;
          height: 10px;
          border: 0px;
          border-top: solid 2px #00912e;
          border-right: solid 2px #00912e;
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 50%;
          right: 20px;
          margin-top: -4px;
          transition: all .2s ease; }
      #recruit .index-contact .contact-menu .menu-item .tel {
        color: #00912e;
        text-align: center; }
        #recruit .index-contact .contact-menu .menu-item .tel p {
          font-size: 3.5rem; }
        #recruit .index-contact .contact-menu .menu-item .tel span {
          font-size: 1.4rem; }

/*-----------------------------------------------------------------------------------
    story
-----------------------------------------------------------------------------------*/
#story {
  color: #595757; }
  #story img {
    max-width: 100%; }
  #story .story-header {
    overflow: hidden; }
    @media screen and (min-width: 999px) {
      #story .story-header {
        background-image: url(./assets/img/header@2x.png);
        background-size: 100% auto;
        min-height: 40vw;
        display: flex;
        align-items: center;
        justify-content: center; } }
    #story .story-header img {
      width: 100%;
      height: auto; }
      @media screen and (min-width: 999px) {
        #story .story-header img {
          max-width: 800px;
          width: 100%;
          display: block;
          margin: -5vw auto 0; } }
  #story .story-nav {
    padding: 20px 5%;
    max-width: 400px;
    margin: 0 auto; }
    @media screen and (min-width: 999px) {
      #story .story-nav {
        max-width: 600px;
        margin-bottom: 40px; } }
    #story .story-nav ul {
      display: flex;
      justify-content: space-between; }
      #story .story-nav ul li {
        width: 48%; }
        @media screen and (min-width: 999px) {
          #story .story-nav ul li {
            width: 40%; } }
        #story .story-nav ul li a {
          display: block; }
          @media screen and (min-width: 999px) {
            #story .story-nav ul li a {
              opacity: 1;
              transition: all .2s ease; }
              #story .story-nav ul li a:hover {
                opacity: .75; } }
          #story .story-nav ul li a img {
            width: 100%;
            height: auto; }
  #story .story-1day {
    padding: 0 5%; }
    #story .story-1day .title {
      margin-bottom: 10px; }
      @media screen and (min-width: 999px) {
        #story .story-1day .title {
          margin-bottom: 40px; } }
      #story .story-1day .title img {
        display: block;
        max-width: 350px;
        width: 50%;
        margin: 0 auto; }
      #story .story-1day .title h3 {
        font-size: 1.8rem;
        text-align: center;
        font-weight: bold; }
        @media screen and (min-width: 999px) {
          #story .story-1day .title h3 {
            font-size: 3.2rem; }
            #story .story-1day .title h3 br {
              display: none; } }
    #story .story-1day .text {
      font-size: 1.6rem;
      line-height: 2;
      margin-bottom: 30px; }
      @media screen and (min-width: 999px) {
        #story .story-1day .text {
          text-align: center;
          margin-bottom: 60px; } }
      #story .story-1day .text br {
        display: none; }
        @media screen and (min-width: 999px) {
          #story .story-1day .text br {
            display: inherit; } }
    #story .story-1day .image {
      margin-bottom: 50px; }
      @media screen and (min-width: 999px) {
        #story .story-1day .image {
          max-width: 700px;
          margin: 0 auto; }
          #story .story-1day .image img {
            margin-bottom: 50px; } }
    #story .story-1day .copy {
      color: #674b3c;
      font-size: 1.8rem;
      text-align: center;
      border-top: 1px dotted #674b3c;
      border-bottom: 1px dotted #674b3c;
      margin: 0 -5%;
      padding: 20px 0; }
      @media screen and (min-width: 999px) {
        #story .story-1day .copy {
          max-width: 800px;
          margin: 80px auto 40px;
          font-size: 2.4rem; }
          #story .story-1day .copy .show-sp {
            display: none; } }
  #story .story-contents .story-contents__item {
    background-color: #fff;
    padding: 0 5%;
    overflow: hidden;
    position: relative; }
    #story .story-contents .story-contents__item:before {
      content: '';
      width: 100px;
      height: 20px;
      background-image: url(./assets/img/yajirusi@2x.png);
      background-size: 100% 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto; }
    #story .story-contents .story-contents__item:nth-of-type(odd) {
      background-color: #faf2ef; }
      #story .story-contents .story-contents__item:nth-of-type(odd):before {
        background-image: url(./assets/img/yajirusi_w@2x.png); }
    #story .story-contents .story-contents__item:first-child:before {
      background: none; }
    #story .story-contents .story-contents__item .inner {
      border-left: 6px solid #a08a7b;
      padding: 30px 0px 30px 15px; }
      @media screen and (min-width: 999px) {
        #story .story-contents .story-contents__item .inner {
          max-width: 1000px;
          margin: 0 auto;
          padding: 60px 0 60px 30px; } }
      @media screen and (min-width: 999px) {
        #story .story-contents .story-contents__item .inner dl {
          display: flex;
          justify-content: space-between; } }
      #story .story-contents .story-contents__item .inner dl dt {
        margin-bottom: 20px;
        position: relative; }
        @media screen and (min-width: 999px) {
          #story .story-contents .story-contents__item .inner dl dt {
            margin-bottom: 0;
            width: 55%; } }
        #story .story-contents .story-contents__item .inner dl dt::before {
          content: '';
          width: 20vw;
          height: 3px;
          display: block;
          background-color: #a08a7b;
          position: absolute;
          top: 15px;
          left: -21vw; }
          @media screen and (min-width: 999px) {
            #story .story-contents .story-contents__item .inner dl dt::before {
              width: 30px;
              left: -35px;
              top: 20px; } }
        #story .story-contents .story-contents__item .inner dl dt .time {
          font-size: 1.95rem;
          margin-bottom: 5px; }
          @media screen and (min-width: 999px) {
            #story .story-contents .story-contents__item .inner dl dt .time {
              font-size: 2.6rem; } }
        #story .story-contents .story-contents__item .inner dl dt .title {
          color: #00912e;
          font-size: 1.95rem;
          font-weight: bold;
          margin-bottom: 10px; }
          @media screen and (min-width: 999px) {
            #story .story-contents .story-contents__item .inner dl dt .title {
              font-size: 2.6rem; } }
        #story .story-contents .story-contents__item .inner dl dt .text {
          font-size: 1.4rem; }
          @media screen and (min-width: 999px) {
            #story .story-contents .story-contents__item .inner dl dt .text {
              font-size: 1.6rem; } }
      @media screen and (min-width: 999px) {
        #story .story-contents .story-contents__item .inner dl dd {
          margin-bottom: 0;
          width: 40%; } }
  #story .story-contents .story-contents__comment {
    position: relative;
    padding: 50px 5%; }
    @media screen and (min-width: 999px) {
      #story .story-contents .story-contents__comment .inner {
        max-width: 1000px;
        margin: 0 auto;
        padding: 60px 0 60px 30px; } }
    #story .story-contents .story-contents__comment.brown {
      background: #faf2ef; }
      #story .story-contents .story-contents__comment.brown:before {
        background-image: url(./assets/img/yajirusi_w@2x.png); }
    #story .story-contents .story-contents__comment:before {
      content: '';
      width: 100px;
      height: 20px;
      background-image: url(./assets/img/yajirusi@2x.png);
      background-size: 100% 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto; }
    @media screen and (min-width: 999px) {
      #story .story-contents .story-contents__comment dl {
        display: flex;
        justify-content: space-between; } }
    #story .story-contents .story-contents__comment dl dt {
      font-size: 1.6rem;
      line-height: 2;
      margin-bottom: 20px; }
      @media screen and (min-width: 999px) {
        #story .story-contents .story-contents__comment dl dt {
          margin-bottom: 0;
          width: 55%; } }
    @media screen and (min-width: 999px) {
      #story .story-contents .story-contents__comment dl dd {
        margin-bottom: 0;
        width: 40%; } }
    #story .story-contents .story-contents__comment dl dd img {
      display: block;
      max-width: 400px;
      width: 100%;
      margin: 0 auto; }
  #story .index-contact {
    background-color: rgba(168, 204, 6, 0.08);
    padding: 40px 5%; }
    @media screen and (min-width: 999px) {
      #story .index-contact {
        margin: 0px auto 0;
        padding: 80px 0; } }
    #story .index-contact .index-contact__inner {
      width: 100%;
      max-width: 1000px;
      margin: 0px auto 0px; }
    #story .index-contact .title {
      text-align: center;
      width: 90px;
      margin: 0 auto 30px; }
      @media screen and (min-width: 999px) {
        #story .index-contact .title {
          width: 120px; } }
      #story .index-contact .title img {
        width: 100%; }
    #story .index-contact .copy {
      text-align: center;
      font-size: 2.5rem;
      color: #00912e;
      margin-bottom: 10px;
      line-height: 1; }
      @media screen and (min-width: 999px) {
        #story .index-contact .copy {
          font-size: 5rem;
          margin-bottom: 20px; } }
    #story .index-contact .text {
      text-align: center;
      font-size: 1.2rem;
      color: #00912e; }
      @media screen and (min-width: 999px) {
        #story .index-contact .text {
          font-size: 2.0rem; } }
    #story .index-contact .contact-menu {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      @media screen and (min-width: 999px) {
        #story .index-contact .contact-menu {
          flex-direction: row;
          align-items: flex-start; } }
      #story .index-contact .contact-menu .menu-item {
        margin-bottom: 30px; }
        @media screen and (min-width: 999px) {
          #story .index-contact .contact-menu .menu-item {
            margin-bottom: 0;
            margin: 20px 40px; } }
        #story .index-contact .contact-menu .menu-item dl dt {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px; }
          @media screen and (min-width: 999px) {
            #story .index-contact .contact-menu .menu-item dl dt {
              margin-bottom: 10px; } }
          #story .index-contact .contact-menu .menu-item dl dt span img {
            max-width: 30px;
            margin-right: 10px; }
          #story .index-contact .contact-menu .menu-item dl dt p {
            font-size: 1.5rem;
            color: #00912e; }
        #story .index-contact .contact-menu .menu-item .button a {
          display: block;
          max-width: 300px;
          width: 100%;
          padding: 15px 0px;
          font-size: 1.6rem;
          text-align: center;
          margin: 0 auto 30px;
          color: #00912e;
          border: 2px solid #00912e;
          text-decoration: none;
          position: relative;
          background-color: transparent;
          transition: all .2s ease; }
          #story .index-contact .contact-menu .menu-item .button a:hover {
            background-color: #00912e;
            color: #fff; }
            #story .index-contact .contact-menu .menu-item .button a:hover::after {
              border-top: solid 2px #fff;
              border-right: solid 2px #fff; }
          #story .index-contact .contact-menu .menu-item .button a::after {
            content: '';
            width: 10px;
            height: 10px;
            border: 0px;
            border-top: solid 2px #00912e;
            border-right: solid 2px #00912e;
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 50%;
            right: 20px;
            margin-top: -4px;
            transition: all .2s ease; }
        #story .index-contact .contact-menu .menu-item .tel {
          color: #00912e;
          text-align: center; }
          #story .index-contact .contact-menu .menu-item .tel p {
            font-size: 3.5rem; }
          #story .index-contact .contact-menu .menu-item .tel span {
            font-size: 1.4rem; }
