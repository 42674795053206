/*-----------------------------------------------------------------------------------
    sidebar
-----------------------------------------------------------------------------------*/

.side-nav {
	@include media(PC){
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

	.side-nav-col {
    margin-bottom: 10px;

    @include media(PC) {
      width: 30%;
    }

		form {
			display: block;
			width:100%;
		}

		select {
			background-color: #eee;
			display: block;
			width:100%;
			padding: 10px 20px;
      position: relative;
      border: none;
      -moz-appearance: none;    // Firefox 40+
      -webkit-appearance: none; // Chrome 45+
      font-size: 1.3rem;
      border-radius: 4px;
		}
		label{
			position: relative;
			display: block;

			&:after{
				content:"\f0dc";
				font-family: FontAwesome;
				position: absolute;
				top: 10px;
				right: 20px;
				display: block;
				pointer-events: none;
			}
		}
	}
}
