/*-----------------------------------------------------------------------------------
    staff
-----------------------------------------------------------------------------------*/

#staff {
  width: 100%;
  max-width: $width;
  margin: 0px auto 0px;
  // padding: 0 5%;
  @include media(PC) {
    padding: 0;
    margin: 40px auto 0px;
  }

  .staff-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
    @include media(PC) {
      font-size: 5.1rem;
    }
  }

  .staff-contents {
    background-color: #ded7c5;
    padding: 30px 5%;
    .staff-contents__eyecatch {
      img {
        max-width: 100%;
      }
    }
    .staff-contents__section {
      display: flex;
      flex-direction: column;
      padding: 40px 0;

      @include media(PC) {
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: -2%;
        align-items: stretch;
      }

      .section-item {
        background-color: #fff;
        border-radius: 20px;
        padding: 0 20px 20px;
        margin-bottom: 30px;

        @include media(PC) {
          width: 48%;
          margin-left: 2%;
          margin-bottom: 0;

          margin-top: 40px;
        }

        &:last-child {
          margin-bottom: -10px;

          @include media(PC) {
            margin-bottom: 0;
          }
        }

        .section-item__header {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          border-bottom: 1px solid $primary-color;
          margin-top: -5vw;

          @include media(PC) {
            margin-top: -70px;
          }

          .profile {
            width: 55%;
            padding: 3vw 0px 0px 10px;

            @include media(PC) {
              padding: 10px 0 0 10px;
            }

            .role {
              font-size: 1.2rem;
            }
            .name {
              font-size: 2.6rem;

              @include media(PC) {
                font-size: 3.0rem;
              }
            }
            .kana {
              color: $primary-color;
              font-family: $heading-font;
              font-size: 1.3rem;
            }
          }

          .image {
            width: 45%;
            img {
              max-width: 100%;
              height: auto;
              vertical-align: middle;
            }
          }
        }

        .section-item__qualifications {
          padding: 10px 0;
          font-size: 1.2rem;
          border-bottom: 1px solid $primary-color;

          @include media(PC) {
            font-size: 1.4rem;
          }
        }

        .section-item__profile {
          padding: 10px 0;
          font-size: 1.2rem;

          @include media(PC) {
            font-size: 1.4rem;
          }
        }

      }
    }
  }
}

