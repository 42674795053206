
/*-----------------------------------------------------------------------------------
    work
-----------------------------------------------------------------------------------*/

#work {
  width: 100%;
  // max-width: $width;
  // margin: 20px auto 0px;
  padding: 0 5%;
  @include media(PC) {
    padding: 0;
    margin: 40px auto 0px;
  }
  .work__inner {
    @include media(PC) {
      max-width: $width;
      margin: 20px auto 0px;
    }
  }

  .work-header {
    margin-bottom: 40px;
    h2 {
      font-size: 1.4rem;

      @include media(PC) {
        font-size: 2.8rem;
      }

      span {
        color: $accent-color;
        padding-right: 10px;
      }
    }
  }

  .work-gallery {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    @include media(PC) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    .work-gallery__main {
      width: 100%;
      margin-bottom: 20px;
      touch-action: auto !important;

      @include media(PC) {
        width: 780px;
        background-color: rgb(244, 244, 244);
        padding: 20px;
        .slick-slide {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .slick-track {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .item {
        width: 100%;

        img {
          width: auto;
          max-width: 100%;
          height: auto;
        }
      }
    }

    .slide-arrow {
      position: absolute;
      top: 45%;
      z-index: 20;
    }

    .prev-arrow {
      left: -10px;
      display: block;
      width: 35px;
      height: 35px;
      cursor: pointer;
      background-image: url(./assets/img/cursor-left.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% auto;
      transition: all .1s ease;

      @include media(PC) {
        width: 50px;
        height: 50px;
        left: 20px
      }

      &:hover {
        opacity: 0.5;
      }
    }

    .next-arrow {
      right: -10px;
      display: block;
      width: 35px;
      height: 35px;
      cursor: pointer;
      background-image: url(./assets/img/cursor-right.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% auto;
      transition: all .1s ease;

      @include media(PC) {
        width: 50px;
        height: 50px;
        right: 20px;
      }

      &:hover {
        opacity: 0.5;
      }
    }

    .work-gallery__nav {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;

      @include media(PC) {
        width: 200px;
      }

      .item {
        width: 20%;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 4px solid #fff;

        @include media(PC) {
          width: 50%;
        }

        img {
          width: 100%;
          height: auto;
        }

        &.thumbnail-current {
          border: 4px solid $primary-color;
        }
      }
    }
  }

  .work-caption {
    .title {
      font-size: 2.1rem;
      margin-bottom: 10px;
    }
    .text {
      font-size: 1.6rem;
    }
  }

  .work-shere {
    margin: 40px 0;
    ul {
      display: flex;

      li {
        margin-right: 30px;

        a {
          display: block;
          img {
            height: 30px;

          }
        }
      }
    }
  }

  .work-ba {
    margin-bottom: 40px;
    .title {
      font-size: 2.1rem;
      color: $accent-color;
      border-bottom: 1px solid $accent-color;
      padding-bottom: 2px;
      margin-bottom: 20px;

      @include media(PC) {
        font-size: 2.8rem;
        margin-bottom: 30px;
      }
    }
    .figure {
      display: flex;
      flex-direction: column;

      @include media(PC) {
        flex-direction: row;
        justify-content: space-between;
      }

      dl {
        margin-bottom: 20px;

        @include media(PC) {
          width: 48%;
        }
        dt {
          p {
            font-size: 1.4rem;
            border: 1px solid #000;
            display: inline-block;
            padding: 5px 10px;
            margin-bottom: 5px;
          }
          img {
            max-width: 100%;
          }
        }
        dd {
          font-size: 1.4rem;
        }
      }
    }
  }

  .work-planning {
    margin-bottom: 40px;
    .title {
      font-size: 2.1rem;
      color: $accent-color;
      border-bottom: 1px solid $accent-color;
      padding-bottom: 2px;
      margin-bottom: 20px;
      @include media(PC) {
        font-size: 2.8rem;
        margin-bottom: 30px;
      }
    }
    .image {
      img {
        max-width: 100%;
        width: auto;
      }
    }
  }

  .work-pickup {
    margin-bottom: 40px;
    .title {
      font-size: 2.1rem;
      color: $accent-color;
      border-bottom: 1px solid $accent-color;
      padding-bottom: 2px;
      margin-bottom: 20px;
      @include media(PC) {
        font-size: 2.8rem;
        margin-bottom: 30px;
      }
    }
    dl {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;

      @include media(PC) {
        flex-direction: row;
        justify-content: space-between;
      }

      dt {
        @include media(PC) {
          width: 450px;
        }
        p {
          font-size: 1.4rem;
          border: 1px solid #000;
          display: inline-block;
          padding: 5px 10px;
          margin-bottom: 5px;
        }
        img {
          max-width: 100%;
        }
      }
      dd {
        font-size: 1.4rem;

        @include media(PC) {
          width: calc(100% - 470px);
          font-size: 1.6rem;
          line-height: 1.8;
        }
      }
    }
  }

  .work-point {
    margin-bottom: 40px;

    .title {
      background-color: #ecf2d9;
      border-left: 8px solid $accent-color;
      font-size: 1.8rem;
      color: #595757;
      padding: 10px;
      margin-bottom: 20px;
      margin-top: 40px;
    }
    .text {
      margin-bottom: 20px;
      p {
        font-size: 1.6rem;
        line-height: 1.8;
        margin-bottom: 15px;
      }
      h1,h2,h3,h4,h5 {
        font-size: 2.0rem;
        color: $accent-color;
        margin-bottom: 15px;
      }
    }
  }

  .work-contact {
    background-color: rgba(#a8cc06, 0.08);
    // width: 100%;
    // max-width: $width;
    // margin: 40px auto 0px;
    padding: 40px 5%;
    @include media(PC) {
      margin: 80px auto 0;
      padding: 80px 0;
    }

    .work-contact__inner {
      width: 100%;
      max-width: $width;
      margin: 0px auto 0px;
    }

    .title {
      text-align: center;
      width: 90px;
      margin: 0 auto 30px;

      @include media(PC) {
        width: 120px;
      }
      img {
        width: 100%;
      }
    }

    .copy {
      text-align: center;
      font-size: 2.5rem;
      color: $primary-color;
      margin-bottom: 10px;
      line-height: 1;

      @include media(PC) {
        font-size: 5rem;
        margin-bottom: 20px;
      }
    }
    .text {
      text-align: center;
      font-size: 1.2rem;
      color: $primary-color;

      @include media(PC) {
        font-size: 2.0rem;
      }
    }
    .contact-menu {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include media(PC) {
        flex-direction: row;
        align-items: flex-start;
      }

      .menu-item {
        margin-bottom: 30px;

        @include media(PC) {
          margin-bottom: 0;
          margin: 20px 40px;
        }
        dl {
          dt {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            @include media(PC) {
              margin-bottom: 10px;
            }
            span {
              img {
                max-width: 30px;
                margin-right: 10px;
              }
            }
            p {
              font-size: 1.5rem;
              color: $primary-color;
            }
          }
        }
        .button {
          a {
            display: block;
            max-width: 300px;
            width: 100%;
            padding: 15px 0px;
            font-size: 1.6rem;
            text-align: center;
            margin: 0 auto 30px;
            color: $primary-color;
            border: 2px solid $primary-color;
            text-decoration: none;
            position: relative;
            background-color: transparent;
            transition: all .2s ease;

            &:hover {
              background-color: $primary-color;
              color: #fff;

              &::after {
                border-top: solid 2px #fff;
                border-right: solid 2px #fff;
              }
            }

            &::after {
              content: '';
              width: 10px;
              height: 10px;
              border: 0px;
              border-top: solid 2px $primary-color;
              border-right: solid 2px $primary-color;
              -ms-transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              position: absolute;
              top: 50%;
              right: 20px;
              margin-top: -4px;
              transition: all .2s ease;
            }
          }
        }
        .tel {
          color: $primary-color;
          text-align: center;
          p {
            font-size: 3.5rem;
          }
          span {
            font-size: 1.4rem;
          }
        }
      }
    }

  }
}

#archive-work {
  // width: 100%;
  // max-width: $width;
  // margin: 0px auto 0px;
  padding: 0 5%;
  @include media(PC) {
    padding: 0;
    margin: 0px auto 0px;
  }

  .archive-work-header {
    background-image: url(./assets/img/wokstop@2x.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    font-size: 2.5rem;
    color: $primary-color;
    font-family: $heading-font;
    font-weight: bold;
    letter-spacing: 0.01em;
    margin-bottom: 30px;

    @include media(PC) {
      min-height: 300px;
      font-size: 5.1rem;
    }
  }

  .archive-work-nav {
    margin-bottom: 20px;

    @include media(PC) {
      padding: 0 100px;
      width: 100%;
      max-width: $width;
      margin: 0px auto 0px;
    }
    ul {
      display: flex;
      flex-wrap:wrap;
      margin-left: -3%;

      @include media(PC) {
        padding: 40px 0;
        margin-left: -2%;
      }

      li {
        width: 47%;
        margin-left: 3%;
        margin-bottom: 20px;

        @include media(PC) {
          width: 31%;
          margin-left: 2%;
        }
        a {
          display: block;
          color: $primary-color;
          font-size: 1.6rem;
          text-decoration: none;
          padding: 5px;
          text-align: center;
          width: 100%;
          border: 1px solid $primary-color;

          @include media(PC) {
            transition: all .2s ease;
            &:hover {
              background-color: $primary-color;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .archive-work-banner {
    margin-bottom: 40px;

    @include media(PC) {
      padding: 0 80px;
      width: 100%;
      max-width: $width;
      margin: 0px auto 0px;
    }
    a {
      display: block;
      opacity: 1;
      transition: all .2s ease;

      &:hover {
        opacity: .75;
      }
      img {
        max-width: 100%;
      }
    }
  }

  .archive-work-old {

    @include media(PC) {
      padding: 50px 0;
    }
    p {
      text-align: center;
      font-size: 1.2rem;
      margin-bottom: 10px;

      @include media(PC) {
        font-size: 1.6rem;
        margin-bottom: 20px;
      }
    }
    ul {
      display: flex;
      justify-content: center;
      li {
        margin: 0 5px;
        border-right: 1px solid $primary-color;
        padding: 0 20px;

        @include media(PC) {
          padding: 0 30px;
        }

        &:first-child {
          border-left: 1px solid $primary-color;
        }
        a {
          color: $primary-color;
          font-size: 1.2rem;
          text-decoration: none;

          @include media(PC) {
            font-size: 1.6rem;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .archive-work-list {
    margin: 50px 0;

    @include media(PC) {
      width: 100%;
      max-width: $width;
      margin: 40px auto ;
    }
    .entry-wrap {
      display: flex;
      flex-wrap: wrap;
      margin-left: -3%;

      .entry {
        margin-bottom: 20px;
        width: 47%;
        margin-left: 3%;

        @include media(PC) {
          width: 22%;
          margin-bottom: 30px;
        }

        a {
          display: block;
          position: relative;
          text-decoration: none;
          opacity: 1;
          transition: all .2s ease;

          &:hover {
            opacity: .7;
          }
          .cat {
            position: absolute;
            right:0;
            top:0;
            color: #fff;
            background-color: $primary-color;
            width: 100px;
            font-size: 1.2rem;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          img {
            width: 100%;
            height: auto;
            vertical-align: middle;
          }
        }
      }

      .no-data {
        color: $primary-color;
        text-align: center;
        font-size: 1.3rem;
        padding: 40px 0;
        width: 100%;

        @include media(PC) {

        }
        .strong {
          font-size: 4.0rem;
          font-weight: bold;
        }
      }
    }
  }

  .work-contact {
    background-color: rgba(#a8cc06, 0.08);
    // width: 100%;
    // max-width: $width;
    // margin: 40px auto 0px;
    padding: 40px 5%;
    @include media(PC) {
      margin: 80px auto 0;
      padding: 80px 0;
    }

    .work-contact__inner {
      width: 100%;
      max-width: $width;
      margin: 0px auto 0px;
    }

    .title {
      text-align: center;
      width: 90px;
      margin: 0 auto 30px;

      @include media(PC) {
        width: 120px;
      }
      img {
        width: 100%;
      }
    }

    .copy {
      text-align: center;
      font-size: 2.5rem;
      color: $primary-color;
      margin-bottom: 10px;
      line-height: 1;

      @include media(PC) {
        font-size: 5rem;
        margin-bottom: 20px;
      }
    }
    .text {
      text-align: center;
      font-size: 1.2rem;
      color: $primary-color;

      @include media(PC) {
        font-size: 2.0rem;
      }
    }
    .contact-menu {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include media(PC) {
        flex-direction: row;
        align-items: flex-start;
      }

      .menu-item {
        margin-bottom: 30px;

        @include media(PC) {
          margin-bottom: 0;
          margin: 20px 40px;
        }
        dl {
          dt {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            @include media(PC) {
              margin-bottom: 10px;
            }
            span {
              img {
                max-width: 30px;
                margin-right: 10px;
              }
            }
            p {
              font-size: 1.5rem;
              color: $primary-color;
            }
          }
        }
        .button {
          a {
            display: block;
            max-width: 300px;
            width: 100%;
            padding: 15px 0px;
            font-size: 1.6rem;
            text-align: center;
            margin: 0 auto 30px;
            color: $primary-color;
            border: 2px solid $primary-color;
            text-decoration: none;
            position: relative;
            background-color: transparent;
            transition: all .2s ease;

            &:hover {
              background-color: $primary-color;
              color: #fff;

              &::after {
                border-top: solid 2px #fff;
                border-right: solid 2px #fff;
              }
            }

            &::after {
              content: '';
              width: 10px;
              height: 10px;
              border: 0px;
              border-top: solid 2px $primary-color;
              border-right: solid 2px $primary-color;
              -ms-transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              position: absolute;
              top: 50%;
              right: 20px;
              margin-top: -4px;
              transition: all .2s ease;
            }
          }
        }
        .tel {
          color: $primary-color;
          text-align: center;
          p {
            font-size: 3.5rem;
          }
          span {
            font-size: 1.4rem;
          }
        }
      }
    }

  }

  .tablenav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;

    a {
      color: #000;
      margin: 0 20px;
      font-size: 1.6rem;
      text-decoration: none;
    }

    span {
      color: #000;
      margin: 0 20px;
      font-size: 1.6rem;
      text-decoration: none;
    }
  }
}

#restoration {
  width: 100%;
  // max-width: $width;
  // margin: 20px auto 0px;
  // padding: 0 5%;
  @include media(PC) {
    padding: 0;
    margin: 40px auto 0px;
  }

  .restoration-header {
    background-image: url(./assets/img/wokstop@2x.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    font-size: 2.5rem;
    color: $primary-color;
    font-family: $heading-font;
    font-weight: bold;
    letter-spacing: 0.01em;
    margin-bottom: 30px;

    @include media(PC) {
      min-height: 300px;
      font-size: 5.1rem;
    }
  }

  .restoration-nav {
    margin-bottom: 20px;

    @include media(PC) {
      padding: 0 100px;
      max-width: $width;
      margin: 20px auto 0px;
      padding: 0 5%;
    }
    ul {
      display: flex;
      flex-wrap:wrap;
      margin-left: -3%;

      @include media(PC) {
        padding: 40px 0;
        margin-left: -2%;
      }

      li {
        width: 47%;
        margin-left: 3%;
        margin-bottom: 20px;

        @include media(PC) {
          width: 31%;
          margin-left: 2%;
        }
        a {
          display: block;
          color: $primary-color;
          font-size: 1.6rem;
          text-decoration: none;
          padding: 5px;
          text-align: center;
          width: 100%;
          border: 1px solid $primary-color;

          @include media(PC) {
            transition: all .2s ease;
            &:hover {
              background-color: $primary-color;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .restoration-contents {
    @include media(PC) {
      max-width: $width;
      margin: 20px auto 0px;
      padding: 0 5%;
    }
    .restoration-contents__title {
      margin-bottom: 40px;

      .title {
        background-color: #ecf2d9;
        border-left: 8px solid $accent-color;
        font-size: 1.8rem;
        color: #595757;
        padding: 10px;
        margin-bottom: 20px;
      }
      .text {
        font-size: 1.4rem;
        margin-bottom: 15px;
      }
    }

    .restoration-contents__item {
      img {
        max-width: 100%;
        height: auto;
        vertical-align: middle;
      }
    }
  }

  .work-contact {
    background-color: rgba(#a8cc06, 0.08);
    padding: 40px 5%;
    @include media(PC) {
      margin: 80px auto 0;
      padding: 80px 0;
    }

    .work-contact__inner {
      width: 100%;
      max-width: $width;
      margin: 40px auto 0px;
    }

    .title {
      text-align: center;
      width: 90px;
      margin: 0 auto 30px;

      @include media(PC) {
        width: 120px;
      }
      img {
        width: 100%;
      }
    }

    .copy {
      text-align: center;
      font-size: 2.5rem;
      color: $primary-color;
      margin-bottom: 10px;
      line-height: 1;

      @include media(PC) {
        font-size: 5rem;
        margin-bottom: 20px;
      }
    }
    .text {
      text-align: center;
      font-size: 1.2rem;
      color: $primary-color;

      @include media(PC) {
        font-size: 2.0rem;
      }
    }
    .contact-menu {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include media(PC) {
        flex-direction: row;
        align-items: flex-start;
      }

      .menu-item {
        margin-bottom: 30px;

        @include media(PC) {
          margin-bottom: 0;
          margin: 20px 40px;
        }
        dl {
          dt {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            @include media(PC) {
              margin-bottom: 10px;
            }
            span {
              img {
                max-width: 30px;
                margin-right: 10px;
              }
            }
            p {
              font-size: 1.5rem;
              color: $primary-color;
            }
          }
        }
        .button {
          a {
            display: block;
            max-width: 300px;
            width: 100%;
            padding: 15px 0px;
            font-size: 1.6rem;
            text-align: center;
            margin: 0 auto 30px;
            color: $primary-color;
            border: 2px solid $primary-color;
            text-decoration: none;
            position: relative;
            background-color: transparent;
            transition: all .2s ease;

            &:hover {
              background-color: $primary-color;
              color: #fff;

              &::after {
                border-top: solid 2px #fff;
                border-right: solid 2px #fff;
              }
            }

            &::after {
              content: '';
              width: 10px;
              height: 10px;
              border: 0px;
              border-top: solid 2px $primary-color;
              border-right: solid 2px $primary-color;
              -ms-transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              position: absolute;
              top: 50%;
              right: 20px;
              margin-top: -4px;
              transition: all .2s ease;
            }
          }
        }
        .tel {
          color: $primary-color;
          text-align: center;
          p {
            font-size: 3.5rem;
          }
          span {
            font-size: 1.4rem;
          }
        }
      }
    }

  }
}
