
/*-----------------------------------------------------------------------------------
    contact
-----------------------------------------------------------------------------------*/

#contact {
  width: 100%;
  // max-width: $width;
  // margin: 20px auto 0px;
  // padding: 0 5%;
  @include media(PC) {
    padding: 0;
    margin: 40px auto 0px;
  }

  .contact-header {
    background-image: url(./assets/img/contacttop@2x.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    font-size: 2.5rem;
    color: $primary-color;
    font-family: $heading-font;
    font-weight: bold;
    letter-spacing: 0.01em;

    @include media(PC) {
      height: 300px;
      font-size: 5.1rem;
    }
  }

  .contact-contents {
    background-color: rgba(#a8cc06,0.2);
    padding: 40px 5%;

    @include media(PC) {
      .inner {
        max-width: $width;
        margin: 20px auto 0px;
      }
    }

    .prv {
      height: 200px;
      overflow: auto;
      background-color: #fff;
      padding: 20px;
    }
  }

  .form-group {
    color: #000;
    dl {
        padding-right: 20px;
        display: block;
        padding-right: 0;
        font-size: 1.4rem;
        margin-bottom: 20px;
        @include media(PC) {
          display: flex;
          padding-bottom: 20px;
          border-bottom: 1px dotted #eee;
          margin-bottom: 20px;
          justify-content: center;
        }
        dt {
          text-align: left;
          width: auto;
          margin-right: 0;
          margin-bottom: 10px;
          @include media(PC) {
            text-align: right;
            width: 200px;
            margin-right: 40px;
          }
          .clr_00 {
              color: #e32877;
          }
          span {
            padding: 2px 5px;
            color: #fff;
            background: $primary-color;
            margin-left: 10px;
            font-weight: bold;
          }
        }
        dd {
          width: auto;
          border: 0;
          @include media(PC) {
            width: calc(100% - 500px);
          }
          input {
            width: 100%;
            background-color: #fff;
            border: 0;
            padding: 10px;
            &[type="radio"] {
              width: auto !important;
            }
            @include media(PC) {
              max-width: 500px;
              padding: 5px;
              border-radius: 5px;
            }
          }
          span.cap {
            font-size: 12px;
            display: block;
            margin-top: 10px;
          }
          textarea {
            padding: 5px;
            border-radius: 5px;
            background-color: #fff;
            border: 0;
            width: 100%;
            padding: 10px;
            @include media(PC) {
              padding: 5px;
              max-width: 500px;
            }
          }
        }
        .form-teltrueform {
          display: flex;
          align-items: center;
          dl {
            padding-bottom: 0;
            border-bottom: 0px;
            margin-bottom: 0px;
            padding-right: 0;
            margin-right: 20px;
            display: flex;
            align-items: center;
            dt {
                width: auto;
                margin-right: 10px;
            }
            dd {
                width: auto;
            }
            select {
                padding: 5px;
                border-radius: 5px;
                border: 1px solid #eee;
                min-width: 50px;
                text-align: center;
                option {
                    text-align: center;
                }
            }
          }
        }
        .form-dateform {
            dl {
                padding-bottom: 0;
                border-bottom: 0px;
                margin-bottom: 15px;
                padding-right: 0;
                margin-right: 20px;
                display: block;
                @include media(PC) {
                  display: flex;
                  align-items: center;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                dt {
                    width: auto;
                    margin-right: 10px;
                    width: 70px;
                }
                dd {
                  width: 100%;
                  @include media(PC) {
                    width: calc(100% - 80px);
                    font-size: 14px;
                  }
                }
                .hasDatepicker {
                  width: 120px;
                  @include media(PC) {
                    width: 150px;
                  }
                }
                select {
                    padding: 5px;
                    border-radius: 5px;
                    border: 1px solid #eee;
                    min-width: 50px;
                    text-align: center;
                    margin-right: 10px;
                    option {
                        text-align: center;
                    }
                }
            }
        }
        .mwform-radio-field {
          display: block;
          margin: 0px 0px 10px !important;
        }
    }
    &.form-cap {
        p {
            text-align: center;
            line-height: 1.8;
        }
        .form-check {
            text-align: center;
            margin: 20px 0;
            span {
                font-weight: bold !important;
                margin-left: 10px;
            }
        }
    }
  }
  .contact-form__submit {
    margin: 20px auto 40px;
    text-align: center;
    input {
        max-width: 200px;
        background-color: transparent;
        text-align: center;
        cursor: pointer;
        padding: 20px;
        margin: 10px 20px;
        color: $primary-color;
        transition: all 0.2s ease;
        letter-spacing: .2em;
        font-weight: bold;
        border-radius: 5px;
        width: 80%;
        border: 1px solid $primary-color;
        font-size: 1.5rem;
        @include media(PC) {
          width: 100%;
          margin: 0px 20px;
          display: inline-block;
          padding: 20px 30px;
          font-size: 1.8rem;
        }

        &:hover {
          background-color: $primary-color;
          color: #fff;
        }
    }
  }
  .mw_wp_form_preview {
    .form-cap {
        display: none;
    }
  }
  .mw_wp_form_complete {
    margin: 0px 20px;
    margin-top: -40px;
    padding: 40px 20px;
    @include media(PC) {
      padding: 20px;
      margin-top: -20px;
      margin-bottom: 20px;
    }

    .thanks-heading {
      text-align: center;
      margin-bottom: 20px;
      font-size: 20px * 0.7;
      @include media(PC) {
        font-size: 20px;
      }
    }
    .thanks-txt {
        margin-bottom: 40px;
    }

    .thanks-back {
        width: 200px;
        background-color: $primary-color;
        margin: 0 auto;
        transition: all 0.2s ease;

        &:hover {
            background-color: scheme(2);
        }

        a {
            display: block;
            text-align: center;
            padding: 20px;
            color: #fff;
            letter-spacing: 0.1em;
        }
    }
  }



}

