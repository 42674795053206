/*-----------------------------------------------------------------------------------
    letter
-----------------------------------------------------------------------------------*/

#letter {
  width: 100%;
  max-width: $width;
  margin: 20px auto 0px;
  padding: 0 5%;
  @include media(PC) {
    padding: 0;
    margin: 40px auto 0px;
  }

  .letter-header {
    background-image: url(./assets/img/kazokutoop@2x.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    margin-bottom: 30px;

    img {
      max-width: 100%;
      width: 40%;
      height: auto;
    }
    @include media(PC) {
      height: 400px;
      font-size: 5.1rem;
    }
  }

  .letter-caption {
    margin: 20px 0;
    @include media(PC) {
      margin: 40px 0;
      font-size: 1.6rem;
    }

    .copy {
      text-align: center;
      font-size: 1.2rem;
      color: #595757;
      margin-bottom: 30px;

      @include media(PC) {
        font-size: 1.6rem;
      }
    }
  }

  .letter-contents {
    .letter-contents__pickup {
      margin-bottom: 30px;
      .entry {
        position: relative;
        margin-bottom: 30px;
        h3 {
          background-color: #ecf2d9;
          border-left: 8px solid $accent-color;
          font-size: 1.8rem;
          color: #595757;
          padding: 10px;
          margin-bottom: 20px;
        }

        .download {
          position: absolute;
          right:5px;
          top: 60px;
          a {
            display: block;
            img {
              width: 50px;
              height: auto;
            }
          }
        }

        .image {
          ul {
            display: flex;
            flex-direction: column;

            li {
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .letter-contents__archives {
      .title {
        background-color: #ecf2d9;
        border-left: 8px solid $accent-color;
        font-size: 1.8rem;
        color: #595757;
        padding: 10px;
        margin-bottom: 20px;
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -2%;
        .entry {
          width: 48%;
          margin-left: 2%;
          margin-bottom: 20px;
          position: relative;

          @include media(PC) {
            width: 14.4%;
          }
          .download {
            position: absolute;
            right: -5px;
            top: -5px;
            a {
              display: block;
              img {
                width: 50px;
              }
            }
          }
          .image {
            img {
              width: 100%;
              height: auto;
            }
          }
          h3 {
            text-align: center;
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .letter-backnumber {
    text-align: center;
    padding: 40px 0;
    p {
      font-size: 1.4rem;
      a {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }
}
