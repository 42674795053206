#index {
  .index-hero {
    .slick-slide {
      opacity: 1;
      transition: all .2s ease;

      @include media(PC) {
        opacity: 0.6;
      }
    }

    .slick-center {
      opacity: 1;
    }

    .item {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .index-news {
    width: 100%;
    max-width: $width;
    margin: 20px auto 40px;
    padding: 0 5%;

    @include media(PC) {
      margin: 40px auto 80px;
      padding: 0;
    }

    .title {
      margin-bottom: 15px;
      font-family: $heading-font;
      font-size: 2.25rem;
      font-weight: bold;
      color: $primary-color;
      letter-spacing: 0.1em;

      @include media(PC) {
        font-size: 3.4rem;
      }
    }

    .index-news__list {
      .list {
        .list-item {
          margin-bottom: 20px;

          @include media(PC) {
            display: flex;
            align-items: center;
          }

          .cat {
            margin-bottom: 10px;
            font-family: $heading-font;
            font-size: 1.5rem;
            font-weight: bold;
            color: $primary-color;
            letter-spacing: 0.1em;

            @include media(PC) {
              width: 90px;
              margin-right: 20px;
              margin-bottom: 0;
              font-size: 2.0rem;
              text-align: right;
            }
          }

          dl {
            display: flex;
            font-size: 1.2rem;
            font-weight: 300;

            @include media(PC) {
              width: calc(100% - 110px);
              font-size: 2.0rem;
              align-items: center;
            }

            dt {
              width: 80px;
              margin-right: 5px;
              color: $primary-color;

              @include media(PC) {
                width: 120px;
              }
            }

            dd {
              width: calc(100% - 85px);

              @include media(PC) {
                width: calc(100% - 125px);
              }

              a {
                color: $dark-color;

                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }

        .is--new {
          padding-left: 4px;
          font-size: 12px;
          font-weight: bold;
          color: red;

          @include media(PC) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .index-concept {
    width: 100%;
    max-width: $width;
    margin: 20px auto 40px;
    padding: 0 5%;

    @include media(PC) {
      margin: 40px auto 80px;
      padding: 0;
    }

    .copy {
      margin-bottom: 30px;
      font-size: 3.0rem;
      text-align: center;

      @include media(PC) {
        font-size: 40px;

        br {
          display: none;
        }
      }
    }

    .image {
      margin-bottom: 40px;

      img {
        display: block;
        width: 100%;
        max-width: 60%;
        margin: 0 auto;

        @include media(PC) {
          max-width: 220px;
        }
      }
    }

    .text {
      font-size: 1.2rem;
      line-height: 2;
      text-align: center;

      @include media(PC) {
        font-size: 1.6rem;
      }
    }
  }

  .index-story {

    // margin: 20px auto 0px;
    padding: 0px 0px 30px;
    background-color: #f9f3f0;

    @include media(PC) {
      margin: 40px auto 0px;
      padding: 40px 0;
      background-image: url(./assets/img/storyback@2x.png);
      background-position: center top;
      background-size: cover;
    }

    .index-story__inner {
      @include media(PC) {
        width: 100%;
        max-width: $width;
        margin: 20px auto 0px;
      }

      .title {
        padding-top: 40px;
        background-image: url(./assets/img/storyback@2x.png);
        background-position: center top;
        background-size: cover;
        text-align: center;

        @include media(PC) {
          margin-bottom: 30px;
          padding-top: 0;
          background: none;
        }

        span {
          display: block;
          margin-bottom: 10px;
          font-size: 1.4rem;
          color: #604c3f;

          @include media(PC) {
            margin-bottom: 20px;
            font-size: 2.0rem;
          }
        }

        img {
          width: 100%;
          max-width: 90%;
          margin: 0 auto 40px;

          @include media(PC) {
            max-width: 730px;
          }
        }
      }

      .list {
        display: flex;
        flex-direction: column;
        padding: 30px 5%;

        @include media(PC) {
          flex-direction: row;
          margin-bottom: 50px;
          padding: 0;
          justify-content: center;
        }

        .list-item {
          margin-top: -40px;

          @include media(PC) {
            width: 230px;
            margin: 0 20px;
            margin-top: 0;
          }

          &:nth-of-type(even) {
            position: relative;

            span {
              margin-left: 40%;

              @include media(PC) {
                margin-left: 0;
              }
            }
          }

          span {
            display: block;
            max-width: 60%;

            @include media(PC) {
              max-width: 100%;
              opacity: 1;

            }

            img {
              width: 100%;
            }
          }
        }
      }

      .button {
        a {
          position: relative;
          display: block;
          width: 100%;
          max-width: 300px;
          margin: 0 auto 30px;
          padding: 15px 0px;
          border: 2px solid #604c3f;
          background-color: transparent;
          font-size: 1.6rem;
          color: #604c3f;
          text-align: center;
          text-decoration: none;
          transition: all .2s ease;

          &:hover {
            background-color: #604c3f;
            color: #fff;

            &::after {
              border-top: solid 2px #fff;
              border-right: solid 2px #fff;
            }
          }

          &::after {
            position: absolute;
            top: 50%;
            right: 20px;
            width: 10px;
            height: 10px;
            margin-top: -4px;
            border: 0px;
            border-top: solid 2px #604c3f;
            border-right: solid 2px #604c3f;
            content: '';
            transition: all .2s ease;
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  .index-works {

    padding: 40px 5%;
    background-color: rgba($accent-color, 0.1);

    @include media(PC) {
      margin: 0px auto 0px;
      padding: 80px 0;
    }

    .index-works__inner {
      width: 100%;
      max-width: $width;
      margin: 0px auto 40px;
    }

    .title {
      max-width: 100px;
      margin: 0 auto 30px;

      @include media(PC) {
        max-width: 150px;
        margin: 0 auto 50px;
      }

      img {
        width: 100%;
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;

      @include media(PC) {
        flex-direction: row;
        margin-bottom: 50px;
        padding: 0 30px;
        justify-content: space-between;
      }

      .list-item {
        margin-bottom: 20px;

        @include media(PC) {
          width: 30%;
          margin: 0 3%;
        }

        a {
          position: relative;
          display: block;
          opacity: 1;
          transition: all .2s ease;

          &:hover {
            opacity: .7;
          }

          .cat {
            position: absolute;
            top: 0;
            right: 0;
            width: 100px;
            padding: 5px 0;
            background-color: $primary-color;
            font-size: 1.2rem;
            color: #fff;
            text-align: center;
          }

          .image {
            img {
              width: 100%;
              height: auto;
              vertical-align: middle;
            }
          }
        }
      }
    }

    .button {
      a {
        position: relative;
        display: block;
        width: 100%;
        max-width: 300px;
        margin: 0 auto 30px;
        padding: 15px 0px;
        border: 2px solid $primary-color;
        background-color: transparent;
        font-size: 1.6rem;
        color: $primary-color;
        text-align: center;
        text-decoration: none;
        transition: all .2s ease;

        &:hover {
          background-color: $primary-color;
          color: #fff;

          &::after {
            border-top: solid 2px #fff;
            border-right: solid 2px #fff;
          }
        }

        &::after {
          position: absolute;
          top: 50%;
          right: 20px;
          width: 10px;
          height: 10px;
          margin-top: -4px;
          border: 0px;
          border-top: solid 2px $primary-color;
          border-right: solid 2px $primary-color;
          content: '';
          transition: all .2s ease;
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }

  .index-voice {
    padding: 40px 0px 0px;
    background-color: #F1ECCC;

    @include media(PC) {
      margin: 0px auto 40px;
      padding: 80px 0;
    }

    .index-voice__inner {
      width: 100%;
      max-width: $width;
      margin: 0px auto 40px;
      padding-bottom: 10px;
    }

    .title {
      max-width: 100px;
      margin: 0 auto 0px;

      @include media(PC) {
        max-width: 150px;
        margin: 0 auto 30px;
      }

      img {
        width: 100%;
      }
    }

    .subtitle {
      margin-top: 20px;
      font-size: 17px;
      color: $primary-color;
      padding: 0 20px;
      text-align: center;
      margin-bottom: 40px;

      @include media(PC){
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 80px;

        br {
          display: none;
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;

      @include media(PC) {
        flex-direction: row;
        margin-bottom: 50px;
        padding: 0 30px;
        justify-content: space-between;
        margin-left: -20px;
      }

      .list-item {
        margin-bottom: 20px;
        padding: 0 10px;

        @include media(PC) {
          width: calc(33.3333% - 20px);
          margin-right: 20px;
        }

        a {
          position: relative;
          display: block;
          opacity: 1;
          transition: all .2s ease;

          &:hover {
            opacity: .7;
          }
            img {
              width: 100%;
              height: auto;
              vertical-align: middle;
            }

          }
        dl {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 10px;
          font-size: 18px;
          color: $primary-color;
          font-weight: bold;

          dt {
            text-align: center;
          }
        }
      }
    }

    .slide-arrow {
      position: absolute;
      top: 35%;
      z-index: 20;
    }

    .prev-arrow {
      left: 10px;
      display: block;
      width: 35px;
      height: 100px;
      cursor: pointer;
      background-image: url(./assets/img/cursor-left-b.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% auto;
      transition: all .1s ease;

      @include media(PC) {
        width: 50px;
        height: 105px;
        left: -20px;
      }

      &:hover {
        opacity: 0.5;
      }
    }

    .next-arrow {
      right: 10px;
      display: block;
      width: 35px;
      height: 100px;
      cursor: pointer;
      background-image: url(./assets/img/cursor-right-b.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% auto;
      transition: all .1s ease;

      @include media(PC) {
        width: 50px;
        height: 105px;
        right: -20px;
      }

      &:hover {
        opacity: 0.5;
      }
    }

  }

  .index-banner {
    width: 100%;
    max-width: $width;
    margin: 0px auto 40px;
    padding: 0px 5%;

    @include media(PC) {
      margin: 0px auto 40px;
      padding: 0px;
    }

    a {
      opacity: 1;
      transition: all .2s ease;

      &:hover {
        opacity: .7;
      }

      img {
        max-width: 100%;
      }
    }
  }

  .index-blog {
    width: 100%;
    max-width: $width;
    margin: 20px auto 40px;
    padding: 0px 5%;

    @include media(PC) {
      margin: 80px auto;
      padding: 0px;
    }

    .list {
      display: flex;
      flex-direction: column;

      @include media(PC) {
        flex-direction: row;
        align-items: flex-end;
      }

      .list-item {
        width: 100%;
        margin-bottom: 20px;

        @include media(PC) {
          margin: 20px;
        }

        a {
          display: block;
          opacity: 1;
          transition: all .2s ease;

          &:hover {
            opacity: .7;
          }

          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  .index-sns {
    width: 100%;
    max-width: $width;
    margin: 20px auto 40px;
    padding: 0px 5%;

    @include media(PC) {
      margin: 80px auto;
      padding: 0px;
    }

    .text {
      font-family: $heading-font;
      font-size: 1.9rem;
      font-weight: bold;
      color: $primary-color;
      text-align: center;
      letter-spacing: 0.1em;

      @include media(PC) {
        font-size: 2.6rem;
      }
    }

    .list {
      display: flex;
      justify-content: center;
      align-items: center;

      .list-item {
        max-width: 80px;
        margin: 20px 10px;

        @include media(PC) {
          margin: 20px;
        }

        img {
          width: 100%;
        }

        a {
          opacity: 1;
          transition: all .2s ease;

          &:hover {
            opacity: .7;
          }
        }
      }
    }
  }

  .index-newsletter {
    width: 100%;
    max-width: $width;
    margin: 20px auto 40px;
    padding: 0px 5%;

    @include media(PC) {
      margin: 80px auto;
      padding: 0px;
    }

    .list {
      display: flex;
      flex-direction: column;

      @include media(PC) {
        flex-direction: row;
        align-items: flex-end;
      }

      .list-item {
        width: 100%;
        margin-bottom: 20px;

        @include media(PC) {
          margin: 20px;
        }

        a {
          display: block;
          opacity: 1;
          transition: all .2s ease;

          &:hover {
            opacity: .7;
          }

          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  .index-joinus {
    width: 100%;
    max-width: $width;
    margin: 20px auto 40px;
    padding: 0px 5%;

    @include media(PC) {
      margin: 80px auto;
      padding: 0px;
    }

    .figure {
      display: flex;
      flex-direction: column;

      @include media(PC) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      dt {
        margin-bottom: 20px;

        @include media(PC) {
          width: 400px;
          margin-bottom: 0;
        }

        img {
          max-width: 100%;
        }
      }

      dd {

        @include media(PC) {
          width: calc(100% - 450px);
          padding: 0 40px;
        }

        .text {
          margin-bottom: 30px;
          font-size: 1.2rem;

          @include media(PC) {
            font-size: 1.6rem;
            line-height: 1.8;
          }
        }

        .button {
          a {
            position: relative;
            display: block;
            width: 100%;
            max-width: 300px;
            margin: 0 auto 30px;
            padding: 15px 0px;
            border: 2px solid $primary-color;
            background-color: transparent;
            font-size: 1.6rem;
            color: $primary-color;
            text-align: center;
            text-decoration: none;
            transition: all .2s ease;

            &:hover {
              background-color: $primary-color;
              color: #fff;

              &::after {
                border-top: solid 2px #fff;
                border-right: solid 2px #fff;
              }
            }

            &::after {
              position: absolute;
              top: 50%;
              right: 20px;
              width: 10px;
              height: 10px;
              margin-top: -4px;
              border: 0px;
              border-top: solid 2px $primary-color;
              border-right: solid 2px $primary-color;
              content: '';
              transition: all .2s ease;
              -ms-transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }

  .index-links {
    width: 100%;
    max-width: $width;
    margin: 20px auto 40px;
    padding: 0px 5%;

    @include media(PC) {
      margin: 80px auto;
      padding: 0px;
    }

    ul {
      display: flex;
      flex-direction: column;

      @include media(PC) {
        flex-direction: row;
        padding: 0 20px;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      li {
        margin-bottom: 20px;

        @include media(PC) {
          width: 48%;
          margin-bottom: 30px;
        }

        p {
          margin-bottom: 5px;
          font-size: 1.6rem;
        }

        a {
          display: block;
          opacity: 1;
          transition: all .2s ease;

          &:hover {
            opacity: .7;
          }

          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  .index-about {
    padding: 40px 5%;
    background-color: $primary-color;

    @include media(PC) {
      margin: 80px auto 0;
      padding: 40px 100px;
    }

    .index-about__inner {
      width: 100%;
      max-width: $width;
      margin: 20px auto 0px;
    }

    .title {
      width: 90px;
      margin: 0 auto 30px;
      text-align: center;

      @include media(PC) {
        width: 120px;
      }

      img {
        width: 100%;
      }
    }

    .figure {
      dl {
        display: flex;
        margin-bottom: 5px;
        justify-content: space-between;

        dt {
          width: 70px;
          padding: 10px;
          border: 1px solid #fff;
          font-size: 1.2rem;
          color: #fff;
          text-align: right;

          @include media(PC) {
            width: 200px;
            font-size: 1.6rem;
          }
        }

        dd {
          width: calc(100% - 75px);
          padding: 10px;
          border: 1px solid #fff;
          background-color: #fff;
          font-size: 1.2rem;

          @include media(PC) {
            width: calc(100% - 205px);
            font-size: 1.6rem;
          }

          .pc-show {
            @include media(PC) {
              display: inline !important;
            }
          }
        }
      }
    }

    .zeh {
      margin-top: 60px;

      @include media(PC) {
        margin-top: 100px;
      }
      h3 {
        text-align: center;
        color: #fff;
        font-size: 18px;

        @include media(PC) {
          font-size: 24px;
        }
      }
      .zeh-table {
        margin-top: 10px;
        color: #fff;
        font-size: 1.2rem;
        padding-bottom: 30px;


        @include media(PC) {
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
          font-size: 1.6rem;
          padding-bottom: 100px;
        }

        table {
          width: 100%;
          max-width: 600px;
          th {
            width: 25%;
          }
          td,th {
            background-color: #fff;
            border: 2px solid #00912e;
            color: #131313;
            text-align: center;
            padding: 5px;

            @include media(PC) {
              padding: 10px;
            }
          }
        }
      }
    }
  }

  .index-map {
    width: 100%;
    // max-width: $width;
    margin: 0px auto 0px;

    @include media(PC) {
      margin: 0px auto 0;
    }

    iframe {
      vertical-align: middle;
    }
  }

  .index-contact {

    padding: 40px 5%;
    background-color: rgba(#a8cc06, 0.08);

    @include media(PC) {
      margin: 0px auto 0;
      padding: 80px 0;
    }

    .index-contact__inner {
      width: 100%;
      max-width: $width;
      margin: 0px auto 0px;
    }

    .title {
      width: 90px;
      margin: 0 auto 30px;
      text-align: center;

      @include media(PC) {
        width: 120px;
      }

      img {
        width: 100%;
      }
    }

    .copy {
      margin-bottom: 10px;
      font-size: 2.5rem;
      line-height: 1;
      color: $primary-color;
      text-align: center;

      @include media(PC) {
        margin-bottom: 20px;
        font-size: 5rem;
      }
    }

    .text {
      font-size: 1.2rem;
      color: $primary-color;
      text-align: center;

      @include media(PC) {
        font-size: 2.0rem;
      }
    }

    .contact-menu {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      align-items: center;
      justify-content: center;

      @include media(PC) {
        flex-direction: row;
        align-items: flex-start;
      }

      .menu-item {
        margin-bottom: 30px;

        @include media(PC) {
          margin: 20px 40px;
          margin-bottom: 0;
        }

        dl {
          dt {
            display: flex;
            margin-bottom: 20px;
            align-items: center;
            justify-content: center;

            @include media(PC) {
              margin-bottom: 10px;
            }

            span {
              img {
                max-width: 30px;
                margin-right: 10px;
              }
            }

            p {
              font-size: 1.5rem;
              color: $primary-color;
            }
          }
        }

        .button {
          a {
            position: relative;
            display: block;
            width: 100%;
            max-width: 300px;
            margin: 0 auto 30px;
            padding: 15px 0px;
            border: 2px solid $primary-color;
            background-color: transparent;
            font-size: 1.6rem;
            color: $primary-color;
            text-align: center;
            text-decoration: none;
            transition: all .2s ease;

            &:hover {
              background-color: $primary-color;
              color: #fff;

              &::after {
                border-top: solid 2px #fff;
                border-right: solid 2px #fff;
              }
            }

            &::after {
              position: absolute;
              top: 50%;
              right: 20px;
              width: 10px;
              height: 10px;
              margin-top: -4px;
              border: 0px;
              border-top: solid 2px $primary-color;
              border-right: solid 2px $primary-color;
              content: '';
              transition: all .2s ease;
              -ms-transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }

        .tel {
          color: $primary-color;
          text-align: center;

          p {
            font-size: 3.5rem;
          }

          span {
            font-size: 1.4rem;
          }
        }
      }
    }

  }

  .common-title {
    margin-bottom: 30px;
    border-bottom: 6px solid #f1eccc;
    font-size: 1.8rem;
    font-weight: bold;
    color: $primary-color;
    text-align: center;

    @include media(PC) {
      margin-bottom: 30px;
      border-bottom: 12px solid #f1eccc;
      font-size: 3.0rem;
    }
  }
}
