/*-----------------------------------------------------------------------------------
    blog
-----------------------------------------------------------------------------------*/

#blog {
	.blog-header {
    background-color: #e3dbc0;
		width: 100%;
    position: relative;

    .image {
      text-align: center;
      img {
        max-width: 100%;
        height: auto;
        max-height: 200px;
        vertical-align: middle;

        @include media(PC) {
          max-height: 300px;
        }
      }
    }
  }

  .side-nav {
    width: 100%;
    max-width: $width;
    margin: 0px auto 0px;
    @include media(PC) {
      margin: 0px auto 0;
    }
    .side-nav-banner {
      display: none;

      @include media(PC) {
        display: inherit;

        a {
          display: block;

          img {
            width: 100%;
          }
        }
      }
    }
  }

	.blog-nav {
    padding: 20px 5%;
		p {
			font-size: 1.4rem;
			text-align: center;
		}
	}

  .blog-wrap {
    width: 100%;
    max-width: $width;
    margin: 0px auto 0px;
    padding: 0px 5%;
    @include media(PC) {
      margin: 0px auto 0;
      padding: 0;
    }

    .entry {
      .title {
        background-color: #e3dbc0;
        font-size: 1.8rem;
        border-left: 6px solid #604c3f;
        padding: 5px 10px;
        margin-bottom: 10px;

        @include media(PC) {
          font-size: 2.4rem;
          padding: 10px;
        }
      }
      .entry__desc {
        font-size: 1.3rem;
        color: #595757;
        display: flex;
        margin-bottom: 20px;
        @include media(PC) {
          font-size: 1.6rem;
        }
        &:before{
          content:"\f017";
          font-family: FontAwesome;
          margin-right: -20px;
        }
      }
      .entry__body {
        font-size: 1.4rem;
        line-height: 1.8;

        @include media(PC) {
          font-size: 1.6rem;
          line-height: 2;
        }

        p {
          margin-bottom: 20px;
        }

        img {
          margin-bottom: 20px;
          max-width: 100%;
        }
      }
			.entry-nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto 40px;
        font-family: $heading-font;
        font-weight: bold;

				@include media(PC){
          width: 600px;
          margin: 50px auto 100px;
          text-align: center;
          display: flex;
          justify-content: center;
				}

				&__btn {
          margin: 10px 0px 0px;
          min-width: auto;
          display: block;
          width: 30%;

					@include media(PC){
  					margin:20px 20px;
	  				transition: all 0.2s ease;
					}

					&:hover {
						opacity: 0.5;
					}

					a {
            border: 1px solid $primary-color;
						color: $primary-color;
						font-size: 1.4rem;
            display: block;
            // font-weight: bold;
            letter-spacing: 0.1em;
            text-decoration: none;
            padding: 10px;
            text-align: center;
						@include media(PC){
              padding: 10px 20px;
              color: $primary-color;
              font-size: 1.8rem;
              display: block;
						}
					}
				}
			}
    }
  }

  &.blog2 {
    .blog-header {
      background-color: #fbe5e3;
    }
    .entry {
      .title {
        background-color: #fbe5e3;
      }
    }
  }
}

#archive-blog1 {
	.archive-blog-header {
    background-color: #e3dbc0;
		width: 100%;
    position: relative;

    .image {
      text-align: center;
      img {
        max-width: 100%;
        height: auto;
        max-height: 200px;
        vertical-align: middle;

        @include media(PC) {
          max-height: 300px;
        }
      }
    }
  }
  .side-nav {
    width: 100%;
    max-width: $width;
    margin: 0px auto 0px;
    @include media(PC) {
      margin: 0px auto 0;
    }
    .side-nav-banner {
      display: none;

      @include media(PC) {
        display: inherit;

        a {
          display: block;

          img {
            width: 100%;
          }
        }
      }
    }
  }

	.archive-blog-nav {
    padding: 20px 5%;
		p {
			font-size: 1.4rem;
			text-align: center;
		}
	}

	.archive-blog-list {
    width: 100%;
    max-width: $width;
    margin: 0px auto 0px;
    padding: 0px 5%;
    @include media(PC) {
      margin: 0px auto 0;
      padding: 0;
    }
    .entry-wrap {
      display: flex;
      flex-wrap: wrap;
      margin-left: -5%;

      @include media(PC) {
        margin-left: -20px;
      }
    }

		.entry {
			margin-bottom: 30px;
      transition: all 0.2s ease;
      width: 45%;
      margin-left: 5%;

      @include media(PC) {
        width: calc(25% - 20px);
        margin-left: 20px;
      }

      a {
        display: block;
        text-decoration: none;
        color: #595757;
      }

      img {
        width: 100%;
        height: auto;
      }

			.title {
        font-size: 1.5rem;
        text-decoration: underline;
			}
			.date {
				margin-bottom: 0px;
			}
    }


  }
  .tablenav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;

    a {
      color: #000;
      margin: 0 20px;
      font-size: 1.6rem;
      text-decoration: none;
    }

    span {
      color: #000;
      margin: 0 20px;
      font-size: 1.6rem;
      text-decoration: none;
    }
  }
}

#archive-blog2 {
	.archive-blog-header {
    background-color: #fbe5e3;
		width: 100%;
    position: relative;

    .image {
      text-align: center;
      img {
        max-width: 100%;
        height: auto;
        max-height: 200px;
        vertical-align: middle;

        @include media(PC) {
          max-height: 300px;
        }
      }
    }
  }
  .side-nav {
    width: 100%;
    max-width: $width;
    margin: 0px auto 0px;
    @include media(PC) {
      margin: 0px auto 0;
    }
    .side-nav-banner {
      display: none;

      @include media(PC) {
        display: inherit;

        a {
          display: block;

          img {
            width: 100%;
          }
        }
      }
    }
  }

	.archive-blog-nav {
    padding: 20px 5%;
		p {
			font-size: 1.4rem;
			text-align: center;
		}
	}

	.archive-blog-list {
    width: 100%;
    max-width: $width;
    margin: 0px auto 0px;
    padding: 0px 5%;
    @include media(PC) {
      margin: 0px auto 0;
      padding: 0;
    }
    .entry-wrap {
      display: flex;
      flex-wrap: wrap;
      margin-left: -5%;

      @include media(PC) {
        margin-left: -20px;
      }
    }

		.entry {
			margin-bottom: 30px;
      transition: all 0.2s ease;
      width: 45%;
      margin-left: 5%;

      @include media(PC) {
        width: calc(25% - 20px);
        margin-left: 20px;
      }

      a {
        display: block;
        text-decoration: none;
        color: #595757;
      }

      img {
        width: 100%;
        height: auto;
      }

			.title {
        font-size: 1.5rem;
        text-decoration: underline;
			}
			.date {
				margin-bottom: 0px;
			}
    }


  }
  .tablenav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;

    a {
      color: #000;
      margin: 0 20px;
      font-size: 1.6rem;
      text-decoration: none;
    }

    span {
      color: #000;
      margin: 0 20px;
      font-size: 1.6rem;
      text-decoration: none;
    }
  }
}
