/*-----------------------------------------------------------------------------------
    story
-----------------------------------------------------------------------------------*/

#story {
  color: #595757;

  img {
    max-width: 100%;
  }
  .story-header {
    overflow: hidden;
    @include media(PC) {
      background-image: url(./assets/img/header@2x.png);
      background-size: 100% auto;
      min-height: 40vw;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 100%;
      height: auto;

      @include media(PC) {
        max-width: 800px;
        width: 100%;
        display: block;
        margin: -5vw auto 0;
      }
    }
  }
  .story-nav {
    padding: 20px 5%;
    max-width: 400px;
    margin: 0 auto;

    @include media(PC) {
      max-width: 600px;
      margin-bottom: 40px;
    }

    ul {
      display: flex;
      justify-content: space-between;

      li {
        width: 48%;
        @include media(PC) {
          width: 40%;
        }
        a {
          display: block;

          @include media(PC) {
            opacity: 1;
            transition : all .2s ease;
            &:hover {
              opacity: .75;
            }
          }

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  .story-1day {
    padding: 0 5%;
    .title {
      margin-bottom: 10px;
      @include media(PC) {
        margin-bottom: 40px;
      }
      img {
        display: block;
        max-width: 350px;
        width: 50%;
        margin: 0 auto;
      }
      h3 {
        font-size: 1.8rem;
        text-align: center;
        font-weight: bold;

        @include media(PC) {
          font-size: 3.2rem;

          br {
            display: none;
          }
        }
      }
    }
    .text {
      font-size: 1.6rem;
      line-height: 2;
      margin-bottom: 30px;

      @include media(PC) {
        text-align: center;
        margin-bottom: 60px;
      }
      br {
        display: none;

        @include media(PC) {
          display: inherit;
        }
      }
    }
    .image {
      margin-bottom: 50px;

      @include media(PC) {
        max-width: 700px;
        margin: 0 auto;

        img {
          margin-bottom: 50px;
        }
      }
    }
    .copy {
      color:#674b3c;
      font-size: 1.8rem;
      text-align: center;
      border-top: 1px dotted #674b3c;
      border-bottom: 1px dotted #674b3c;
      margin: 0 -5%;
      padding: 20px 0;

      @include media(PC) {
        max-width: 800px;
        margin: 80px auto 40px;
        font-size: 2.4rem;

        .show-sp {
          display: none;
        }
      }
    }
  }
  .story-contents {
    .story-contents__item {
      background-color: #fff;
      padding: 0 5%;
      overflow: hidden;
      position: relative;

      &:before {
        content:'';
        width: 100px;
        height: 20px;
        background-image: url(./assets/img/yajirusi@2x.png);
        background-size: 100% 100%;
        display: block;
        position: absolute;
        top:0;
        left:0;
        right:0;
        margin: auto;

      }

      &:nth-of-type(odd) {
        background-color: #faf2ef;
        &:before {
          background-image: url(./assets/img/yajirusi_w@2x.png);
        }
      }

      &:first-child {
        &:before {
          background:none;
        }
      }
      .inner {
        border-left: 6px solid #a08a7b;
        padding: 30px 0px 30px 15px;

        @include media(PC) {
          max-width: 1000px;
          margin: 0 auto;
          padding: 60px 0 60px 30px;
        }

        dl {
          @include media(PC) {
            display: flex;
            justify-content: space-between;
          }
          dt {
            margin-bottom: 20px;
            position: relative;

            @include media(PC){
              margin-bottom: 0;
              width: 55%;
            }

            &::before {
              content:'';
              width: 20vw;
              height: 3px;
              display: block;
              background-color: #a08a7b;
              position: absolute;
              top:15px;
              left:-21vw;

              @include media(PC) {
                width: 30px;
                left: -35px;
                top: 20px;
              }

            }
            .time {
              font-size: 1.95rem;
              margin-bottom: 5px;

              @include media(PC) {
                font-size: 2.6rem;
              }
            }
            .title {
              color: $primary-color;
              font-size: 1.95rem;
              font-weight: bold;
              margin-bottom: 10px;

              @include media(PC) {
                font-size: 2.6rem;
              }
            }
            .text {
              font-size: 1.4rem;

              @include media(PC) {
                font-size: 1.6rem;
              }
            }
          }

          dd {
            @include media(PC){
              margin-bottom: 0;
              width: 40%;
            }
          }
        }
      }
    }
    .story-contents__comment {
      position: relative;
      padding: 50px 5%;


      .inner {
        @include media(PC) {
          max-width: 1000px;
          margin: 0 auto;
          padding: 60px 0 60px 30px;
        }
      }

      &.brown {
        background: #faf2ef;
        &:before {
          background-image: url(./assets/img/yajirusi_w@2x.png);
        }
      }
      &:before {
        content:'';
        width: 100px;
        height: 20px;
        background-image: url(./assets/img/yajirusi@2x.png);
        background-size: 100% 100%;
        display: block;
        position: absolute;
        top:0;
        left:0;
        right:0;
        margin: auto;
      }

      dl {
        @include media(PC) {
          display: flex;
          justify-content: space-between;
        }
        dt {
          font-size: 1.6rem;
          line-height: 2;
          margin-bottom: 20px;
          @include media(PC){
            margin-bottom: 0;
            width: 55%;
          }
        }
        dd {
          @include media(PC){
            margin-bottom: 0;
            width: 40%;
          }
          img {
            display: block;
            max-width: 400px;
            width: 100%;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .index-contact {
    background-color: rgba(#a8cc06, 0.08);

    padding: 40px 5%;
    @include media(PC) {
      margin: 0px auto 0;
      padding: 80px 0;
    }

    .index-contact__inner {
      width: 100%;
      max-width: $width;
      margin: 0px auto 0px;
    }

    .title {
      text-align: center;
      width: 90px;
      margin: 0 auto 30px;

      @include media(PC) {
        width: 120px;
      }
      img {
        width: 100%;
      }
    }

    .copy {
      text-align: center;
      font-size: 2.5rem;
      color: $primary-color;
      margin-bottom: 10px;
      line-height: 1;

      @include media(PC) {
        font-size: 5rem;
        margin-bottom: 20px;
      }
    }
    .text {
      text-align: center;
      font-size: 1.2rem;
      color: $primary-color;

      @include media(PC) {
        font-size: 2.0rem;
      }
    }
    .contact-menu {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include media(PC) {
        flex-direction: row;
        align-items: flex-start;
      }

      .menu-item {
        margin-bottom: 30px;

        @include media(PC) {
          margin-bottom: 0;
          margin: 20px 40px;
        }
        dl {
          dt {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            @include media(PC) {
              margin-bottom: 10px;
            }
            span {
              img {
                max-width: 30px;
                margin-right: 10px;
              }
            }
            p {
              font-size: 1.5rem;
              color: $primary-color;
            }
          }
        }
        .button {
          a {
            display: block;
            max-width: 300px;
            width: 100%;
            padding: 15px 0px;
            font-size: 1.6rem;
            text-align: center;
            margin: 0 auto 30px;
            color: $primary-color;
            border: 2px solid $primary-color;
            text-decoration: none;
            position: relative;
            background-color: transparent;
            transition: all .2s ease;

            &:hover {
              background-color: $primary-color;
              color: #fff;

              &::after {
                border-top: solid 2px #fff;
                border-right: solid 2px #fff;
              }
            }

            &::after {
              content: '';
              width: 10px;
              height: 10px;
              border: 0px;
              border-top: solid 2px $primary-color;
              border-right: solid 2px $primary-color;
              -ms-transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              position: absolute;
              top: 50%;
              right: 20px;
              margin-top: -4px;
              transition: all .2s ease;
            }
          }
        }
        .tel {
          color: $primary-color;
          text-align: center;
          p {
            font-size: 3.5rem;
          }
          span {
            font-size: 1.4rem;
          }
        }
      }
    }

  }
}
