/*-----------------------------------------------------------------------------------
    recruit
-----------------------------------------------------------------------------------*/

#recruit {
  .recruit-header {
    background-color: #e6dcd2;
    .show-sp {
      @include media(PC) {
        display: none;
      }
      .recruit-header__inner {
        background-image: url(./assets/img/recruit_sp@2x.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
        min-height: 140vw;
        padding: 40px 5%;
        position: relative;

      }
      .title {
        text-align: center;
        font-size: 2.5rem;
        font-family: $heading-font;
        color: $primary-color;
        font-weight: bold;
      }
      .copy {
        bottom: 20px;
        left:0;
        right:0;
        margin: auto;
        text-align: center;
        color: $primary-color;
        font-size: 2.2rem;
        position: absolute;
        font-weight: bold;
      }
      .recruit-header__caption {
        background-color: #fff;
        padding: 20px 5%;

        .text {
          font-size: 1.2rem;
          margin-bottom: 20px;
        }

        .image {
          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
    }
    .show-pc {
      display: none;
      @include media(PC) {
        display: inherit;
      }
      width: 100%;
      max-width: 1100px;
      margin: 0px auto 0px;
      background-image: url(./assets/img/recruit_back_pc@2x.png);
      background-size: cover;
      min-height: 1500px;
      position: relative;

      .title {
        text-align: center;
        color: $primary-color;
        font-size: 5.0rem;
        font-family: $heading-font;
        font-weight: bold;
        padding-top: 50px;
      }
      .caption {
        position: absolute;
        top:700px;
        left:0;
        right:0;
        margin: auto;
        width: 650px;
        .copy {
          font-size: 3rem;
          font-weight: bold;
          text-align: center;
          color: $primary-color;
          margin-bottom: 30px;
        }
        .text {
          font-size: 1.6rem;
        }
      }
      .image {
        position: absolute;
        bottom: 30px;
        left:-10%;
        right: 0;
        margin: auto;
        img {
          max-width: 130%;
        }
      }
      .image2 {
        position: absolute;
        bottom: 800px;
        left:-5%;
        margin: auto;
        width: 330px;
        img {
          max-width: 130%;
        }
      }
      .image3 {
        position: absolute;
        top: 50px;
        right:100px;
        margin: auto;
        width: 220px;
        img {
          max-width: 130%;
        }
      }
    }

  }

  .recruit-contents {
    background-color: rgba(#a8cc06,0.2);
    padding: 20px 5%;


    .title {
      text-align: center;
      color: $primary-color;
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 20px;

      @include media(PC) {
        font-size: 4.0rem;
        padding-top: 40px;
        br {
          display: none;
        }
      }
    }

    .figure {
      width: 100%;
      max-width: $width;
      margin: 0px auto 0px;

      @include media(PC) {
        padding: 20px 40px 40px;
      }
      dl {
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        dt {
          padding: 10px;
          border: 1px solid #fff;
          background-color: $primary-color;
          width: 100px;
          text-align: right;
          font-size: 1.2rem;
          color: #fff;
          font-weight: bold;

          @include media(PC) {
            font-size: 1.6rem;
            width: 200px;
          }
        }
        dd {
          width: calc(100% - 105px);
          border: 1px solid #fff;
          background-color: #fff;
          padding: 10px;
          font-size: 1.2rem;

          @include media(PC) {
            width: calc(100% - 205px);
            font-size: 1.6rem;
          }
        }
      }
    }
  }
  .index-contact {
    background-color: rgba(#a8cc06, 0.08);

    padding: 40px 5%;
    @include media(PC) {
      margin: 0px auto 0;
      padding: 80px 0;
    }

    .index-contact__inner {
      width: 100%;
      max-width: $width;
      margin: 0px auto 0px;
    }

    .title {
      text-align: center;
      width: 90px;
      margin: 0 auto 30px;

      @include media(PC) {
        width: 120px;
      }
      img {
        width: 100%;
      }
    }

    .copy {
      text-align: center;
      font-size: 2.5rem;
      color: $primary-color;
      margin-bottom: 10px;
      line-height: 1;

      @include media(PC) {
        font-size: 5rem;
        margin-bottom: 20px;
      }
    }
    .text {
      text-align: center;
      font-size: 1.2rem;
      color: $primary-color;

      @include media(PC) {
        font-size: 2.0rem;
      }
    }
    .contact-menu {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include media(PC) {
        flex-direction: row;
        align-items: flex-start;
      }

      .menu-item {
        margin-bottom: 30px;

        @include media(PC) {
          margin-bottom: 0;
          margin: 20px 40px;
        }
        dl {
          dt {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            @include media(PC) {
              margin-bottom: 10px;
            }
            span {
              img {
                max-width: 30px;
                margin-right: 10px;
              }
            }
            p {
              font-size: 1.5rem;
              color: $primary-color;
            }
          }
        }
        .button {
          a {
            display: block;
            max-width: 300px;
            width: 100%;
            padding: 15px 0px;
            font-size: 1.6rem;
            text-align: center;
            margin: 0 auto 30px;
            color: $primary-color;
            border: 2px solid $primary-color;
            text-decoration: none;
            position: relative;
            background-color: transparent;
            transition: all .2s ease;

            &:hover {
              background-color: $primary-color;
              color: #fff;

              &::after {
                border-top: solid 2px #fff;
                border-right: solid 2px #fff;
              }
            }

            &::after {
              content: '';
              width: 10px;
              height: 10px;
              border: 0px;
              border-top: solid 2px $primary-color;
              border-right: solid 2px $primary-color;
              -ms-transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              position: absolute;
              top: 50%;
              right: 20px;
              margin-top: -4px;
              transition: all .2s ease;
            }
          }
        }
        .tel {
          color: $primary-color;
          text-align: center;
          p {
            font-size: 3.5rem;
          }
          span {
            font-size: 1.4rem;
          }
        }
      }
    }

  }
}
